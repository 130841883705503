/**
 * @author SESA545946 - Brijesh shakya
 * @email Brijesh.Shakya@non.se.com
 * @create date 2021-10-04 20:34:33
 * @modify date 2021-10-04 21:31:32
 * @desc Configuration settings service
 */


import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { ConfigurationSettingsRequest } from '../models/configuration-settings-request';
import { ConfigurationSettingsUpdate } from '../models/configuartion-settings-update';
import { ConfiguratioDbosSession } from '../models/congifuration-dbos-session';
import { ErrorFormat } from '../models/error-format';
import { API_URLS } from '../shared/api_urls';

@Injectable({
  providedIn: 'root'
})
export class DeviceConfigService {

  public _selectedDevicetype: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) { }

  setSelectedDevicetype(value) {
    if (value) {
      this._selectedDevicetype.next(value);
    } else {
      this._selectedDevicetype.next(null)
    }
  }

  fetchDeviceTypeRefFile(type): Observable<any> {
    const url = 'config/devicetype';

    return this.http.get(url, { params: new HttpParams().set('type', type) })
      .pipe(map(data => {
        return data;
      }))
  }

  fetchFormObject(data: ConfigurationSettingsRequest): Observable<ConfigurationSettingsRequest> {
    const url = 'config/devicesettings/pull';
    return this.http.post<ConfigurationSettingsRequest>(url, data)
      .pipe(map(data => {
        return data;
      }))
  }

  fetchDBOSToken(): Observable<any> {
    const url = 'token';
    return this.http.get<any>(url)
      .pipe(map(data => {
        return data;
      }))
  }

  fetchDBOSSession(data: ConfiguratioDbosSession): Observable<any> {
    const url = 'session';
    return this.http.post<ConfiguratioDbosSession>(url, data)
      .pipe(retry(0), // retry a failed request up to 3 times
        catchError(this.handleError),
        map(data => {
          return data;
        }));
  }

  updateConfigSettings(data: ConfigurationSettingsUpdate): Observable<ConfigurationSettingsUpdate> {
    const url = 'config/devicesettings/update';
    return this.http.post<ConfigurationSettingsUpdate>(url, data)
      .pipe(retry(1), // retry a failed request up to 3 times
        catchError(this.handleError),
        map(data => {
          return data;
        }))
  };

  authenticateFirmwareCredentials(credentials: any): Observable<any> {
    return this.http.post<any>(`tsi/gateway/login`, credentials)
      .pipe(map(data => {
        return data;
      }));
  };

  private handleError(error: HttpErrorResponse) {
    var msg = '';
    var errorType = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      errorType = 'network';
      msg = 'Detected network slow / disconnection, please try again.';
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      errorType = 'server';
      msg = 'Settings request failed! please try again later.';
    }
    // return an observable with a user-facing error message
    return throwError(
      new ErrorFormat(errorType, msg)
    );
  };

  exportConfigSettings(ConfigurationsObj: any): Observable<any> {
    // return this.http.post<any>(`config/devicesettings/configfile/variable`, ConfigurationsObj)
    return this.http.post<any>(`config/devicesettings/configfile/upload`, ConfigurationsObj)
      .pipe(map(data => {
        return data;
      }));
  };

  confirmImportConfigSettingsRequest(ConfigurationsObj: any): Observable<any> {
    return this.http.post<any>(`config/devicesettings/configfile/variable`, ConfigurationsObj)
      .pipe(map(data => {
        return data;
      }));
  };

  /*exportConfigSettings(ConfigurationsObj: any,  retrievedSettings:any): Observable<any> {
    console.log('Config ---> Gateway credentials: ' + ConfigurationsObj);
    let configVal = JSON.stringify(retrievedSettings)
    console.log("config value is ------"+typeof configVal)
    return this.http.post<any>(`config/devicesettings/configfile/upload`, {ConfigurationsObj,configVal})
      .pipe(map(data => {
        return data;
      }));
  };*/

  // Config file upload
  // uploadDocs(upload: any, uploadFileUrl): Observable<NewSite> {
  //   console.log("upload formData site : ----------------------------------" + JSON.stringify(upload));
  //   const formData = new FormData();
  //   const headers = new HttpHeaders();
  //   headers.set('Content-Type', undefined);
  //   headers.append('Accept', 'application/json');
  //   formData.append('uploadDetails', new Blob([JSON.stringify(upload)], { type: 'application/json' }));
  //   // formData.append("fileFirst", new Blob([JSON.stringify(uploadFileUrl)]));
  //   formData.append("uploadedDocument", new Blob([JSON.stringify(uploadFileUrl)
  //   ], {
  //     type: "application/json"
  //   }));
  //   console.log("formData site : ----------------------------------" + JSON.stringify(formData));
  //   // const docType = upload['documentType'];
  //   // console.log('docType---------------------' + docType);
  //   // const siteName = upload['siteName'];
  //   // const fileName = upload['fileName'];
  //   const fileUploadURL = 'sites/documents?documentType';
  //   return this.http.post<NewSite>(fileUploadURL, formData, { headers });
  // }

  validateImportedConfigFile(upload: any, uploadFileUrl): Observable<any> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.set('Content-Type', undefined);
    headers.append('Accept', 'application/json');
    formData.append('uploadDetails', new Blob([JSON.stringify(upload)], { type: 'application/json' }));
    // formData.append("fileFirst", new Blob([JSON.stringify(uploadFileUrl)]));
    formData.append("uploadedConfiguration", new Blob([JSON.stringify(uploadFileUrl)
    ], {
      type: "application/json"
    }));
    const fileUploadURL = 'config/devicesettings/configfile/download';
    // const fileUploadURL = 'config/devicesettings/configfile/upload';
    return this.http.post<any>(fileUploadURL, formData, { headers });
  }

  getSystemSettingsAvailableForSite(siteId: string): Observable<any> {
    return this.http.get(`${API_URLS.GET_SYSTEM_SETTINGS_AVAILABLE}/${siteId}`).pipe(
      catchError(this.handleError)
    );
  }

}
