import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { SuccessDialogComponent } from './dialogueboxes/success-dialog.component';
import { ErrorDialogComponent } from './dialogueboxes/error-dialog/error-dialog.component';
import { InfoDialogComponent } from './dialogueboxes/info-dialog/info-dialog.component';
import { DeviceInfoDialogComponent } from './dialogueboxes/info-dialog/device-update-info';
import { AuthErrorDialogComponent } from './dialogueboxes/error-dialog/auth-error-dialog.component';
import { UpdatedForgetDialogComponent } from './dialogueboxes/error-dialog/updatedForgetPassword';
import { ResendDialogComponent } from './dialogueboxes/error-dialog/resendMailVerfication.component';
import { ResendEMailVerificationWithUsernameDialogComponent } from './dialogueboxes/error-dialog/resendEMailVerificationWithUsername';
import { tokenMailExpiredDialogComponent } from './dialogueboxes/error-dialog/tokenAndMailExpiredModal';
import { TruncateLettersPipe } from './pipes/truncate-letters.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { SiteTableFilterPipe } from './pipes/site-table-filter.pipe';
import { SiteListPopupComponent } from './site-list/site-list.component';
import { SiteFilterPipe } from './pipes/site-filter.pipe';
import { SearchRouteFilterPipe } from './pipes/search.filter.pipe';
import { ToolTipComponent } from './tooltip/tooltip.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SiteSelectionDropDownComponent } from './site-selection-drop-down/site-selection-drop-down.component';
import { SiteListDropdownComponent } from '../my-dashboard/dashboard/sites-dropdown/sites-dropdown.component';
import { SiteRegistrationComponent } from './site-registration/site-registration.component';
import { AzureMapSiteRegistrationComponent } from './azure-map-site-reistration/azure-map-site-registration';
import { DropdownCheckboxSelectorComponent } from './dropdown-checkbox-selector/dropdown-checkbox-selector.component';
import { BreadcrumbsComponent } from 'src/app/modules/shared/breadcrumbs/breadcrumbs.component';
import { DropdownCheckboxSelectorDocumentTypeComponent } from './dropdown-checkbox-selector-document-type/dropdown-checkbox-selector-document-type.component';
// import { GridInComponent } from './energy-source-analysis/grid-in/grid-in.component';
// import { GridOutComponent } from './energy-source-analysis/grid-out/grid-out.component';
// import { GeneratorComponent } from './energy-source-analysis/generator/generator.component';
// import { PowermeterComponent } from './energy-source-analysis/powermeter/powermeter.component';


@NgModule({
  declarations: [
    SuccessDialogComponent,
    ErrorDialogComponent,
    InfoDialogComponent,
    DeviceInfoDialogComponent,
    AuthErrorDialogComponent,
    UpdatedForgetDialogComponent,
    ResendDialogComponent,
    ResendEMailVerificationWithUsernameDialogComponent,
    tokenMailExpiredDialogComponent,
    DatePickerComponent,
    SiteListPopupComponent,
    TruncateLettersPipe,
    SiteFilterPipe,
    SearchRouteFilterPipe,
    SiteTableFilterPipe,
    ToolTipComponent,
    SiteSelectionDropDownComponent,
    SiteListDropdownComponent,
    SiteRegistrationComponent,
    AzureMapSiteRegistrationComponent,
    DropdownCheckboxSelectorComponent,
    BreadcrumbsComponent,
    DropdownCheckboxSelectorDocumentTypeComponent
    // //components for report
    // GridInComponent,
    // GridOutComponent,
    // GeneratorComponent,
    // PowermeterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    RecaptchaModule,
    NgbModule,
    FontAwesomeModule,
    RouterModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    TabsModule.forRoot(),
    NgApexchartsModule,

  ],
  exports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    RecaptchaModule,
    NgbModule,
    FontAwesomeModule,
    RouterModule,
    RecaptchaModule,
    ReactiveFormsModule,
    TruncateLettersPipe,
    DatePickerComponent,
    SiteTableFilterPipe,
    SiteListPopupComponent,
    SiteFilterPipe,
    SearchRouteFilterPipe,
    ToolTipComponent,
    TabsModule,
    NgxPaginationModule,
    NgApexchartsModule,
    SiteSelectionDropDownComponent,
    SiteListDropdownComponent,
    SiteRegistrationComponent,
    DropdownCheckboxSelectorComponent,
    BreadcrumbsComponent,
    DropdownCheckboxSelectorDocumentTypeComponent
    //components for report
    // GridInComponent,
    // GridOutComponent,
    // GeneratorComponent,
    // PowermeterComponent,
    //
  ],
})
export class SharedModule { }
