/**
 * @author sesa 534419 - Ashutosh Singh
 * @email ashutosh.singh1@non.se.com
 * @create date 2019-04-27 17:15:20
 * @modify date 2019-04-27 17:15:20
 * @desc This component helps you to update/reset new password for user
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResService } from 'src/app/services/res.service';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from 'src/app/modules/shared/dialogueboxes/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from 'src/app/modules/shared/dialogueboxes/success-dialog.component';
import { API_URLS } from 'src/app/shared/api_urls';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  validateTokenUrl: string = 'confirm?token=';
  token: string;
  validToken: boolean;
  newPassword: string;
  resetPasswordUrl: string;
  password: string;
  passwordAgain: string;

  isCheckedTC = false; // this is to validate checkbox TC
  isSubmitClicked = false; // this is also for validate checkbox TC
  isUC = false;
  isLC = false;
  isNumeric = false;
  isSpclChar = false;
  isMinLength = false;
  isMaxLength = false;
  validPassword = false;
  typePassword = false;
  typePassword2 = false;
  @ViewChild('f', { static: true }) resetPasswordForm: NgForm;
  isNotAllowedChar: boolean = false;
  spaceNotAllowedChar: boolean = false;
  isDirty: boolean = false
  public showEyeIcon = false
  isMinLengthFail: boolean = false

  schneiderLogo = 'assets/app-images/schneider_LIO_White_RGB.png';

  constructor(private _authService: AuthService, private modalService: NgbModal, private activatedRoute: ActivatedRoute, private resource: ResService,
    private http: HttpClient, private router: Router) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      if (this.token !== '' && this.token !== undefined) {
        this.validateTokenUrl += this.token;
        this.resetPasswordUrl = this.validateTokenUrl;
        this.validateToken();
      }
    });
  }

  onKeyPress () {
    this.showEyeIcon = true
  }

  togglePassword = function () {
    this.typePassword = !this.typePassword;
  }

  togglePassword2 = function () {
    this.typePassword2 = !this.typePassword2;
  }

  validatePassWordForSpclChar (event: any) {
    const notallowedChar = /(?=.*?[-().'|<>?=;:+,])/
    const inputChar = event
    // console.log('value-------------' + event);
    // console.log('value-------------' + event.length);

    if (inputChar.length >= 8) {
      this.isMinLengthFail = true
    } else {
      this.isMinLengthFail = false
    }
    if (notallowedChar.test(inputChar)) {
      // console.log('isNotAllowedChar' + this.isNotAllowedChar);
      this.isNotAllowedChar = true
    } else {
      this.isNotAllowedChar = false
    }
    this.validatePassWord(inputChar)
  }

  validatePassWord(event: any) {
    const inputChar = event;
    this.validPassword = false;
    this.isLC = false;
    this.isUC = false;
    this.isNumeric = false;
    this.isSpclChar = false;
    this.isMinLength = false;
    this.isMaxLength = false;
    const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%&^*]).{8,25}$/;
    const upperCase = /(?=.*?[A-Z])/;
    const lowerCase = /(?=.*?[a-z])/;
    const numeric = /(?=.*?[0-9])/;
    const spclChar = /(?=.*?[!@#$%&^*])/;
    const notallowedChar = /(?=.*?[-().'|<>?=;:+,])/;

    this.isNotAllowedChar = notallowedChar.test(inputChar)

    if (inputChar.startsWith(' ') || inputChar.endsWith(' ')) {
      this.spaceNotAllowedChar = true;
    } else {
      this.spaceNotAllowedChar = false;
    }

    this.validPassword = pattern.test(inputChar)
    if (!this.validPassword) {
      this.isLC = lowerCase.test(inputChar)
      this.isUC = upperCase.test(inputChar)
      this.isNumeric = numeric.test(inputChar)
      this.isSpclChar = spclChar.test(inputChar)
    }
  }

  ngOnInit() {
  }

  validateToken() {
    this.resource.getResource(this.validateTokenUrl)
      .subscribe((data) => {
        if (data.status === 'success') {
          this.validToken = true;
          // this.alertService.success(data['msg']);
          this._authService.setModalMessage(data['msg']);
          this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
        } else if (data.status === 'fail') {
          this.validToken = false;
          this._authService.setModalMessage(data['msg']);
          this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
          // this.alertService.error(data['msg']);
        }
      }, err => {
        this.validToken = false;
        this._authService.setModalMessage('Unable to reset password. Please contact support if issue persists' );
        this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
        this.router.navigate(['/login']);
      });
  }

  onSubmit() {
    this.newPassword = this.resetPasswordForm.value.password;

    if (!!this.newPassword && !!this.token) {
      this.http.get(
        API_URLS.FORGOT_PASSWORD_CONFIRM, {
        params: {'password': this.newPassword, 'token': this.token}
      })
        .subscribe(
          data => {
            if (data['status'] === 'Success') {
              this._authService.setModalMessage(data['msg']);
              this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
              // this.alertService.success(data['msg'], true);
              this.router.navigate(['/login']);
            } else if (data['status'] === 'Failure') {
              this._authService.setModalMessage("Error while processing, try later");
              this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
              // this.alertService.error(data['msg']);
            }
          },
          err => {
            if (err) {
              this._authService.setModalMessage("Error while processing, try later");
              this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
            }
          }
        );
    } else {
    }
  }
}
