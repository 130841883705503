/**
 * @author sesa 534419 - Ashutosh Singh
 * @email ashutosh.singh1@non.se.com
 * @create date 2019-04-27 16:31:27
 * @modify date 2019-04-29 01:39:32
 * @desc This component is helps to accept New user registration forms data and send it to service
 * to insert into the database
 */
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Country } from 'src/app/models/country';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import countriesJson from '../../../shared/data-values/countries/countries.json';
import { Constants } from 'src/app/shared/constants';
import { RecaptchaComponent } from 'ng-recaptcha';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { TermsConditionsComponent } from '../../my-dashboard/dashboard/terms-conditions/terms-conditions.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  captchaChecked: boolean = false;
  isMessageSent: boolean = false;
  @ViewChild('f', { static: false }) signUpForm: NgForm;
  @ViewChild('captchaRef', { static: false }) captchaRef: RecaptchaComponent;
  private closeResult: string;
  registerSuccessMsg: string;
  resendMailConfirmationSubscription: Subscription;
  erroMsg: any;
  errorObject: any;
  private erooroMessageForBadCredentials;
  string;

  isCheckedTC = false; // this is to validate checkbox TC
  isSubmitClicked = false; // this is also for validate checkbox TC
  isUC = false;
  isLC = false;
  isIC = false;
  isNumeric = false;
  isSpclChar = false;
  isMinLength = false;
  validPassword = false;
  typePassword = false;
  isMinLengthFail: boolean = false
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  organisation: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  captchaResponse: string;
  selectedCountry: Country = new Country('', 'Choose your country');
  userSelectedSite: String = '';
  isNotAllowedChar = false;
  isDirty: boolean = false;
  public showEyeIcon = false

  // this sitekey belongs to localhost and ci2angular.westeurope.cloudapp.azure.com
  siteKey: string = Constants.captchaSiteKey;

  registerSuccess = false;

  countries = [];

  constructor(
    private router: Router,
    private userService: UserService,
    private _authservice: AuthService,
    private modalService: NgbModal
  ) {
    const countriesList = [];
    countriesJson.forEach(function (country) {
      countriesList.push(new Country(country.name, country.id));
    });

    this.countries = countriesList;
  }

  schneiderLogo = 'assets/app-images/schneider_LIO_White_RGB.png';
  isUsernameAvailable: boolean = false;
  isUsernameAvailableMsg: string = '';
  registerData: any = null;
  isUserExist(even) {
    if (!even.target.value.replace(/\s/g, '').length) {
      // this.isSiteAvailableMsg = 'site name with only space is not allowed';
      this.isUsernameAvailableMsg = '';
      return false;
    }
    if (!even.target.value) return false;
    let userNameCheck = even.target.value.trim();
    this._authservice.isUsernameExists(userNameCheck).subscribe((data) => {
      if (data['status'] == 'Success') {
        this.isUsernameAvailable = data.msg;
        this.isUsernameAvailableMsg = 'That username is taken. try another.';
        // this.isSiteAvailable = false;
      } else if (data['status'] == 'Failure') {
        this.isUsernameAvailable = false;
        this.isUsernameAvailableMsg = '';
      }
    });
  }

  errorModalImage: string = 'assets/img/power-flow/Error_icon.png';

  showMessage: any;
  @ViewChild('signUpErrorDialogTemplate', { static: true }) signUpErrorDialog;
  public signUpState: boolean = false;
  onSubmit() {
    this.isSubmitClicked = true;
    if (!this.isCheckedTC) {
      return false;
    }
    // alert('isUsernameAvailable------------'+this.isUsernameAvailable)
    if (!this.isUsernameAvailable) {
      this.isUsernameAvailableMsg = 'username not availbale';
      return false;
    }
    this.isUsernameAvailableMsg = '';
    this.signUpState = true;
    this.signUpForm.value.gRecaptchaResponse = this.captchaResponse;
    // this.signUpForm.value.userSelectedSite = "";
    this.registerData = this.signUpForm.value;
    this.userService
      .register(this.signUpForm.value)
      .pipe(map((res) => res))
      .subscribe(
        (data) => {
          this.signUpState = false;
          if (data['status'] == 'Success') {
            this.registerSuccessMsg = data['msg'];
            this.registerSuccess = true;
          } else if (data['status'] == 'Failure') {
            this.showMessage = data['msg'];
            // this._authservice.setModalMessage(data['msg']);
            // this.modalService.open(SignUpErrorDialogComponent, { windowClass: 'global-custom-class' });
            // this.alertService.error(data['msg']);
            let hasOpenedModal = this.modalService.hasOpenModals();
            if (hasOpenedModal) {
              this.modalService.dismissAll();
            }
            this.modalService.open(this.signUpErrorDialog, {
              windowClass: 'global-custom-class',
            });
            this.isSubmitClicked = false;
            this.captchaRef.reset();
            this.registerSuccess = false;
          }
        },
        (err) => {
          let errorObj = err.error;
          this.signUpState = false;
          this.isSubmitClicked = false;
          this.captchaRef.reset();
          this.registerSuccess = false;
          this.errorObject = err.error;
          this.erooroMessageForBadCredentials = this.errorObject.msg;
          if (this.erooroMessageForBadCredentials) {
            // this._authservice.setModalMessage(this.erooroMessageForBadCredentials);
            // this.modalService.open(SignUpErrorDialogComponent, { windowClass: 'global-custom-class' });
            this.showMessage = this.erooroMessageForBadCredentials;
            let hasOpenedModal = this.modalService.hasOpenModals();
            if (hasOpenedModal) {
              this.modalService.dismissAll();
            }
            this.modalService.open(this.signUpErrorDialog, {
              windowClass: 'global-custom-class',
            });
          } else {
            this.showMessage = 'Error while signup, Please try again later';
            let hasOpenedModal = this.modalService.hasOpenModals();
            if (hasOpenedModal) {
              this.modalService.dismissAll();
            }
            this.modalService.open(this.signUpErrorDialog, {
              windowClass: 'global-custom-class',
            });
            // this._authservice.setModalMessage("Error while signup");
            // this.modalService.open(SignUpErrorDialogComponent, { windowClass: 'global-custom-class' });
          }
          // this.alertService.error('Error');
        }
      );
  }

  validatePassWordForSpclChar (event: any) {
    const notallowedChar = /(?=.*?[-().'|<>?=;:+,])/
    const inputChar = event
    // console.log('value-------------' + event);
    // console.log('value-------------' + event.length);

    if (inputChar.length >= 8) {
      this.isMinLengthFail = true
    } else {
      this.isMinLengthFail = false
    }
    if (notallowedChar.test(inputChar)) {
      // console.log('isNotAllowedChar' + this.isNotAllowedChar);
      this.isNotAllowedChar = true
    } else {
      this.isNotAllowedChar = false
    }
    this.validatePassWord(inputChar)
  }

  validatePassWord(event: any) {
    const inputChar = event;
    this.validPassword = false;
    this.isLC = false;
    this.isUC = false;
    this.isNumeric = false;
    this.isSpclChar = false;
    this.isMinLength = false
    const pattern =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%&^*]).{8,}$/;
    const upperCase = /(?=.*?[A-Z])/;
    const lowerCase = /(?=.*?[a-z])/;
    const numeric = /(?=.*?[0-9])/;
    const spclChar = /(?=.*?[!@#$%&^*])/;
    const notallowedChar = /(?=.*?[-().'|<>?=;:+,])/;

    this.isNotAllowedChar = notallowedChar.test(inputChar)

    this.validPassword = pattern.test(inputChar)
    if (!this.validPassword) {
      this.isLC = lowerCase.test(inputChar)
      this.isUC = upperCase.test(inputChar)
      this.isNumeric = numeric.test(inputChar)
      this.isSpclChar = spclChar.test(inputChar)
    }
  }

  togglePassword = function () {
    this.typePassword = !this.typePassword;
  };

  onKeyPress () {
    this.showEyeIcon = true
  }

  FieldsChange(values: any) {
    // console.log(values.currentTarget.checked);
    this.isCheckedTC = values.currentTarget.checked;
  }

  resolved(cResponse: string) {
    // console.log(`Resolved captcha with response: ${cResponse}`);
    this.captchaResponse = cResponse;
    if (cResponse != '' && cResponse != null && cResponse != undefined) {
      this.captchaChecked = true;
    } else {
      this.captchaChecked = false;
    }
  }

  redirect() {
    this.router.navigate(['']);
  }

  /*This is for Terms & COndition Model box-if you click in UI, this code will get executed to open model box*/
  open(content) {
    this.modalService
      .open(TermsConditionsComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass:'modalSizeFix' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  has_any_spaces(regex, str) {
    if (regex.test(str) || str === '') {
      return false;
    }
    return true;
  }

  incorrectEmailId: boolean = true;
  incorrectEmailChars: boolean = false;
  validateEmailId(email) {
    let emaiId = email;
    // console.log('------------emaiId--------------' + emaiId + '---------------');
    // console.log('emaiId.endsWith(" ")-------------'+emaiId.endsWith(" "));
    // if (!email.target.value.replace(/\s/g, '').length) {
    // }
    // if (emaiId.includes(" ")) {
    //   alert()
    // }
    // const patternSpace = "/\s/g"
    // const expression = /^\S+$/g;
    // let result = this.has_any_spaces(expression, emaiId)
    // console.log('--result---------' + result);
    // if (/\s/.test(emaiId)) {
    //   // It has any kind of whitespace
    //   console.log('---space found ---------');
    // }
    if (emaiId.startsWith(' ') || emaiId.endsWith(' ')) {
      this.incorrectEmailChars = true;
    } else {
      this.incorrectEmailChars = false;
      // this.validPassword = true;
    }
    if (!emaiId) return false;
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;
    this.incorrectEmailId = re.test(emaiId);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  resendEmailVerification() {
    const sighUpData = this.registerData;
    const token = '';
    this.resendMailConfirmationSubscription = this._authservice
      .resendConfirmationMail(token, sighUpData.email)
      .subscribe(
        (data) => {
          if (data['status'] == 'Success') {
            this.isMessageSent = true;
            // this._authservice.setModalMessage(data['msg']);
            // this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
          }
        },
        (err) => {
          this.isMessageSent = false;
        }
      );
  }

  goToSESupportWebPortal() {
    window.open('https://solar.schneider-electric.com/netsuite/', '_blank');
  }

  goToSchneiderWebPortalForDataPrivacyAndCookiePolicy() {
    window.open(
      'https://www.se.com/ww/en/about-us/legal/data-privacy.jsp',
      '_blank'
    );
  }

  //  ngOnDestroy(): void {
  //    if (this.resendMailConfirmationSubscription) this.resendMailConfirmationSubscription.unsubscribe()
  //  }
}
