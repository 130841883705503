import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class MapsService {
  public markers: Observable<any[]>;
  private _markers: BehaviorSubject<any[]>;
  private emitChangeSource = new Subject<any>();
  private emitSiteCoordinateChangeSource = new Subject<any>();
  private emitChangeMarkerChangeSource = new Subject<any>();
  private emitUpdateSiteMapMarkerAlarmsChangeSource = new Subject<any>();
  private emitSiteRegistrationChangeMarkerChangeSource = new Subject<any>();
  private emitMultisiteMapViewChangeSource = new Subject<any>();
  private emitMultisiteMobileViewChangeSource = new Subject<any>();
  private emitRemoveMarkerChangeSource = new Subject<any>();
  private emitChangeSourceInSIteRegistration = new Subject<any>();
  private emitRemoveMarkerInSiteUpdateChangeSource = new Subject<any>();

  constructor(private http: HttpClient) {
    this._markers = <BehaviorSubject<any[]>>new BehaviorSubject([]);
    this.markers = this._markers.asObservable();
  }

  publishMapMarkers(markers: any[]) {
    this._markers.next(markers);
  }

  changeEmitted$ = this.emitChangeSource.asObservable();
  updateCoordinateEvent(coordinatesDetails) {
    this.emitChangeSource.next(coordinatesDetails);
  }

  changeSiteCoordinateEmitted$ = this.emitSiteCoordinateChangeSource.asObservable();
  updateSiteCoordinateEvent(coordinatesDetails) {
    this.emitSiteCoordinateChangeSource.next(coordinatesDetails);
  }

  changeEmittedInSIteRegistration$ = this.emitChangeSourceInSIteRegistration.asObservable();
  updateCoordinateEventInSIteRegistration(coordinatesDetails) {
    this.emitChangeSourceInSIteRegistration.next(coordinatesDetails);
  }

  changeMarkerEmitted$ = this.emitChangeMarkerChangeSource.asObservable();
  updateLocationAddressInUpdateSiteForm(coordinatesDetails) {
    this.emitChangeMarkerChangeSource.next(coordinatesDetails);
  }

  removeMarkerEmitted$ = this.emitRemoveMarkerChangeSource.asObservable();
  siteRegistrationRemoveMarker(coordinatesDetails) {
    this.emitRemoveMarkerChangeSource.next(coordinatesDetails);
  }

  removeMarkerInSiteUpdateEmitted$ = this.emitRemoveMarkerInSiteUpdateChangeSource.asObservable();
  siteUpdateRemoveMarker(coordinatesDetails) {
    this.emitRemoveMarkerInSiteUpdateChangeSource.next(coordinatesDetails);
  }

  siteRegistrationChangeMarkerEmitted$ = this.emitSiteRegistrationChangeMarkerChangeSource.asObservable();
  siteRegistrationUpdateLocationAddressInUpdateSiteForm(coordinatesDetails) {
    this.emitSiteRegistrationChangeMarkerChangeSource.next(coordinatesDetails);
  }

  multisiteMapViewEmitted$ = this.emitMultisiteMapViewChangeSource.asObservable();
  multisiteMapViewUpdate(mapView) {
    this.emitMultisiteMapViewChangeSource.next(mapView);
  }
  multisiteMobileViewEmitted$ = this.emitMultisiteMobileViewChangeSource.asObservable();
  multisiteMobileViewUpdate(mobileView) {
    this.emitMultisiteMobileViewChangeSource.next(mobileView);
  }

  // updateSiteMapMarkerAlarms$ = this.emitUpdateSiteMapMarkerAlarmsChangeSource.asObservable();
  // updateSiteMapMarkerAlarmsDetails(coordinatesDetails) {
  //   console.log('updateSiteMapMarkerAlarmsDetails--------------------' + JSON.stringify(coordinatesDetails));
  //   this.emitUpdateSiteMapMarkerAlarmsChangeSource.next(coordinatesDetails);
  // }
}
