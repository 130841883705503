export class Country {
 
  constructor(public name: string, public id: string) { }
  /*
  constructor(id: number, name: string, iso: string, nicename: string, iso3: string, numcode: number, phonecode: number) {
    this.id = id;
    this.name = this.name;
    this.iso = this.iso;
    this.nicename = this.nicename;
    this.iso3 = this.iso3;
    this.numcode = this.numcode;
    this.phonecode = this.phonecode;
  }
  */
 
}