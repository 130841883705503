import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MultisiteService {

    constructor(private http: HttpClient) { }

    getSiteData(pageIndex: number): Observable<any> {
        return this.http.get<any>(`sitetable/` + pageIndex)
            .pipe(map(data => {
                return data;
            }));
    }

    getSiteSearchData(str: string): Observable<any> {
        return this.http.get<any>(`sitetable/search/` + str)
            .pipe(map(data => {
                return data;
            }));
    }

    getAlarmsData(): Observable<any> {
        return this.http.get<any>(`sitetable/alarms`)
            .pipe(map(data => {
                return data;
            }));
    }

    doGetSiteData(): Observable<any> {
        return this.http.get<any>(`sitetable`)
            .pipe(map(data => {
                return data;
            }));
    }

    /**
     * Gets site data by page index
     * @param pageIndex 
     * @returns site data by page index 
     */
    public getSiteDataByPageIndex(pageIndex: number): Observable<any> {
        return this.http.get<any>(`sitetable/` + pageIndex);
    }

    /**
     * Gets alarms data
     * @returns alarms data 
     */
    public getAlarmsDataByPageIndex(pageIndex: number): Observable<any> {
        return this.http.get<any>(`sitetable/alarms/` + pageIndex)
            .pipe(map(data => {
                return data;
            }));
    }

    doGetCustomizedAllSitesDataSiteData(sites: any): Observable<any> {
        let siteTempObj = {
            "sitesNames": sites
        }
        return this.http.post<any>(`custom/sitetable/list`, sites)
            .pipe(map(data => {
                return data;
            }));
    }

    saveCustomizeSiteTable(data: any, masterValue: any): Observable<any> {
        let siteObj = {
            "data": data,
            "isAllSitesSelected": masterValue
        }
        return this.http.post<any>(`custom/sitetable`, siteObj)
            .pipe(map(data => {
                return data;
            }));
    }

    doGetCustomizeSiteTable(): Observable<any> {
        return this.http.get<any>(`custom/sitetable`)
            .pipe(map(data => {
                return data;
            }));
    }

    resetCustomSiteTable(): Observable<any> {
        return this.http.get<any>(`reset/default/sitetable`)
            .pipe(map(data => {
                return data;
            }));
    }


    getSiteListDetails(): Observable<any> {
        return this.http.get<any>(`sitemarker`).pipe(map(data => {
            return data;
        }));
    }

    getPowerGuage(): Observable<any> {
        return this.http.get<any>(`powergauge`).pipe(map(data => {
            return data;
        }));
    }

    getMultisiteMapViewState(): Observable<any> {
        return this.http.get<any>(`multisite/mapview`).pipe(map(data => {
            return data;
        }));
    }


    setMultisiteMapViewState(mapViewState: string): Observable<any> {
        let mapViewStateObj = {
            "mapViewState": mapViewState
        };
        const url = 'multisite/map';
        return this.http.post(url, mapViewStateObj)
    }

    private emitSIteListDetailSource = new Subject<any>();
    emitSIteListDetailSourceChangeEmitted$ = this.emitSIteListDetailSource.asObservable();
    emitSIteListDetailSourceChange(data) {
        this.emitSIteListDetailSource.next(data);
    }

    invokeFirstComponentFunction = new EventEmitter();
    subsVar: Subscription;

    onMultisiteSitetableComponentCustomizeTableButtonClick() {
        this.invokeFirstComponentFunction.emit();
    }

    private messageSource = new BehaviorSubject(null);
    currentMessage = this.messageSource.asObservable();


    changeMessage(message: boolean) {
        this.messageSource.next(message)
    }

    private alarmsCountSource = new BehaviorSubject(null);
    alarmsCountDetails = this.alarmsCountSource.asObservable();

    updateAlarmsCountSource(alarmsDetail: any) {
        this.alarmsCountSource.next(alarmsDetail)
    }

    private searchSitesSource = new BehaviorSubject(null);
    searchSitesSourceDetails = this.searchSitesSource.asObservable();

    searchSiteDetais(siteData: any) {
        this.searchSitesSource.next(siteData)
    }

    private searchSitesSpinnerStartSource = new BehaviorSubject(null);
    searchSitesSpinnerStartSourceDetails = this.searchSitesSpinnerStartSource.asObservable();

    searchSitesSpinnerStartSourceUpdate(siteData: string) {
        this.searchSitesSpinnerStartSource.next(siteData)
    }

    private doGetSiteTableForEmptySearchStartSource = new BehaviorSubject(null);
    doGetSiteTableForEmptySearchDetails = this.doGetSiteTableForEmptySearchStartSource.asObservable();

    doGetSiteTableForEmptySearchUpdate(siteData: string) {
        this.doGetSiteTableForEmptySearchStartSource.next(siteData)
    }

}
