/**
 * @author Anuj Grover
 * @email anuj.grover@non.se.com |  SESA625719
 * @create date 2021-07-22 17:34:38
 * @modify date 2021-07-28 02:39:35
 * @desc [Root Module]
 */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PushNotificationsService } from 'ng-push';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginSignupModule } from './modules/login-signup/login-signup.module';
import { AuthService } from './services/auth.service';
import { AuthInterceptor } from './services/auth.interceptor';
import { UserService } from './services/user.service';
import { HeaderModule } from './modules/header/header.module';
import { AuthGuard } from './services/auth-guard.service';
import { CanDeactivateGuard } from './modules/my-dashboard/dashboard/can-deactivate-guard.service';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ResService } from './services/res.service';
import { FooterModule } from './modules/footer/footer.module';
// import { AlarmsAndWarningsRightSidebar } from './modules/alarms-and-warnings-right-sidebar/alarms-and-warnings-right-sidebar.module';
import { SidebarModule } from 'ng-sidebar';
import { DatePipe } from '@angular/common';
import { SpinnerInterceptor } from './spinner-Interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { ErrorHandlerInterceptor } from './services/error-handler.interceptor.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    //* App Module
    BrowserModule,
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot(),
    HttpClientModule,
    //* Feature Modules
    LoginSignupModule,
    NgbModule,
    HeaderModule,
    // HomeModule,
    // Performance,
    // ReportingModule,
    // Event,
    // ConfigurationModule,
    ReactiveFormsModule,
    FormsModule,
    SidebarModule.forRoot(),
    FontAwesomeModule,
    FooterModule,
    // AlarmsAndWarningsRightSidebar,
    AppRoutingModule, //* This Must be called last
    NgxSpinnerModule,
    PdfViewerModule
  ],
  exports: [NgxSpinnerModule],
  providers: [
    AuthService,
    AuthGuard,
    CanDeactivateGuard,
    UserService,
    PushNotificationsService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, // these interceptors are executed by order wise
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true }, // these interceptors are executed by order wise
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorHandlerInterceptor,
    //   multi: true
    // },
    ResService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
