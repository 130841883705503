<div class="profileModal">
	<ngx-spinner name="userProfileForm" [fullScreen]="false" size="default" color="#3dcd58" type="ball-clip-rotate"
		[zIndex]="999"></ngx-spinner>
	<div class="modal-body profileModalBackground">
		<div class="container">
			<div class="d-flex justify-content-between pt-1">
				<h3 class="modal-title">User Profile</h3>
				<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
					<span aria-hidden="true">&times;</span> </button>
			</div>

			<div *ngIf="noSiteVal"><span>No site is configured for the user</span></div>

			<form [formGroup]="profileForm" (ngSubmit)="saveUserProfile()">
				<div class="row">
					<div class="col-12">
						<div class="card card-profile">
							<!-- User Profile Image -->

							<div class="card-body">
								<div class="row mt-2">
									<div class="col-md-6 mb-xl-0 mb-2">
										<label class="labels labelsStyle required-field">First Name</label>
										<input type="text" class="form-control firstName" placeholder="First Name"
											id="firstName" formControlName="firstName" autocomplete="turnoff" />
										<span
											*ngIf="!profileForm.controls['firstName'].valid && profileForm.controls['firstName'].errors.required"
											class="errorMessages">
											First name is required.
										</span>
										<span
											*ngIf="!profileForm.controls['firstName'].valid  && profileForm.controls['firstName'].errors.pattern"
											class="errorMessages">
											Only alphabets and ' . are allowed.
										</span>
										<span
											*ngIf="!profileForm.controls['firstName'].valid  && profileForm.controls['firstName'].errors.maxlength"
											class="errorMessages">
											First name maximum 21 characters only.
										</span>
									</div>
									<div class="col-md-6">
										<label class="labels labelsStyle">Last Name</label>
										<input type="text" class="form-control lastName" placeholder="Last Name"
											formControlName="lastName" autocomplete="turnoff" />
										<span
											*ngIf="!profileForm.controls['lastName'].valid  && profileForm.controls['lastName'].errors.pattern"
											class="errorMessages">
											Only alphabets and ' . are allowed.
										</span>
										<span
											*ngIf="!profileForm.controls['lastName'].valid  && profileForm.controls['lastName'].errors.maxlength"
											class="errorMessages">
											Last name maximum 21 characters only.
										</span>
									</div>

								</div>
								<div class="row mt-2">
									<div class="col-md-6 mb-xl-0 mb-2">
										<label class="labels labelsStyle required-field">Email</label>
										<input type="text" class="form-control email" placeholder="Email"
											formControlName="email" autocomplete="turnoff" readonly />
									</div>
									<div class="col-md-6">
										<label class="labels labelsStyle">Phone Number</label>
										<input type="text" class="form-control phone" placeholder="Enter Phone Number"
											formControlName="phone" autocomplete="turnoff" />
										<span
											*ngIf="!profileForm.controls['phone'].valid  && profileForm.controls['phone'].errors.minlength"
											class="errorMessages">
											Phone number should be 10-15 digit.
										</span>
										<span
											*ngIf="!profileForm.controls['phone'].valid  && profileForm.controls['phone'].errors.maxlength"
											class="errorMessages">
											Phone number maximum 15 numbers only.
										</span>
										<div *ngIf="!profileForm.controls['phone'].valid  && profileForm.controls['phone'].errors.pattern"
											class="errorMessages">
											Only numerical values allowed.
										</div>
									</div>
								</div>




								<!-- State & Pincode -->
								<div class="row mt-2">
									<div class="col-md-6">
										<label class="labels labelsStyle required-field">City</label>
										<input type="text" class="form-control city" autocomplete="turnoff"
											placeholder="City" formControlName="city" />

										<span
											*ngIf="!profileForm.controls['city'].valid  && profileForm.controls['city'].errors.minlength"
											class="errorMessages">
											City must be at least 2 characters.
										</span>
										<span
											*ngIf="!profileForm.controls['city'].valid  && profileForm.controls['city'].errors.maxlength"
											class="errorMessages">
											City maximum 21 characters only.
										</span>
										<span
											*ngIf="!profileForm.controls['city'].valid  && profileForm.controls['city'].errors.pattern"
											class="errorMessages">
											Only alphabets and . - ' are allowed.
										</span>
									</div>
									<div class="col-md-6 mb-xl-0 mb-2">
										<label class="labels labelsStyle">State</label>
										<input type="text" class="form-control city" autocomplete="turnoff"
											placeholder="State" formControlName="state" />

										<span
											*ngIf="!profileForm.controls['state'].valid  && profileForm.controls['state'].errors.maxlength"
											class="errorMessages">
											State maximum 50 characters only.
										</span>
										<span
											*ngIf="!profileForm.controls['state'].valid  && profileForm.controls['state'].errors.pattern"
											class="errorMessages">
											Only alphabets and . - ' are allowed.
										</span>
									</div>
								</div>

								<!-- Country & City -->
								<div class="row mt-2">
									<div class="col-md-6">
										<select id="inputState" class="form-control country" formControlName="country">
											<option *ngFor="let optionName of countryList" [value]="optionName.id">
												{{optionName.name}}</option>
										</select>
										<span
											*ngIf="!profileForm.controls['country'].valid  && profileForm.controls['country'].errors.required"
											class="errorMessages">
											Country is required.
										</span>
									</div>
									<div class="col-md-6 mb-xl-0 mb-2">
										<label class="labels labelsStyle">Zip Code</label>
										<input type="text" class="form-control city" autocomplete="turnoff"
											placeholder="Zip Code" formControlName="pincode" />

										<span
											*ngIf="!profileForm.controls['pincode'].valid  && profileForm.controls['pincode'].errors.maxlength"
											class="errorMessages">
											Pincode maximum 50 characters only.
										</span>
										<span
											*ngIf="!profileForm.controls['pincode'].valid  && profileForm.controls['pincode'].errors.pattern"
											class="errorMessages">
											Only alphabets, numbers and - are allowed.
										</span>
									</div>
								</div>


								<!-- Organisation -->
								<div class="row mt-2">
									<div class="col-md-6">
										<label class="labels labelsStyle">Organization</label>
										<input type="text" class="form-control city" autocomplete="turnoff"
											placeholder="Organization" formControlName="organisation" />

										<span
											*ngIf="!profileForm.controls['organisation'].valid  && profileForm.controls['organisation'].errors.maxlength"
											class="errorMessages">
											Organization maximum 100 characters only.
										</span>
										<span
											*ngIf="!profileForm.controls['organisation'].valid  && profileForm.controls['organisation'].errors.pattern"
											class="errorMessages">
											Only alphabets, numbers and . - ' are allowed.
										</span>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-md-12">
										<label class="labels labelsStyle required-field">Address</label>
										<textarea type="textarea" class="form-control profileAddress"
											placeholder="Enter Address" autocomplete="turnoff" rows="3" cols="50"
											formControlName="address"> </textarea>

										<span
											*ngIf="!profileForm.controls['address'].valid  && profileForm.controls['address'].errors.minlength"
											class="errorMessages">
											Address must be at least 5 characters.
										</span>
										<span
											*ngIf="!profileForm.controls['address'].valid  && profileForm.controls['address'].errors.maxlength"
											class="errorMessages">
											Address maximum 100 characters only.
										</span>
										<span
											*ngIf="!profileForm.controls['address'].valid  && profileForm.controls['address'].errors.pattern"
											class="errorMessages">
											Only alphabets and numbers ' . / # ( ) & , - are allowed.
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-12 card-profile">
						<div class="modalProfileButtons-desktop">
							<span class="successMessage" *ngIf="successMessage">{{successMessage}}</span>&nbsp;&nbsp;
							<button class="btn btn-primary profile-button" type="submit" *ngIf="!noSiteVal"
								[disabled]="profileForm.invalid || !profileForm.dirty"> <i
									class="se-icon-user_standard user_standardCss" aria-hidden="true"></i>
								Save Profile </button>
						</div>
					</div>
				</div>
			</form>
			<form [formGroup]="pwdForm" (ngSubmit)="changePassword()">
				<div class="row pt-4">
					<div class="col-12">
						<div class="card card-profile">
							<div class="card-body">
								<div class="row mt-2">
									<div class="col-12">
										<div class="d-flex justify-content-between align-items-center ">
											<h4 class="text-right passwordCss changePwdProfile">Change Password</h4>
										</div>
									</div>
								</div>

								<div class="row mt-2">
									<div class="col-md-6 mb-xl-0 mb-2">
										<label class="labels labelsStyle required-field">Old Password</label>
										<div class="input-group input-group-sm">
											<div class="input-group-prepend" style="height: 31px;">
												<div class="input-group-text">
													<svg _ngcontent-lxy-c3="" xmlns:xlink="http://www.w3.org/1999/xlink"
														class="auth0-lock-icon auth0-lock-icon-box" focusable="false"
														height="14px" version="1.1" viewBox="0 0 13 16" width="11px"
														xmlns="http://www.w3.org/2000/svg">
														<g _ngcontent-lxy-c3="" fill="none" fill-rule="evenodd"
															stroke="none" stroke-width="1">
															<g _ngcontent-lxy-c3="" fill="#888888"
																transform="translate(-288.000000, -1508.000000)">
																<path _ngcontent-lxy-c3=""
																	d="M299,1523.998 L290,1523.998 C288.896,1523.998 288,1523.102 288,1521.999 L288,1515.999 C288,1514.895 288.896,1513.998 290,1513.998 L290,1513.998 L290,1512.499 C290,1510.015 292.015,1507.999 294.5,1507.999 C296.985,1507.999 299,1510.015 299,1512.499 L299,1513.999 C300.104,1513.999 301,1514.895 301,1515.999 L301,1521.999 C301,1523.103 300.104,1523.998 299,1523.998 L299,1523.998 Z M298,1512.499 C298,1510.566 296.433,1508.999 294.5,1508.999 C292.567,1508.999 291,1510.566 291,1512.499 L291,1513.998 L298,1513.998 L298,1512.499 L298,1512.499 Z M300,1515.999 C300,1515.446 299.552,1514.998 299,1514.998 L290,1514.998 C289.447,1514.998 289,1515.446 289,1515.999 L289,1521.999 C289,1522.551 289.447,1522.998 290,1522.998 L299,1522.998 C299.552,1522.998 300,1522.551 300,1521.999 L300,1515.999 L300,1515.999 Z M294.5,1520.998 C294.224,1520.998 294,1520.774 294,1520.498 L294,1517.498 C294,1517.223 294.224,1516.999 294.5,1516.999 C294.776,1516.999 295,1517.223 295,1517.498 L295,1520.498 C295,1520.774 294.776,1520.998 294.5,1520.998 L294.5,1520.998 Z">
																</path>
															</g>
														</g>
													</svg>
												</div>
											</div>
											<input [type]="seeOld ? 'text' : 'password'" class="form-control"
												id="oldPassword" placeholder="Old Password"
												formControlName="oldPassword" />
											<i (click)="toggleSeeOld()" class="showPassword fa"
												[ngClass]="{'se-eye': !seeOld,'fa-eye-slash': seeOld}"></i>
										</div>
										<span class="errorMessages"
											*ngIf="oldPasswordCtl.touched && !oldPasswordCtl.valid && oldPasswordCtl.errors.required">
											Old Password is required
										</span>
									</div>
									<div class="col-md-6 mb-xl-0 mb-2">
										<label class="labels labelsStyle required-field">New Password</label>
										<div class="input-group input-group-sm">
											<div class="input-group-prepend" style="height: 31px;">
												<div class="input-group-text">
													<svg _ngcontent-lxy-c3="" xmlns:xlink="http://www.w3.org/1999/xlink"
														class="auth0-lock-icon auth0-lock-icon-box" focusable="false"
														height="14px" version="1.1" viewBox="0 0 13 16" width="11px"
														xmlns="http://www.w3.org/2000/svg">
														<g _ngcontent-lxy-c3="" fill="none" fill-rule="evenodd"
															stroke="none" stroke-width="1">
															<g _ngcontent-lxy-c3="" fill="#888888"
																transform="translate(-288.000000, -1508.000000)">
																<path _ngcontent-lxy-c3=""
																	d="M299,1523.998 L290,1523.998 C288.896,1523.998 288,1523.102 288,1521.999 L288,1515.999 C288,1514.895 288.896,1513.998 290,1513.998 L290,1513.998 L290,1512.499 C290,1510.015 292.015,1507.999 294.5,1507.999 C296.985,1507.999 299,1510.015 299,1512.499 L299,1513.999 C300.104,1513.999 301,1514.895 301,1515.999 L301,1521.999 C301,1523.103 300.104,1523.998 299,1523.998 L299,1523.998 Z M298,1512.499 C298,1510.566 296.433,1508.999 294.5,1508.999 C292.567,1508.999 291,1510.566 291,1512.499 L291,1513.998 L298,1513.998 L298,1512.499 L298,1512.499 Z M300,1515.999 C300,1515.446 299.552,1514.998 299,1514.998 L290,1514.998 C289.447,1514.998 289,1515.446 289,1515.999 L289,1521.999 C289,1522.551 289.447,1522.998 290,1522.998 L299,1522.998 C299.552,1522.998 300,1522.551 300,1521.999 L300,1515.999 L300,1515.999 Z M294.5,1520.998 C294.224,1520.998 294,1520.774 294,1520.498 L294,1517.498 C294,1517.223 294.224,1516.999 294.5,1516.999 C294.776,1516.999 295,1517.223 295,1517.498 L295,1520.498 C295,1520.774 294.776,1520.998 294.5,1520.998 L294.5,1520.998 Z">
																</path>
															</g>
														</g>
													</svg>
												</div>
											</div>
											<input [type]="seeNew ? 'text' : 'password'" class="form-control"
												id="newPassword" placeholder="New Password"
												formControlName="newPassword" />
											<i (click)="toggleSeeNew()" class="showPassword fa"
												[ngClass]="{'se-eye': !seeNew,'fa-eye-slash': seeNew}"></i>
										</div>
										<!-- <span class="errorMessages"
											*ngIf="newPasswordCtl.touched && !newPasswordCtl.valid && newPasswordCtl.errors.required">
											New Password is required
										</span> -->
										<div class="pass-err-container" *ngIf="newPasswordCtl.dirty && !newPasswordCtl.valid">
											<div class="pass-err-inner">
												<div>
													<p class="err-header" style="padding-bottom: 7px;"> Password must meet the following requirements: </p>
													<p class="err-msg{{!newPasswordCtl.value ? '' : newPasswordCtl.errors.minlength || !newPasswordCtl.value ? '' : ' err-msg2'}}"> {{!newPasswordCtl.value ? '✘' : newPasswordCtl.errors.minlength ? '✘' : '✔'}} At least 8 characters in length </p>
													<p class="err-msg{{!newPasswordCtl.value ? '' : newPasswordCtl.errors.passwordStrength?.lowerCase || !newPasswordCtl.value ? '' : ' err-msg2'}}"> {{!newPasswordCtl.value ? '✘' : newPasswordCtl.errors.passwordStrength?.lowerCase ? '✘' : '✔'}} Lower case letters (a-z) </p>
													<p class="err-msg{{!newPasswordCtl.value ? '' : newPasswordCtl.errors.passwordStrength?.upperCase || !newPasswordCtl.value ? '' : ' err-msg2'}}"> {{!newPasswordCtl.value ? '✘' : newPasswordCtl.errors.passwordStrength?.upperCase ? '✘' : '✔'}} Upper case letters (A-Z) </p>
													<p class="err-msg{{!newPasswordCtl.value ? '' : newPasswordCtl.errors.passwordStrength?.numeric || !newPasswordCtl.value ? '' : ' err-msg2'}}"> {{!newPasswordCtl.value ? '✘' : newPasswordCtl.errors.passwordStrength?.numeric ? '✘' : '✔'}} Numbers (0-9) </p>
													<p class="err-msg{{!newPasswordCtl.value ? '' : newPasswordCtl.errors.passwordStrength?.special || !newPasswordCtl.value ? '' : ' err-msg2'}}"> {{!newPasswordCtl.value ? '✘' : newPasswordCtl.errors.passwordStrength?.special ? '✘' : '✔'}} Special characters (!@#$%&^*) </p>
												</div>
											</div>
										</div>
										<!-- <ul *ngIf="newPasswordCtl.touched && !newPasswordCtl.valid">
											<li class="errorMessage txt11" *ngIf="newPasswordCtl.errors.minlength">
												Should be at least 8
												characters in
												length</li>
											<li class="errorMessage txt11" *ngIf="newPasswordCtl.errors.maxlength">
												Should be at most 25
												characters in
												length</li>
											<li class="errorMessage txt11"
												*ngIf="newPasswordCtl.errors.passwordStrength">
												Contains following characters
											</li>
											<ul>
												<li class="errorMessage"
													*ngIf="newPasswordCtl.errors.passwordStrength?.lowerCase">
													Lower
													case letters (a-z)</li>
												<li class="errorMessage"
													*ngIf="newPasswordCtl.errors.passwordStrength?.upperCase">
													Upper
													case letters (A-Z)</li>
												<li class="errorMessage"
													*ngIf="newPasswordCtl.errors.passwordStrength?.numeric">
													Numbers
													(i.e 0-9)</li>
												<li class="errorMessage"
													*ngIf="newPasswordCtl.errors.passwordStrength?.special">
													Special
													characters (e.g.
													!@#$%&^*)</li>
											</ul> -->
											<!-- <li class="errorMessage txt11" *ngIf="newPasswordCtl.errors.notAllowed">
												Invalid characters (allowed only !@#$%&^*)</li>
										</ul> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row pb-4">
					<div class="col-12 card-profile">
						<div class="modalProfileButtons-desktop">
							<span
								[ngClass]="passwordResetStatus.status === 'Success' ? 'successMessage': 'failureMessage'"
								*ngIf="passwordResetStatus">{{passwordResetStatus?.msg}}</span>&nbsp;&nbsp;
							<button class="btn btn-primary profile-button"
								[disabled]="pwdForm.invalid || !pwdForm.dirty"> <i class="se-icon-key user_standardCss"
									aria-hidden="true"></i>&nbsp;&nbsp;Change
								Password </button>
						</div>
					</div>
				</div>
			</form>
			<form [formGroup]="pwdForm" (ngSubmit)="changePassword()">
				<div class="row pt-4">
					<div class="col-12">
						<div class="card card-profile">
							<div class="card-body">
								<div class="row mt-2">
									<div class="col-12">
										<div class="d-flex justify-content-between align-items-center ">
											<h4 class="text-right passwordCss changePwdProfile">Terms and Conditions</h4>
										</div>
									</div>
								</div>

								<div class="tncbtn">
									
									<a target="_blank" href="../../../../../assets/pdf/terms-conditions.pdf">
										Terms and conditions
									</a>
								</div>

								
							</div>
						</div>
					</div>
				</div>
				
			</form>
			<div class="row pt-4">
				<div class="col-12">
					<div class="card card-profile">
						<div class="card-body">
							<div class="row mt-2">
								<div class="col-12">
									<div class="d-flex justify-content-between align-items-center ">
										<h4 class="text-right passwordCss changePwdProfile">Delete Account</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row pb-4" *ngIf="deleteAccountStatus">
				<div class="col-8 offset-md-4"
					[ngClass]="deleteAccountStatus.status === 'Success' ? 'successMessage': 'failureMessage'">
					{{deleteAccountStatus?.msg}}</div>
			</div>
			<div class="row pb-4">
				<div class="col-8">
					You will lose all your configured sites and your access to our site
				</div>
				<div class="col-4 card-profile">
					<div class="modalProfileButtons-desktop">
						<button class="btn btn-primary delete-button"
							(click)="deleteUserModalPopUp(deleteUserModal)" [disabled]="isDemoUser">Delete
							Account </button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Delete user account pop-up starts here -->
<ng-template #deleteUserModal let-c="close" let-d="dismiss" class="add-user-modal">
	<div class="modal-header">
		<h4 class="modal-title cust-modal-title" id="modal-basic-title">Delete Account</h4>
	</div>
	<div class="modal-body text-center">
		You will lose all your configured sites and access to our site.
		<p class="text-danger">Are you sure, you want to delete your account ?</p>
	</div>
	<!-- <hr> -->
	<div class="modal-footer justify-content-center">
		<button type="button" class="btn modal-cust-btn" (click)="deleteAccount()">Confirm</button>
		<button type="button" class="btn modal-cust-btn" (click)="d('Cross click')">Cancel</button>
	</div>
</ng-template>