<div class="container-fluid">
    <div class="row rowDiv">
        <div class="col-xl-2 col-lg-2 col-xs-12 rectangleParent" [ngClass]="{'disabledNav': context === 'upgrade'}">
            <div class="rectangle_one mt-1">
                <div *ngIf="isMobileView"
                     class="center_text">
                    <button type="button"
                            (click)="callParentForLeftSidebarChange()"
                            class="btn icon-color">
                        <fa-icon [icon]="faBars"
                                 size="lg"></fa-icon>
                    </button>
                </div>

                <div class="center_text d-none d-xl-block">
                    <span class='topBar_brandname font-weight-bold'>Insight</span>
                    <span class='topBar_brandname'
                          style="font-weight: 500 !important;">Cloud</span>
                </div>

                <!--! For mobile and ipad devices 260px to 768px -->
                <div class="center_text  d-xs-block d-sm-block  d-md-block d-lg-block d-xl-none">
                    <span class='topBar_brandname font-weight-bold logo-font-size'>Insight</span>
                    <span class='topBar_brandname logo-font-size'>Cloud</span>
                </div>
                <!--! For mobile and ipad devices 260px to 768px -->
                <div *ngIf="isMobileView"
                     class="">
                    <div class="feedbackIconMobileView"
                         (click)="openFeedBackModal()">
                        <i _ngcontent-okw-c185=""
                           class="se-icon-communication_bubbles3 seiconFeedbackClass"></i>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-xl-10 col-lg-10 d-none d-sm-none d-lg-block d-md-none d-xl-block">
            <div class="rectangle_two mt-1 icon-color align-items-center">
                <div class="d-flex justify-content-between">

                    <div class="d-flex flex-column align-items-center m-2" [ngClass]="{'disabledNav': context === 'upgrade'}"
                         *ngIf="localSiteListCountTopBar!=0"
                         (click)="submenuClick('performance/sourceAnalysisComparison')">
                        <i _ngcontent-okw-c185=""
                           class="se-icon-graph_up seiconClass"></i>
                        <span class="topBarCaption">
                            Performance
                        </span>
                    </div>
                    <div class="d-flex flex-column align-items-center m-2" [ngClass]="{'disabledNav': context === 'upgrade'}"
                         *ngIf="localSiteListCountTopBar!=0"
                         (click)="submenuClick('configuration/siteAndDevices', 'Configuration', 'Site Settings', 'toggleConfigurationItem')">
                        <i _ngcontent-okw-c185=""
                           class="se-icon-action_settings1 seiconClass"></i>
                        <span class="topBarCaption">
                            Configuration
                        </span>
                    </div>
                    <div class="d-flex flex-column align-items-center m-2" [ngClass]="{'disabledNav': context === 'upgrade'}"
                         *ngIf="(localSiteListCountTopBar > 1) && !isCI1UserType"
                         (click)="submenuClick('site/overview', 'Multisite', 'Site Overview', 'toggleMultisiteItem' )">
                        <i _ngcontent-okw-c185=""
                           class="se-icon-switchboard_add seiconClass"></i>
                        <span class="topBarCaption">
                            Multi Site
                        </span>
                    </div>
                    <div class="d-flex flex-column align-items-center m-2" [ngClass]="{'disabledNav': context === 'upgrade'}"
                         *ngIf="(localSiteListCountTopBar == 1) && !isCI1UserType"
                         (click)="submenuClick('performance/deviceComparison', 'Performance', 'Device Comparison', 'togglePerformanceItem' )">
                        <i _ngcontent-okw-c185=""
                           class="se-icon-action_compare seiconClass"></i>
                        <span class="topBarCaption">
                            Device Comparison
                        </span>
                    </div>
                    <div class="divider m-2" *ngIf="localSiteListCountTopBar!=0"></div>
                    <div class="d-flex align-items-center mr-2"
                         style="margin-left: .3rem !important;">
                        <div ngbDropdown
                             #myDrop="ngbDropdown"
                             placement="bottom-left">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column align-items-center">

                                    <div class="d-flex"
                                    *ngIf="localSiteListCountTopBar!=0">
                                        <div>
                                            <i _ngcontent-okw-c185=""
                                               class="se-icon-user_standard seiconProfileClass"
                                               *ngIf="!profileImageSource"
                                               ngbDropdownToggle></i>
                                            <img [src]="profileImageSource"
                                                 class="imageTopBarProfile"
                                                 *ngIf="profileImageSource"
                                                 ngbDropdownToggle>
                                        </div>
                                        <span style="margin-right: .1rem !important;margin-left:5px;font-family: Nunito;font-style: normal;font-weight: normal;font-size: 16px;line-height: 30px;color: #333333; cursor: pointer;"
                                              ngbDropdownToggle>
                                            {{firstName}}
                                        </span>
                                        <fa-icon [icon]="faCaretDown"
                                                 style="color: #333333 !important; line-height: 30px;"
                                                 [classes]="['ml-1']"
                                                 ngbDropdownToggle></fa-icon>
                                    </div>
                                </div>
                            </div>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem
                                        (click)="openProfileeditModal()" [ngClass]="{'disabledNav': context === 'upgrade'}">My Profile</button>
                                <button ngbDropdownItem
                                        (click)="logOut()">Logout</button>
                            </div>
                        </div>
                    </div>
                    <div class="divider m-2" *ngIf="localSiteListCountTopBar!=0"></div>
                    <!-- feedback -->
                    <div class="d-flex flex-column align-items-center ml-2 mr-2"
                         (click)="openFeedBackModal()" *ngIf="localSiteListCountTopBar!=0">
                        <i _ngcontent-okw-c185=""
                           class="se-icon-communication_bubbles3 seiconFeedbackClass"></i>
                    </div>
                </div>
                <i _ngcontent-okw-c185="" [ngClass]="{'disabledNav': context === 'upgrade'}"
                   (click)="goToSchneiderWebPortal()"
                   class="se-icon-logo_Schneider_Electric_logotype seiconLogoClass"></i>
            </div>
        </div>


    </div>
</div>

<div class="d-none d-sm-none d-lg-block d-md-none d-xl-block mt-1">
    <app-navbar></app-navbar>
</div>


<ng-template #siteCreatePopUp
             let-c="close"
             let-d="dismiss"
             class="add-user-modal">
    <div class="modal-header">
        <h4 class="modal-title"
            id="modal-basic-title">New Site Registration</h4>
    </div>
    <div class="modal-body">
        <ngx-spinner name="sitecreateSpinner"
                     [fullScreen]="false"
                     size="default"
                     color="#3dcd58"
                     type="ball-clip-rotate"></ngx-spinner>
        <site-registration></site-registration>
    </div>
</ng-template>