/**
 * @author Anuj Grover
 * @email anuj.grover@non.se.com |  SESA625719
 * @create date 2021-07-22 17:35:55
 * @modify date 2021-07-22 17:35:55
 * @desc [description]
 */

import { Component, TemplateRef, ViewChild, isDevMode } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { LoginRequest } from 'src/app/models/login'
import { Observable, Subscription } from 'rxjs'
import { ErrorDialogComponent } from 'src/app/modules/shared/dialogueboxes/error-dialog/error-dialog.component'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { tokenMailExpiredDialogComponent } from 'src/app/modules/shared/dialogueboxes/error-dialog/tokenAndMailExpiredModal'
import { environment } from 'src/environments/environment'
import { ResendEMailVerificationWithUsernameDialogComponent } from 'src/app/modules/shared/dialogueboxes/error-dialog/resendEMailVerificationWithUsername'
import { InfoDialogComponent } from 'src/app/modules/shared/dialogueboxes/info-dialog/info-dialog.component'
import { UpdatedForgetDialogComponent } from 'src/app/modules/shared/dialogueboxes/error-dialog/updatedForgetPassword'
import { Constants } from 'src/app/shared/constants'
import { CanComponentDeactivate } from '../../my-dashboard/dashboard/can-deactivate-guard.service'
import { UserService } from 'src/app/services/user.service'


const passwordpattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%&^*]).{8,}$/

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements CanComponentDeactivate {
  environmentName = ''
  environmentUrl = ''
  resetPasswordForm= new FormGroup({
    newPassword:new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).+$/)])),
    confirmPassword:new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),  
  },confirmPasswordValidator)
  @ViewChild('resetPassword') resetPassword: TemplateRef<any> | undefined;
  passwordChangeModelRef = undefined;
  resetpasswordPasswordVisible: boolean = false;
  resetpasswordConfirmPasswordVisible: boolean = false;
  constructor (
    private authservice: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private userService: UserService,
    private fb:FormBuilder,
  ) { 
    this.environmentName = environment.environmentName
    this.environmentUrl = environment.baseUrl
    if (isDevMode()) {
    } else {
    }
    this.detectScreenSize()
  }


  toggleResetPassword(){
    this.resetpasswordPasswordVisible = !this.resetpasswordPasswordVisible;
  }
  toggleResetConfirmpassword(){
    this.resetpasswordConfirmPasswordVisible = !this.resetpasswordConfirmPasswordVisible;
  }


  isValidTabCss: boolean = false

  openDemoApp () {
    this.loggingState = false
    this.loginRequestSubscription = this.authservice
      .handleDemoLogin()
      .subscribe(
        (data: any) => {
          localStorage.setItem('dainw', data)
          // var win = window.open("http://localhost:8086/#/login", "_blank", data);
          // if (win) {
          //   //Browser has allowed it to be opened
          //   win.focus()
          // } else {
          //   //Browser has blocked it
          //   alert('Please allow popups for this website')
          // }
          // console.log("data.msg ts:----------", data.hasOwnProperty("msg"));
          // console.log("data from login ts:----------", data.msg);
          let m = data.msg
          // const m = data.msg;
          // console.log("data from login ts:----------", m.access_token);
          if (data && m.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // console.log('from AuthenticationService currentUser saved --- ' + data);
            if (data['status'] == 'Success') {
              this.authservice.setLoggedUsername(m.loggedInUsername)
              this.authservice.saveToken(m)
            } else if (data['status'] == 'Failure') {
              this.authservice.setModalMessage(data['msg'])
              this.modalService.open(ErrorDialogComponent, {
                windowClass: 'global-custom-class'
              })
            }
          }
          //this.reloadPage();
        },
        err => {
          this.loggingState = false
          // throw error;
          //Backend returns unsuccessful response codes such as 404, 500 etc.
          // console.log('--------------' + JSON.stringify(err, null, 4));
          this.errorObject = err.error
          this.erooroMessageForBadCredentials = this.errorObject.msg
          // console.log('login error-----------------------------------' + this.erooroMessageForBadCredentials);
          // console.log("data from login ts:----------", m.access_token);
          if (
            this.erooroMessageForBadCredentials ==
            'Your email could not be verified and token expired.'
          ) {
            this.authservice.setModalMessage(
              this.erooroMessageForBadCredentials
            )
            this.modalService.open(tokenMailExpiredDialogComponent, {
              windowClass: 'global-custom-class'
            })
            // console.log('login error-----------------------------------');
          } else if (
            this.erooroMessageForBadCredentials == 'Invalid Credentials'
          ) {
            // console.log('wrong credential message -----------------' + this.erooroMessageForBadCredentials);
            this.authservice.setModalMessage(
              this.erooroMessageForBadCredentials
            )
            this.modalService.open(ErrorDialogComponent, {
              windowClass: 'global-custom-class'
            })
          } else if (
            this.erooroMessageForBadCredentials ==
            'Sorry, your email could not be verified, please verify.'
          ) {
            // console.log('wrong credential message -----------------' + this.erooroMessageForBadCredentials);
            this.authservice.setModalMessage(
              this.erooroMessageForBadCredentials
            )
            this.modalService.open(ErrorDialogComponent, {
              windowClass: 'global-custom-class'
            })
          }
        }
      )
  }

  @ViewChild('f', { static: true }) loginForm: NgForm

  private loginInfo: LoginRequest
  loginRequestSubscription: Subscription

  isCheckedTC = false // this is to validate checkbox TC
  isSubmitClicked = false // this is also for validate checkbox TC
  isUC = false
  isLC = false
  isNumeric = false
  isSpclChar = false
  isMinLength = false
  validPassword = false
  isNotAllowedChar: boolean = false
  isMinLengthFail: boolean = false
  isDirty: boolean = false

  incorrectEmailId: boolean = true
  public showEyeIcon = false
  validateEmailId (email) {
    let emaiId = email.target.value
    if (!emaiId) return false
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,5}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/
    this.incorrectEmailId = re.test(emaiId)
  }

  validatePassWordForSpclChar (event: any) {
    const notallowedChar = /(?=.*?[-().'|<>?=;:+,])/
    const inputChar = event
    // console.log('value-------------' + event);
    // console.log('value-------------' + event.length);

    if (inputChar.length >= 8) {
      this.isMinLengthFail = true
    } else {
      this.isMinLengthFail = false
    }
    if (notallowedChar.test(inputChar)) {
      // console.log('isNotAllowedChar' + this.isNotAllowedChar);
      this.isNotAllowedChar = true
    } else {
      this.isNotAllowedChar = false
    }
    this.validatePassWord(inputChar)
  }

  goToSchneiderWebPortalForDataPrivacyAndCookiePolicy () {
    window.open(
      'https://www.se.com/ww/en/about-us/legal/data-privacy.jsp',
      '_blank'
    )
  }

  validatePassWord (event: any) {
    const inputChar = event
    this.validPassword = false
    this.isLC = false
    this.isUC = false
    this.isNumeric = false
    this.isSpclChar = false
    this.isMinLength = false
    const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%&^*]).{8,}$/
    const upperCase = /(?=.*?[A-Z])/
    const lowerCase = /(?=.*?[a-z])/
    const numeric = /(?=.*?[0-9])/
    const spclChar = /(?=.*?[!@#$%&^*])/
    const notallowedChar = /(?=.*?[-().'|<>?=;:+,])/

    this.isNotAllowedChar = notallowedChar.test(inputChar)

    this.validPassword = pattern.test(inputChar)
    if (!this.validPassword) {
      this.isLC = lowerCase.test(inputChar)
      this.isUC = upperCase.test(inputChar)
      this.isNumeric = numeric.test(inputChar)
      this.isSpclChar = spclChar.test(inputChar)
      if (inputChar.length >= 8) {
        this.isMinLengthFail = true
      } else {
        this.isMinLengthFail = false
      }
    }
  }

  validatePasswordPattern(password){
    this.isUC = false
    this.isNumeric = false
    this.isSpclChar = false
    this.isMinLength = false
    const upperCase = /(?=.*?[A-Z])/
    const numeric = /(?=.*?[0-9])/
    const spclChar = /(?=.*?[!@#$%&^*])/
    this.isUC = upperCase.test(password)
    this.isNumeric = numeric.test(password)
    this.isSpclChar = spclChar.test(password)
    if (password.length >= 8) {
      this.isMinLengthFail = true
    } else {
      this.isMinLengthFail = false
    }
  }

  public loginData = { username: '', password: '' }
  typePassword = false
  password: string
  username: string
  loggedUsername: string
  loggedUsersname: string
  loggedInUser: any
  erroMsg: any
  private erooroMessageForBadCredentials
  string
  errorObject: any

  togglePassword = function () {
    this.typePassword = !this.typePassword
  }

  /**
   * Determines whether key press on
   */
  onKeyPress () {
    this.showEyeIcon = true
  }

  detectIOS () {
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

    var isAppleDevice = navigator.userAgent.includes('Macintosh')

    var isTouchScreen = navigator.maxTouchPoints >= 1 // true for iOS 13 (and hopefully beyond)

    return isIOS || (isAppleDevice && (isTouchScreen || this.iOS1to12quirk()))
  }

  isIOSDevice = false

  iOS1to12quirk () {
    var audio = new Audio() // temporary Audio object

    audio.volume = 0.5 // has no effect on iOS <= 12

    return audio.volume === 1
  }

  isMobileView: boolean = false
  isTab: boolean = false
  private detectScreenSize () {
    if (window.innerWidth < 1090 && window.innerWidth > 1000) {
      // this.tabScreenOrientation = 'landscape';
      this.isMobileView = false
      this.isTab = true
    } else if (window.innerWidth < 1000 && window.innerWidth > 750) {
      // this.tabScreenOrientation = 'Portrait';
      this.isMobileView = false
      this.isTab = true
    } else if (window.innerWidth < 750) {
      this.isMobileView = true
      this.isTab = false
    } else {
      this.isTab = false
      this.isMobileView = false
    }

    if (!this.detectIOS() && this.isTab) {
      this.isValidTabCss = true
    } else {
      this.isValidTabCss = false
    }
  }
  // onSubmit() {
  //   this.loginData.username = this.loginForm.value.username;
  //   this.loginData.password = this.loginForm.value.password;
  //   // if (this.authservice.checkCredentials()) { // check if login url is hit even though user is already logged in
  //   //   this.router.navigate(['/home']);
  //   //   return;
  //   // }
  //   //this.authservice.obtainAccessToken(this.loginData);
  //   this.authservice.handleLogin(this.loginData);
  // }

  public loggingState: boolean = false
  onSubmit () {
    this.loggingState = true
    this.loginInfo = new LoginRequest(
      this.loginForm.value.username,
      this.loginForm.value.password
    )
    let credential = this.authservice.getUserCredential(this.loginInfo)
    // console.log('credential -- ' + JSON.stringify(credential));
    // console.log('before service call -- ' + JSON.stringify(this.loginInfo));
    this.loggedUsername = this.loginForm.value.username
    this.authservice.setLoggedUsername(this.loggedUsername)
    this.loginRequestSubscription = this.authservice
      .handleLogin(credential)
      .subscribe(
        (data: any) => {
         let ngbModalOptions: NgbModalOptions = {
          backdrop : 'static',
          keyboard : false,
          centered: true
    };
    
    if(!passwordpattern.test(this.loginInfo.password)){
      this.passwordChangeModelRef=   this.modalService.open(this.resetPassword,ngbModalOptions)
      this.validatePasswordPattern('')
    }
    
          // console.log("data from login ts:----------", (data));
          // console.log("data.msg ts:----------", data.hasOwnProperty("msg"));
          // console.log("data from login ts:----------", data.msg);
          let m = data.msg
          // const m = data.msg;
          // console.log("data from login ts:----------", m.access_token);
          if (data && m.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // console.log('from AuthenticationService currentUser saved --- ' + data);
            if (data['status'] == 'Success') {
              this.authservice.setLoggedUsername(this.loggedUsername)
              this.authservice.saveToken(m)
            } else if (data['status'] == 'Failure') {
              this.authservice.setModalMessage(data['msg'])
              this.modalService.open(ErrorDialogComponent, {
                windowClass: 'global-custom-class'
              })
              // this.alertService.error(data['msg']);
            }
          }
          //this.reloadPage();
        },
        err => {
          this.loggingState = false
          // throw error;
          //Backend returns unsuccessful response codes such as 404, 500 etc.
          this.errorObject = err.error
          this.erooroMessageForBadCredentials = this.errorObject.msg
          // console.log('login error-----------------------------------' + this.erooroMessageForBadCredentials);
          // console.log("data from login ts:----------", m.access_token);
          // if (this.erooroMessageForBadCredentials == "Sorry, your email could not be verified, please verify.") {
          //   this.modalService.open(ResendEMailVerificationWithUsernameDialogComponent, { windowClass: 'global-custom-class' });
          // }
          // if (this.erooroMessageForBadCredentials == "Your email could not be verified and token expired.") {
          //   this.authservice.setModalMessage(this.erooroMessageForBadCredentials);
          //   this.modalService.open(tokenMailExpiredDialogComponent, { windowClass: 'global-custom-class' })
          // }

          if (
            this.erooroMessageForBadCredentials ==
            'Your email is not verified. Please check your mailbox.'
          ) {
            this.authservice.setModalMessage(
              this.erooroMessageForBadCredentials
            )
            this.modalService.open(UpdatedForgetDialogComponent, {
              windowClass: 'global-custom-class'
            })
          }
          if (
            this.erooroMessageForBadCredentials ==
            'Your email could not be verified. Verification link expired.'
          ) {
            this.authservice.setModalMessage(
              this.erooroMessageForBadCredentials
            )
            this.modalService.open(UpdatedForgetDialogComponent, {
              windowClass: 'global-custom-class'
            })
            // console.log('login error-----------------------------------');
          } else if (
            this.erooroMessageForBadCredentials == 'Invalid Credentials'
          ) {
            // console.log('wrong credential message -----------------' + this.erooroMessageForBadCredentials);
            this.authservice.setModalMessage(
              this.erooroMessageForBadCredentials
            )
            this.modalService.open(ErrorDialogComponent, {
              windowClass: 'global-custom-class'
            })
          } else if (
            this.erooroMessageForBadCredentials ==
            'Sorry, your email could not be verified, please verify.'
          ) {
            // console.log('wrong credential message -----------------' + this.erooroMessageForBadCredentials);
            const gtS = this.authservice.getResend()
            if (gtS == 'initial') {
              this.authservice.setResend('set')
              this.authservice.setModalMessage(
                this.erooroMessageForBadCredentials
              )
              this.modalService.open(
                ResendEMailVerificationWithUsernameDialogComponent,
                { windowClass: 'global-custom-class' }
              )
            } else {
              this.authservice.setModalMessage(
                'Verification e-mail is already sent, please check your mailbox'
              )
              this.modalService.open(InfoDialogComponent, {
                windowClass: 'global-custom-class'
              })
            }
          }
        }
      )
  }

  confirmResetPassword(){

    
  if(this.resetPasswordForm.valid){
    this.authservice.updateUserPwd(this.loginInfo.password, this.resetPasswordForm.get('newPassword').value)
    .subscribe((resp) => {
      if(resp['status']==='Success'){
        this.passwordChangeModelRef?.close();
      }
      
    })
    }
  }

  redirect (u: string) {
    this.router.navigate(['./' + u + ''])
  }

  goToAppleStore () {
    window.open(Constants.APPLE_PLAYSTORE_APPLICATION_LINK, '_blank')
  }

  goToGoogleStore () {
    window.open(Constants.GOOGLE_PLAYSTORE_APPLICATION_LINK, '_blank')
  }

  canDeactivate (): Observable<boolean> | Promise<boolean> | boolean {
    /*
    TODO: Add logic to check if back button is pressed and taking it to login 
    */
    // this.onSubmit();
    if (this.authservice.checkCredentials()) return true
    return false
  }
}

export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  return control.get('newPassword').value === control.get('confirmPassword').value
    ? null
    : { PasswordNoMatch: true };
};
