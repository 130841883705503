import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { delay, map, retry } from 'rxjs/operators';
import { Constants } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {

  coordinates: any;
  first: any;
  second: any;
  mapboxURL: String = Constants.mapboxUrl;
  accessToken: String = Constants.mapboxAccessToken;

  getCurrentLocation(lat, lang): Observable<any> {
    this.first = lat.toFixed(4);
    this.second = lang.toFixed(4);
    const url = this.mapboxURL + this.first + "," + this.second + this.accessToken;
    return this.http.get(url);
  }


  constructor(private http: HttpClient) { }

  public getPosition(): Observable<any> {
    return Observable.create(
      (observer) => {
        navigator.geolocation.getCurrentPosition((pos: any) => {
          observer.next(pos);
        }),
          // tslint:disable-next-line: no-unused-expression
          () => {
          },
        // tslint:disable-next-line: no-unused-expression
        {
          enableHighAccuracy: true
        };
      });
  }

  getCurrentLocationAzureLatLonDetails(lat, lang): Observable<any> {
    const url = 'https://atlas.microsoft.com/search/address/reverse/json?subscription-key=' + Constants.AZURE_MAPS_KEY + '&api-version=1.0&language=en-US&query=' + lat + ',' + lang
    return this.http.get(url).pipe(map((data: any) => {
      let addressesDetail: any = data.addresses
      let fullRequiredDetails: any = {}
      if (addressesDetail != null) {
        if (addressesDetail.address != null || addressesDetail.address != "" || addressesDetail.address != undefined) {
          const adrressDetail: any = addressesDetail[0].address
          const latlon: any = addressesDetail[0].position
          fullRequiredDetails = {
            "street": adrressDetail.freeformAddress,
            // "zip": adrressDetail.postalCode,
            "zip": this.getFormattedAddress('zip', adrressDetail),
            "lat": latlon.lat,
            "countryCode": adrressDetail.countryCode,
            "lon": latlon.lon,
            "state": adrressDetail.countrySubdivision,
            // "city": (adrressDetail.countrySecondarySubdivision != undefined || adrressDetail.countrySecondarySubdivision != null || adrressDetail.countrySecondarySubdivision != "") ? adrressDetail.countrySecondarySubdivision : adrressDetail.municipality,
            "country": adrressDetail.country,
            "city": this.getFormattedAddress('city', adrressDetail)
          }
        }
      }
      return fullRequiredDetails;
    }))
    // return this.http.get('https://atlas.microsoft.com/search/address/reverse/json?subscription-key=' + Constants.AZURE_MAPS_KEY + '&api-version=1.0&query=' + lat, lang);
  }

  getCurrentLocationAzureSearch(searchQuery): Observable<any> {
    if (searchQuery == null || searchQuery == "") {
      return of([])
    }
    // const urFuzzy = 'https://atlas.microsoft.com/search/address/json?subscription-key=' + Constants.AZURE_MAPS_KEY + '&limit=5&api-version=1.0&query=' + searchQuery + '&entityType=Municipality'
    // const urFuzzy = 'https://atlas.microsoft.com/search/address/json?subscription-key=' + Constants.AZURE_MAPS_KEY + '&typeahead=true&api-version=1.0&query=' + searchQuery
    const url = 'https://atlas.microsoft.com/search/fuzzy/json?typeahead=true&view=Auto&language=en-US&subscription-key=' + Constants.AZURE_MAPS_KEY + '&api-version=1.0&query=' + searchQuery
    // let urFuzzy = 'https://atlas.microsoft.com/search/fuzzy/json?subscription-key=' + Constants.AZURE_MAPS_KEY + '&api-version=1.0&query=' + searchQuery
    // // urFuzzy += '&minFuzzyLevel=1'
    // urFuzzy += '&minFuzzyLevel=2'
    // // urFuzzy += '&maxFuzzyLevel=3'
    // urFuzzy += '&maxFuzzyLevel=4'
    // const url = 'https://atlas.microsoft.com/search/address/structured/json?subscription-key=' + Constants.AZURE_MAPS_KEY + '&api-version=1.0&query=' + searchQuery
    return this.http.get(url).pipe(map((data: any) => {
      const results: any[] = data.results
      let actualResults: any = []
      if (results.length > 0) {
        results.forEach(element => {
          if (element.address != null || element.address != "" || element.address != undefined) {
            const adrressDetail: any = element.address
            const latlon: any = element.position
            let streetAddress: string = null

            let fullRequiredDetails = {
              "street": this.getFormattedAddress('street', element),
              "zip": this.getFormattedAddress('zip', adrressDetail),
              "lat": latlon.lat,
              "countryCode": adrressDetail.countryCode,
              "lon": latlon.lon,
              "state": adrressDetail.countrySubdivision,
              "city": this.getFormattedAddress('city', adrressDetail),
              "country": adrressDetail.country
              // "country": this.getFormattedAddress('country', adrressDetail)
            }

            if (fullRequiredDetails.city == undefined || fullRequiredDetails.city == "" || fullRequiredDetails.city == null) {
              this.getCurrentLocationAzureLatLonDetails(latlon.lat, latlon.lon).subscribe(responseData => {
                fullRequiredDetails.city = responseData.city
              })
            }
            actualResults.push(fullRequiredDetails)
          }
        });
      }
      actualResults = actualResults.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.street === thing.street && t.country === thing.country
        ))
      )
      return actualResults;
    }))
  }

  getCurrentLocationAzureSearchPostalCode(data): Observable<any> {
    if (data == null || data == "") {
      return of([])
    }
    // const url = 'https://atlas.microsoft.com/search/address/json?subscription-key=' + Constants.AZURE_MAPS_KEY + '&limit=5&api-version=1.0&query=' + searchQuery + '&entityType=Municipality'
    // const url = 'https://atlas.microsoft.com/search/address/json?subscription-key=' + Constants.AZURE_MAPS_KEY + '&limit=5&api-version=1.0&query=' + searchQuery
    const url = 'https://atlas.microsoft.com/search/address/structured/json?subscription-key=' + Constants.AZURE_MAPS_KEY + '&streetName=' + data.street + '&api-version=1.0&countryCode=' + data.countryCode
    return this.http.get(url).pipe(map((data: any) => {
    }))
  }

  getFormattedAddress(context, adrressDetail) {
    let result: string = ''
    switch (context) {
      case 'zip':
        if (adrressDetail.postalCode != undefined && adrressDetail.postalCode != null) {
          if (adrressDetail.postalCode.includes(",")) {
            result = adrressDetail.postalCode.split(",")[0]
          } else {
            result = adrressDetail.postalCode
          }
        }
        if (adrressDetail.extendedPostalCode != undefined && adrressDetail.extendedPostalCode != null) {
          if (adrressDetail.extendedPostalCode.includes(",")) {
            result = adrressDetail.extendedPostalCode.split(",")[0]
          } else {
            result = adrressDetail.extendedPostalCode
          }
        }
        break;
      case 'city':
        if (adrressDetail.countrySecondarySubdivision != undefined || adrressDetail.countrySecondarySubdivision != null || adrressDetail.countrySecondarySubdivision != "") {
          result = adrressDetail.countrySecondarySubdivision
        }
        if (result == '' || result == undefined) {
          result = adrressDetail.municipality
        }
        if (result == '' || result == undefined) {
          result = adrressDetail.countrySubdivision
        }
        break;
      case 'street':
        const adrressDetail1: any = adrressDetail.address
        if (adrressDetail.poi && adrressDetail.poi.name) {
          result = adrressDetail.poi.name + ', ' + adrressDetail1.freeformAddress;
        } else {
          result = adrressDetail1.freeformAddress;
        }


        // if (adrressDetail.country != undefined || adrressDetail.country != null || adrressDetail.country != "") {
        //   result = adrressDetail.country
        // }
        // if (result == '' || result == undefined) {
        //   result = adrressDetail.municipality
        // }
        // if (result == '' || result == undefined) {
        //   result = adrressDetail.countrySubdivision
        // }
        break;
    }
    return result
  }

}