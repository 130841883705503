import { DeviceList } from './device-list';

export class SiteDevicesList {
    deviceHeader: string;
    deviceList: DeviceList[];
    isCollapsable: boolean;
    name: string;
    checked: boolean;

    constructor(deviceHeader: string, deviceList: DeviceList[], isCollapsable: boolean, name?: string, checked?: boolean) {
        this.name = name
        this.deviceHeader = deviceHeader;
        this.deviceList = deviceList;
        this.isCollapsable = isCollapsable;
        this.checked = checked;
    }
}