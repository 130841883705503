export class FirmwareUpgradeRequest {
    siteName: string;
    devicesList: FirmwareDeviceDetails[];
    deviceUsername: string;
    devicePassword: string;
    sessionId: string;
    dbosAuthToken: string;
    ipAddr: string;

    constructor(siteName: string, devicesList: FirmwareDeviceDetails[], deviceUsername: string, devicePassword: string, sessionId: string, dbosAuthToken: string, ipAddr:string){
        this.siteName = siteName;
        this.devicesList = devicesList;
        this.deviceUsername = deviceUsername;
        this.devicePassword = devicePassword;
        this.sessionId = sessionId;
        this.dbosAuthToken = dbosAuthToken;
        this.ipAddr = ipAddr;
    }
}

export class FirmwareDeviceDetails {
    deviceName: string;
    instanceId: string;
    blobLink: string;
    blobFileName: string;
    
    constructor(deviceName: string, instanceId: string, blobLink: string, blobFileName: string){
        this.deviceName = deviceName;
        this.instanceId = instanceId;
        this.blobLink = blobLink;
        this.blobFileName = blobFileName;
    }
}

export class SelectedFirmwareDevices {
    deviceName: string;
    status: string;
    percent: number;
    type: string;
    
    constructor(deviceName: string, status: string, percent: number, type: string){
        this.deviceName = deviceName;
        this.status = status;
        this.percent = percent;
        this.type = type;
    }
}