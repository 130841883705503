import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanDeactivateGuard } from "../my-dashboard/dashboard/can-deactivate-guard.service";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { LoginComponent } from "./login/login.component";
import { NewPasswordComponent } from "./new-password/new-password.component";
import { SignupComponent } from "./signup/signup.component";
import { ValidateSignUpComponent } from "./validate-sign-up/validate-sign-up.component";

const routes: Routes = [
    { path: 'login', component: LoginComponent, data: { toolbar: false } },
    { path: 'signup', component: SignupComponent, data: { toolbar: false } },
    { path: 'forgotpassword', component: ForgotPasswordComponent, data: { toolbar: false } },
    { path: 'reset', component: NewPasswordComponent },
    { path: 'validateSignup', component: ValidateSignUpComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginSignupRoutingModule { }