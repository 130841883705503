/**
 * @author sesa 534419 - Ashutosh Singh
 * @email ashutosh.singh1@non.se.com
 * @create date 2019-04-27 17:38:36
 * @modify date 2019-04-29 02:32:36
 * @desc This service handles user related tasks, e.g register, get users, check users etc.
 */
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { User } from "src/app/models/user";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { API_URLS } from "../shared/api_urls";

export class GetAllUser {
  siteName: string;
}

@Injectable()
export class UserService {
  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  private emitChangeSource = new Subject<any>();
  private emituserNameChangeEmittedChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  userNameChangeEmitted$ = this.emituserNameChangeEmittedChangeSource.asObservable();


  private profileImageData: string = '';
  private userDatafromProfileImageData: any = {};
  get profileImage(): string {
    return this.profileImageData;
  }
  set profileImage(val: string) {
    this.profileImageData = val;
  }

  get userDataProfileImage(): any {
    return this.userDatafromProfileImageData;
  }
  set userDataProfileImage(val: any) {
    this.userDatafromProfileImageData = val;
  }

  emitChange(eventName) {
    this.emitChangeSource.next(eventName);
  }

  emitUserNameChange(eventName) {
    this.emituserNameChangeEmittedChangeSource.next(eventName);
  }

  register(user: User) {
    return this.http.post("user/register", user);
  }

  update(user: User) { }

  delete(id: number) { }

  getById(id: number) { }

  addUserRight(userRole): Observable<any> {
    return this.http.post<any>("assignUser", JSON.stringify(userRole)).pipe(
      map((data) => {
        return data;
      })
    );
  }

  deleteUserRight(userObj): Observable<any> {
    return this.http.post<any>("delete", userObj).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getAllUserList(siteName: string, userName: string): Observable<any> {
    let obj = {
      siteName: siteName,
      loginUser: userName,
    };
    return this.http.post<any>("listOfuser", obj).pipe(
      map((data) => {
        return data;
      })
    );
  }

  submitFeedbackForm(upload: any, uploadFileUrl: any): Observable<any> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.set('Content-Type', undefined);
    headers.append('Accept', 'application/json');
    formData.append('feedback', new Blob([JSON.stringify(upload)], { type: 'application/json' }));
    if (upload.isImageFile == "image") {
      formData.append("uploadedFile", uploadFileUrl)
      // formData.append("uploadedFile", new Blob([JSON.stringify(uploadFileUrl)
      // ], {
      //   type: "application/json"
      // }))
    } else {
      formData.append("uploadedFile", uploadFileUrl)
    }
    const fileUploadURL = 'feedback';
    return this.http.post<any>(fileUploadURL, formData, { headers: headers });
  }

  uploadProfileImage(imageFile: any, userInfo: any): Observable<any> {

    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.set('Content-Type', undefined);
    headers.append('Accept', 'application/json');
    formData.append('user', new Blob([JSON.stringify(userInfo)], { type: 'application/json' }));
    formData.append("profilePic", imageFile)
    const imageUploadURL = 'app/user';
    return this.http.put<any>(imageUploadURL, formData, { headers: headers });
  }


  getProfileImageData(siteName: string): Observable<any> {
    return this.http.get<any>(`${API_URLS.PROFILE_IMAGE_DETAILS_GET}${siteName}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  deleteAccount(): Observable<any> {
    return this.http.delete(`${API_URLS.DELETE_ACCOUNT_API}`).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
