import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { ErrorDialogComponent } from "../modules/shared/dialogueboxes/error-dialog/error-dialog.component";
import { Constants } from "../shared/constants";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router,
        private modalService: NgbModal
    ) { }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.isAuthenticated().then((authenticated: boolean) => {
            if (authenticated) {
                const ciUserType = this.authService.getCIUserType()
                const ciUserRole = this.authService.getUserRole()
                const CI1_UNAUTHORISED_URLS = ["/reporting/reports",
                    "/reporting/reportManager",
                    "/performance/alarmsAndWarningComparison",
                    "/performance/benefits",
                    "/performance/deviceComparison",
                    "/performance/siteAdvancedAnalysis",
                    "/site/comparison",
                    "/configuration/deviceSettings",
                    "/configuration/systemSettings",
                    "/configuration/firmware"];
                const CI1_UNAUTHORISED_URLS_BASIC_END_USER_AND_TECHNICAL_END_USER = [
                    "/reporting/reports",
                    "/reporting/reportManager",
                    "/performance/alarmsAndWarningComparison",
                    "/performance/benefits",
                    "/performance/deviceComparison",
                    "/performance/siteAdvancedAnalysis",
                    "/site/overview",
                    "/site/comparison",
                    "/configuration/deviceSettings",
                    "/configuration/systemSettings",
                    "/configuration/userRights",
                    "/configuration/emailNotifications",
                    "/configuration/financialSettings",
                    "/configuration/firmware"];
                if (ciUserType == Constants.INSIGHT_CLOUD_USER_TYPE.CI1 && CI1_UNAUTHORISED_URLS.some(url => url === state.url) && (ciUserRole == 'Solar installer' || ciUserRole == 'Site administrator')) {
                    let hasOpenedModal = this.modalService.hasOpenModals();
                    if (!hasOpenedModal) {
                        this.authService.setModalMessage("Un-authorised access, Not allowed");
                        this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
                        this.router.navigate(['dashboard']);
                    }
                    return false
                }
                if (ciUserType == Constants.INSIGHT_CLOUD_USER_TYPE.CI1 && CI1_UNAUTHORISED_URLS_BASIC_END_USER_AND_TECHNICAL_END_USER.some(url => url === state.url) && (ciUserRole == 'Basic end user' || ciUserRole == 'Technical end user')) {
                    let hasOpenedModal = this.modalService.hasOpenModals();
                    if (!hasOpenedModal) {
                        this.authService.setModalMessage("Un-authorised access, Not allowed");
                        this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
                        this.router.navigate(['dashboard']);
                    }
                    return false
                }
                if(this.router.url === "/configuration/firmware" && this.authService.doGetFirmwareUpgradeStatus() == "upgrade"){
                    return false
                }
                return true;
            } else {
                this.router.navigate(['login']);
                return false;
            }
        })
    }
}