import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { ImagePath } from 'src/app/shared/image-path';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { PerformanceService } from 'src/app/services/performance.service';
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  navbarItems: any
  eventRefreshSubscriptionSubscription: Subscription
  userTypeSubscription: Subscription
  superadminRoute: any = { itemName: 'SUPERADMIN SETTINGS', routerLink: 'configuration/admin/settings' }
  context: string = null

  constructor(private route: ActivatedRoute, private router: Router, private elem: ElementRef, private _authService: AuthService,
    private performanceService: PerformanceService, private actRoute: ActivatedRoute) {

    setTimeout(() => {
      let cond = this._authService.getTotalRegisteredSites();
      if (cond !== 'error') {
        this.formatNavMenuBasedOnUserType()
      }
    }, 300);




    this.eventRefreshSubscriptionSubscription = this.performanceService.siteListChangeEmitted$.subscribe(siteListCount => {
      // This is event based API call
      if (siteListCount > 0) {
        this.getUserType()
      }
    });

    this.eventRefreshSubscriptionSubscription = this.performanceService.changeEmitted$.subscribe(event => {
      // This is event based API call
      if (event === Constants.WIDGET_REFRESH_EVENT_NAME.EVENT_SITE) {
        this.getUserType()
      }
    });

    this.eventRefreshSubscriptionSubscription = this.performanceService.siteDeletedEmitted$.subscribe(event => {
      // This is event based API call
      if (event === Constants.WIDGET_REFRESH_EVENT_NAME.DELETE_SITE) {
        this.getUserType()
      }
    });


    // this._authService.conextInsightUserType.subscribe(data => {
    //   let userType: string = data;
    //   console.log('Usehjjanged-----------------------------' + userType);
    //   let userTypeRole: string = this._authService.getUserRole();
    // })
  }

  getUserType() {
    if (this.getAlarmSubscriptionStatus) {
      return false;
    } else {
      this.getAlarmSubscriptionStatus = true;
      this.userTypeSubscription = this._authService.getConextInsightUserType(this._authService.getSelectedSite()).subscribe(data => {
        if (data != null) {
          this.getAlarmSubscriptionStatus = false;
          let userType = data.list;
          this._authService.updateConextInsightUserType(userType);
          setTimeout(() => {
            this.formatNavMenuBasedOnUserType()
          }, 700);
        }
      }, err => {
        this.getAlarmSubscriptionStatus = false;
      });
    }
  }

  getAlarmSubscriptionStatus: boolean = false;

  formatNavMenuBasedOnUserType() {
    const userType = this._authService.getCIUserType()
    const userRole = this._authService.getUserRole()
    if (userType == "ci1") {
      this.navbarItems = Constants.CI1_NAVBAR_ITEMS_SITEOVERVIEW
      switch (userRole) {
        case 'Site administrator':
          this.allRoutes = this.comboxRoutesForAll
          break;
        case 'Solar installer':
          this.allRoutes = this.comboxRoutesForAll
          break;
        case 'Technical end user':
          let allRoutesTemp = this.comboxRoutes.slice(0, 5)
          this.allRoutes = allRoutesTemp
          break;
        case 'Basic end user':
          let allRoutesTemp1 = this.comboxRoutes.slice(0, 5)
          this.allRoutes = allRoutesTemp1
          break;
      }
    } else {
      this.allRoutes = this.gatewayRoutes
      this.doUpdateRoleBasedUI()
    }
  }

  doUpdateRoleBasedUI() {
    const userRole = this._authService.getUserRole()
    if (parseInt(this._authService.getTotalRegisteredSites()) > 1) {
      if (userRole == Constants.INSIGHT_CLOUD_USER_ROLE.BASIC_END_USER) {
        this.navbarItems = Constants.CI2_BASIC_END_USER_NAVBAR_ITEMS;
        this.allRoutes = Constants.GATEWAY_ROUTE_FOR_SOLAR_INSTALLER_AND_TECHNICAL_END_USER;
      } else {
        if (userRole == Constants.INSIGHT_CLOUD_USER_ROLE.SITE_ADMININSTRATOR) {
          this.navbarItems = Constants.NAVBAR_ITEMS;
          this.allRoutes = this.gatewayRoutes;
        } else {
          this.navbarItems = Constants.NAVBAR_ITEMS_FOR_SOLAR_INSTALLER_TECHNICAL_END_USER;
          this.allRoutes = Constants.GATEWAY_ROUTE_FOR_SOLAR_INSTALLER_AND_TECHNICAL_END_USER;
        }
      }
    } else {
      // this.navbarItems = Constants.SITE_OVERVIEW_EXCLUDED_NAVBAR_ITEMS;
      if (userRole == Constants.INSIGHT_CLOUD_USER_ROLE.BASIC_END_USER) {
        this.navbarItems = Constants.CI2_BASIC_END_USER_MULTISITE_EXCLUDED_NAVBAR_ITEMS;
        this.allRoutes = Constants.GATEWAY_ROUTE_FOR_SOLAR_INSTALLER_AND_TECHNICAL_END_USER;
      } else {
        // this.allRoutes = this.gatewayRoutes;
        // console.log('cfttfgh==========4==============' + userRole);
        // this.navbarItems = Constants.SITE_OVERVIEW_EXCLUDED_NAVBAR_ITEMS;

        if (userRole == Constants.INSIGHT_CLOUD_USER_ROLE.SITE_ADMININSTRATOR) {
          this.navbarItems = Constants.SITE_OVERVIEW_EXCLUDED_NAVBAR_ITEMS;
          this.allRoutes = Constants.GATEWAY_ROUTE_FOR_SINGLE_SITE;
        } else {
          this.navbarItems = Constants.MULTISITES_EXCLUDED_NAVBAR_ITEMS_FOR_SOLAR_INSTALLER_TECHNICAL_END_USER;
          this.allRoutes = Constants.MULTISITE_EXCLUDED_GATEWAY_ROUTE_FOR_SOLAR_INSTALLER_AND_TECHNICAL_END_USER;
        }
      }
    }

    const xVal = this.navbarItems.find((obj: any) => obj.itemName === 'SUPERADMIN SETTINGS')
    if (this._authService.getLoggedInUsername() === Constants.SUPER_ADMIN && xVal == undefined) {
      this.navbarItems.push(this.superadminRoute)
    }

  }

  isSearchClicked: boolean = false
  isMultisiteAllowed: boolean = false
  faSearchIcon = ImagePath.searchSvgIcon


  ngOnInit(): void {
    this.context = null
    setTimeout(() => {
      this.context = this._authService.doGetFirmwareUpgradeStatus()

      this._authService.forceFirmwareUpgradeStatus.subscribe(data => {
        this.context = data
      })
      // if (this.context === "upgrade") {
      //   this.dropdownvalin = "upgrade"
      //   this.SwapSubTabs("upgrade")
      // } else {
      //   this.getSiteDevicesAndGateway();
      // }
    }, 1000);
  }

  isOpened: boolean = false
  allRoutes: any
  gatewayRoutes = Constants.GATEWAY_ROUTE
  comboxRoutes = Constants.COMBOX_ROUTE
  comboxRoutesForAll = Constants.COMBOX_ROUTE
  searchTerm: string;
  routeSearchResults: any = [];

  cancelAndClose() {
    this.isOpened = !this.isOpened
    this.routeSearchResults.length = 0
    this.searchTerm = ""
  }

  search(event: any): any {
    let valueStr = event.target.value
    if (valueStr == "") {
      this.routeSearchResults.length = 0
    } else {
      this.routeSearchResults = this.allRoutes.filter((val) => val.routeName.toLowerCase().includes(valueStr.toLowerCase()));
    }
  }

  goToRoute(c) {
    this.cancelAndClose()
    this.router.navigate([c.routeUrl]);
  }


  submenuClick(submenuRoute) {
    this.detectScreenSize();
    this.router.navigate([submenuRoute], { relativeTo: this.route });
  }

  @HostListener("window:resize", [])
  private onResize() {
    this.detectScreenSize();
  }

  // ngAfterViewInit() {
  //   this.detectScreenSize();
  // }

  isTab: boolean = false;
  private detectScreenSize() {
    // we will write this logic later
    setTimeout(() => {
      if (window.innerWidth > 991 && window.innerWidth < 1200) {
        this.isTab = true;
      } else {
        this.isTab = false;
      }
    }, 78);
    setTimeout(() => {
      this.isTab = false;
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.userTypeSubscription) this.userTypeSubscription.unsubscribe()
  }

}
