<div class="profileModal">
    <ngx-spinner name="sendFeedbackSpinner" [fullScreen]="false" size="default" color="#3dcd58" type="ball-clip-rotate"
        [zIndex]="999"></ngx-spinner>
    <div class="modal-header mt-2">
        <h4 class="modal-title">Send Feedback</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"> <span
                aria-hidden="true">&times;</span> </button>
    </div>
    <div class="modal-body" *ngIf="successMessageFeedback">
        <div class="successMessageDiv align-items-center d-flex justify-content-center">
            <h4>
                {{successMessageFeedback}}
            </h4>
        </div>
    </div>
    <div class="modal-body" *ngIf="!successMessageFeedback">

        <div class="container feedbackDiv">
            <div class="form-group d-flex justify-content-between">
                <textarea [(ngModel)]="fededbackTextValue"
                    (ngModelChange)="validateFeedbackTextData(fededbackTextValue)" class="form-control popup-user-input"
                    name="userfeedback" rows="10"
                    placeholder="Share product feedback and New ideas here. For product support, please visit www.sesolar.com"
                    style="width: 100%; max-width: 100%;"></textarea>
            </div>
            <div class="d-flex justify-content-between">
                <div class="custom-file">
                    <span class="pr-3 mt-1">{{uploadedFeedbackFile == null || uploadedFeedbackFile == undefined ? 'Add a file':uploadedFeedbackFile | truncateLetters : 30}}</span>
                    <input type="file" class="custom-file-input" id="customFile" #file placeholder="Add a file"
                        (change)="selectFeedBackFile($event)">
                    <label for="file-upload" class="custom-file-upload mt-1" (click)="file.click()">
                        Browse
                    </label>
                    <!-- <p class="ml-1" *ngIf="uploadedFeedbackFile != null || uploadedFeedbackFile != undefined">
                        {{uploadedFeedbackFile | truncateLetters : 30}}
                    </p> -->
                </div>
                <span>
                    <button *ngIf="!successMessageFeedback" class="sendFeedbackBtn" type="button" type="submit"
                        form="profileForm" (click)="submitFeedBack()"
                        [disabled]="!fededbackTextValue || isDocxError || isNotAllowedChar"> Send Feedback</button>
                </span>
                <!-- <span class="fa fa-times removeAttachmentIcon p-2" (click)="removeAttachment()"></span> -->
            </div>
        </div>
        <div>
            <small class='text-danger mt-1' *ngIf='isNotAllowedChar'>
                Invalid character entered, Only !@$%&*()_-+:";',./ and country specific characters are allowed
            </small>
        </div>
        <div *ngIf="isDocxError" class="text-danger d-flex">
            <small>{{uploadImageError}}</small>
        </div>
    </div>
    <!-- <div class="modal-footer">
        <button *ngIf="!successMessageFeedback" class="btn btn-primary feedback-button" type="button" type="submit"
            form="profileForm" (click)="submitFeedBack()"
            [disabled]="!fededbackTextValue || isDocxError || isNotAllowedChar"> Send Feedback</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')"> Cancel </button>
    </div> -->
</div>