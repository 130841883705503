<footer class="footer-container w-100 mobileView"  style="width: 100% !important; height: 92px; margin-top: 12px; background:  #737680; display: flex; justify-content: space-around; align-items: center;"
    [ngClass]="{'footer-container-custom': footerCustomClass}">

    <img src="../../../../assets/app-images/Logotypes.png" style="width: 227px; height: 62px;" class="webImage">

    <a class="footer">Copyright 2024 Schneider Electric - Solar v · {{buildVersion != null ? buildVersion: 1.0.0}}</a>
    <div>
        <a class="footer-feedback">Please provide your feedback<span class='hereLink' (click)="openFeedBackModal()">
                here...</span></a>
            </div>
    <img src="../../../../assets/app-images/Logotypes.png" style="width: 227px; height: 62px;" class="mobileImage">

</footer>