export const API_URLS = {
  WEATHER_LAT_LONG: 'weather-data',

  ALRM_ACTIVE: 'alarm/active/',


  SITE_DELETE: 'sites/delete',
  SITE_ALL_DELETE: 'sites/delete',
  SELECTED_SITE: 'sites/selectedSite',
  SITE_DOWNLOAD: 'sites/download',
  SITE_EXIST: 'sites/siteExist/',
  SITE_OWNERS: 'siteowners/',
  SITE_DELETE_IMAGE: 'sites/deleteimage/',
  SITE_DUPLICATE: 'sites/duplicate',
  SITE_UPDATE: 'sites/update',
  SITE_NEW_CLOUD: 'sites/new-cloud',
  SITE_FILE_UPLOAD: 'sites/documents?documentType',
  SITE_DOCUMENT_LIST: 'sites/documents/list',
  SITE_DOCUMENT_DELTE: 'sites/documents/delete',
  SITE_DOCUMENT_DOWNLOAD: 'sites/documents/download/',
  SITE_REGISTER_DEVICE: 'sites/register-device',
  SITE_VALIDATE_DEVICE: 'sites/validate-device',
  GET_SYSTEM_SETTINGS_AVAILABLE: 'config/systemsettings',

  FINANCIAL: 'financial',
  FINANCIAL_VALUE: 'financial/value',

  DEVICE_TYPE: 'devicetypes',
  DEVICE_TYPE_ci1: 'ci1-devicetypes',
  ENERGYSUMMARY_DEVICE_INFO: 'energysummary/deviceinfo',


  // Environmental Benefits & Earnings
  ENVIRONMENTAL_BENEFITS: 'earnings-benefits',
  EARNINGS_ENVIRONMENTAL_BENEFITS: 'earnings-environmental-benefits',

  // Lifetime energy card in dashboard
  LIFETIME_ENERGY_CARD: 'app/lifetimeenergy',

  // Energy Guage card in dashboard
  ENERGY_GUAGE_CARD: 'cloud/energygauge',


  // Solar Production card in dashboard
  SOLAR_PRODUCTION_CARD_POWER: 'tsi/solar-power',

  SOLAR_PRODUCTION_CARD_ENERGY: 'tsi',

  // Power Gauge in dashboard
  POWERGAUGE: 'powergauge',



  //alarms and warnings
  ALARMS: 'tsi/alarms/',
  SITETABLE_ALARMS: 'sitetable/alarms',


  //Profile URL's
  PROFILE_IMAGE_DETAILS_GET: 'app/user/',
  PROFILE_IMAGE_DETAILS_SET: 'app/user/',

  //Forgot Password
  FORGOT_PASSWORD_CONFIRM: 'finalconfirm',

  // Logout API
  LOGOUT_API: 'logout',

  // Delete Account API
  DELETE_ACCOUNT_API: 'user/delete-account',

  POWERFLOW_API: 'powerflow',

  REAL_PF_CHECK_API: 'real-powerflow-fw-check/',

  ENABLE_LIVE_PF_API: 'enable-real-powerflow',

  DISABLE_LIVE_PF_API: 'disable-real-powerflow',

  ENERGY_COMPARISON_API: 'energy/comparison',

  DEVICE_DETAILS: 'sites/devices',

  ENERGY_SUMMARY_API: 'energysummary',

  MS: 'multisites',

  EVENTS_API: 'tsi/events',

  DEVICE_COMPARISON_API: 'devicecomparison',

  SAVINGS_API: 'savings/evolution',

  ENV_FINANCIAL_API: 'env/financial/savings',

  PAYBACK_TRACK_API: 'payback/track',

  MONTHLY_FINANCIAL_API: 'monthly/financial/performance/tracker',

  ALARMS_ANALYSIS_API: 'alarmanalysis',

  BATT_CHARGE_DISCHARGE_API: 'batt-charge-discharge',

  BATT_PERF_API: 'battperformance',

  POWERMETER_API: 'powermeter-performance',

  GENERATOR_CONN_API: 'generatorConnection',

  ENERGY_SUMMARY_REPORTING_API: 'energysummary-reporting',

  ALARMS_REPORTING_API: 'alarms-reporting',

  MULTISITES_API: 'sites/multisites',

  GET_USER_SCHEDULERS: 'report/all-schedulers',

  ALL_SITES: 'sites/all'

};

const urlsWithSiteName = [API_URLS.SITE_OWNERS,
API_URLS.ALRM_ACTIVE,
API_URLS.ALARMS,
API_URLS.REAL_PF_CHECK_API];

export const search = (baseUrl: string, url: string) => Object.values(API_URLS).find(value => {
  if (urlsWithSiteName.some(u => u === value)) {
    return url.includes(baseUrl + value)
  } else {
    return url.endsWith(baseUrl + value)
  }
});