import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateLetters'
})
export class TruncateLettersPipe implements PipeTransform {
  transform(value: any, size: any): unknown {
    return value.length > size ? value.slice(0, size - 1) + "…" : value;;
  }

}
