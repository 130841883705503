export enum Widgets {
    WIDGETS = "power_production,savings_and_cost,environmental_footprint_by_site"
}

export enum Replace {
    NULL = "null",
    EMPTY = ""
}

export enum ChartViewId {
    PP = "PowerProduction",
    SAC = "SavingsAndCost",
    EF = "EnvironnementalFootprint",
    ER = "Earnings"
}

export enum ChartViewIdList {
    CVL = "PowerProduction_SavingsAndCost_EnvironnementalFootprint"
}

export enum Spiner {
    SPINNER = "Spinner"
}

export enum MultiSiteURL {
    MS = 'multisites',
    PP = 'multisite/powerProduction',
    SC = 'multisite/savingsAndCost',
    EF = 'multisite/environmentalFootprint',
    ER = 'multisite-earning'
}

export enum ExportChartAsConfig {
    TYPE = "pdf",
    EID = "ms-chart",
    PDFO = "landscape"
}

export enum DateType {
    DATE_TYPES = 'Today=DAY,This Week=WEEK,This Month=MONTH,This Year=YEAR'
}

export enum SourceDropDown {
    SOURCE = 'Solar,Battery,Grid,Generator'
}

export enum MSUserMsgs {
    MSG_A = "We couldn't process your request, please try later",
    MSG_B = "Data not found, please ",
    MSG_C = "Selected device is not configured, Retry!",
    MSG_D = "Selected site not available, Relogin!",
    MSG_ADD = "You can add upto ",
    MSG_SITES = " sites",
    MSG_F = "No Sites Selected",
    MSG_G = "You have no sites selected or registered",
    MSG_H = "Settings failed!",
}

export enum ChartDataSet {
    DATA = 'data',
    LABEL = 'label',
    BG = 'backgroundColor',
    HBG = 'hoverBackgroundColor',
    COLOR_SET_PP = "#EC7063,#34495E,#9FA0A4,#5DADE2,#2A3132,#763626,#693D3D,#46211A,#BA5536,#505160,#AEBD38,#598234,#003B46,#07575B,#021C1E,#2C7873,#004445,#021C1E,#FFBB00,#38681C,#99A3A4,#B3B6B7,#F18D9E,#E6D72A,#DE7A22,#5D535E,#68829E,#336B37",
    COLOR_SET_SC_A = "#CF3721,#CF3721,#CF3721,#CF3721,#CF3721,#CF3721,#CF3721,#CF3721,#CF3721,#CF3721,#CF3721",
    COLOR_SET_SC_B = "#6FB98F,#6FB98F,#6FB98F,#6FB98F,#6FB98F,#6FB98F,#6FB98F,#6FB98F,#6FB98F,#6FB98F,#6FB98F",
    SC_LABEL_SET_A = "Solar generation",
    SC_LABEL_SET_B = "Costs (Grid + Generator)",
}

export enum SitePushPop {
    COPY = 'DUPLICATE',
    REMOVE = 'DELETE',
    NEW = 'CREATE'
}

export enum DateCategory {
    DATE = "date",
    DATE_INTERVAL = "HOUR,DAY,MONTH,YEAR"
}