/**
 * @author SESA545946 - Brijesh shakya
 * @email Brijesh.Shakya@non.se.com
 * @create date 2021-10-04 27:12:33
 * @modify date 2021-10-04 21:31:32
 * @desc [This service takes site data as input from component displays in create map component, emit change event
 *  with coordinates whenever search(setLatLong) or refresh(updateSiteList) is performed]
 */

import { Injectable, EventEmitter, NgZone } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

interface SiteList {
  siteName: String;
  alarms: number;
  warnings: number;
  coordinates: number[];
  siteType: string;
  address: string;
  siteSize: number;
  pvCapacity: number;
  inverterSize: number;
}

@Injectable({
  providedIn: 'root'
})

export class CreateMapService {
  public sites: SiteList[] = [];
  public updatedSites: SiteList[];

  dataChange: EventEmitter<number[]> = new EventEmitter();
  mapSiteInfo: EventEmitter<number[]> = new EventEmitter();
  coord: any = {
    'coordinates': <[number, number][]>[
    ]
  };
  private emitChangeSource = new Subject<any>();
  private emitSIteListDetailSource = new Subject<any>();

  constructor(public zone: NgZone, private _authService: AuthService, private http: HttpClient) {

  }

  changeEmitted$ = this.emitChangeSource.asObservable();
  emitChange() {
    this.emitChangeSource.next();
  }

  emitSIteListDetailSourceChangeEmitted$ = this.emitSIteListDetailSource.asObservable();
  emitSIteListDetailSourceChange(data) {
    this.emitSIteListDetailSource.next(data);
  }

  getSiteListDetails(): Observable<any> {
    return this.http.get<any>(`sitemarker`).pipe(map(data => {
      return data;
    }));
  }

  getSiteMarker(site: string): Observable<any> {
    const url = 'site/marker';
    let siteName = {
      "siteName": site
    }
    return this.http.get(url, { params: siteName });
  }

  //search for lat long
  setLatLong(lat: number, long: number) {
    this.coord.coordinates = [];
    this.coord.coordinates.push([lat, long]);
    this.dataChange.emit(this.coord);
  }

  //site info details
  setSiteInfo(siteInfo: any) {
    this.mapSiteInfo.emit(siteInfo);
  }

  //updates old site list with new data
  updateSiteList(siteList: SiteList[]) {
    siteList.forEach(site => {
      this.coord.coordinates.push(site['coordinates']);
    });
    this.sites = siteList;
    this.dataChange.emit(this.coord);
  }

  getAlarmaAndWarningsMarkerColorForMap(alarms, warnings) {
    if (alarms == 0 && warnings == 0) {
      return "#3dcd58"
    } else if (alarms > 0 && warnings == 0) {
      return "#ff0000"
    } else if (alarms == 0 && warnings > 0) {
      return "#F78D05"
    } else if (alarms > 0 && warnings > 0) {
      return "#ff0000"
    }
  }
}
