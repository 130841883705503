/**
 * @author sesa 545946 - Brijesh shakya
 * @email brijesh.shakya@non.se.com
 * @create date 2021-07-27 17:35:01
 * @modify date 2021-08-27 13:25:11
 * @desc This is a test
 */
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable()
export class ResService {
    constructor(private http: HttpClient) { }

    getResource(resourceUrl): Observable<any> {
        return this.http.get(resourceUrl);
    }
}