import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

interface FileDetail {
  name: string;
  type: string;
  lastModifiedDate: string;
  tysizepe: string;
}

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit {
  @Input() name;

  constructor(
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private authService: AuthService,
    private userService: UserService,

  ) { }

  successMessageFeedback: string = '';
  fededbackTextValue: any;
  isNotAllowedChar: boolean = false;
  isDocxError: boolean = false;
  uploadedFeedbackFile: any = null;
  isImageFile: string = null
  fileData: File
  fileDetails: any
  initialFiletype: string = null
  uploadImageError: string;
  image3: any;
  fileName: any = null;
  type: any = null;
  size: any;
  uploadFileUrl: string = null;
  maxSizeError: boolean = false;

  ngOnInit(): void {
    this.successMessageFeedback = ''
  }

  validateFeedbackTextData(data) {
    this.isNotAllowedChar = false
    const notAllowedChar = /[|\<>[\]{}`\\#^=~]/;
    if (notAllowedChar.test(data)) {
      this.isNotAllowedChar = true
    } else {
      this.isNotAllowedChar = false
    }
  }

  selectFeedBackFile(event) {
    this.fileDetails = null
    this.uploadImageError = null
    this.uploadFileUrl = null
    this.fileName = null
    this.fileData = null
    this.isImageFile = null
    const file = event.target.files.item(0);
    if (file == null) {
      return false;
    }
    this.fileDetails = this.getFileDetails(event)
    this.initialFiletype = this.fileDetails.type
    let fileList: FileList = event.target.files;
    let filetype = file.type;
    if (filetype.match('image.*')) {
      var size = event.target.files[0].size;
      if (size > 5120000) {
        let uploadExceedSize = this.formatBytes(size);
        this.isDocxError = true;
        this.uploadImageError = 'Uploaded image size is : ' + uploadExceedSize + '. Maximum allowed is 5 MB'
        return false;
      }

      // Processing image file 
      this.image3 = event.target.files[0];
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url 
        reader.onload = (eventData: any) => { // called once readAsDataURL is completed 
          this.fileData = fileList[0]
          this.isImageFile = "image"
        }
      }
    } else {
      this.validateTheUploadedfile(event)
      if (fileList.length > 0) {
        this.fileData = fileList[0]
      }
      // alert("========================")
    }
  }

  formatBytes(bytes) {
    if (bytes < 1024) return bytes + " Bytes";
    else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KB";
    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + " MB";
    else return (bytes / 1073741824).toFixed(3) + " GB";
  }


  getFileDetails(event): FileDetail {
    for (var i = 0; i < event.target.files.length; i++) {
      var name = event.target.files[i].name;
      var type = event.target.files[i].type;
      var size = event.target.files[i].size;
      var modifiedDate = event.target.files[i].lastModifiedDate;
      let imgDetailObj = {
        name: name,
        type: type,
        lastModifiedDate: modifiedDate,
        tysizepe: size
      }
      this.uploadedFeedbackFile = name
      return imgDetailObj;
    }
  }


  validateTheUploadedfile(event) {
    this.type = event.target.files[0].type;
    this.size = event.target.files[0].size;
    this.fileName = event.target.files[0].name;
    var extension = this.fileName.substr(this.fileName.lastIndexOf('.') + 1).toLowerCase();
    if (extension == 'pdf' || extension == 'doc' || extension == 'docx' || extension == 'csv' || extension == 'xlsx') {
      this.isDocxError = false;
      this.uploadImageError = ""
    } else {
      this.isDocxError = true
      this.uploadImageError = "Only pdf, doc, csv, excel or docx are allowed"
      return false
    }
    if ((this.size >= 5120000)) {
      this.isDocxError = true
      this.uploadImageError = "Max allowed file size is 5 mb"
      return false
    }
  }

  removeAttachment() {
    this.isDocxError = false
    this.uploadImageError = null
    this.uploadedFeedbackFile = null
    this.fileData = null
  }



  submitFeedBack() {
    this.spinner.show('sendFeedbackSpinner');
    let obj: any;
    if (this.fileDetails != null && this.fileDetails != undefined) {
      obj = {
        "feedback": this.fededbackTextValue,
        "isImageFile": this.isImageFile,
        "filename": (this.fileDetails.name == null || this.fileDetails.name == undefined) ? null : this.fileDetails.name,
        "fileType": (this.fileDetails.type == null || this.fileDetails.type == undefined) ? null : this.fileDetails.type
      }
    } else {
      obj = {
        "feedback": this.fededbackTextValue,
        "filename": null,
        "isImageFile": null,
        "fileType": null
      }
    };

    this.userService.submitFeedbackForm(obj, this.fileData).subscribe(
      (data: any) => {
        this.spinner.hide("sendFeedbackSpinner");
        if (data['status'] == 'Success') {
          this.successMessageFeedback = 'Thank you for your feedback';
        } else if (data['status'] == 'Failure') {
          this.uploadImageError = 'Please try later';
        }
      },
      error => {
        this.spinner.hide("sendFeedbackSpinner");
        let errorObj = error.error;
        if (errorObj['msg'].includes("Maximum upload size exceeded")) {
          this.uploadImageError = 'Maximum upload size exceeded, please try smaller sized document';
        }
        else {
          this.uploadImageError = 'Some error occured. Please try again.';
        }
      }
    )

  }
}
