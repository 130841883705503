import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Injector,
  HostListener,
  OnInit
} from '@angular/core'
import atlas, { Map, AuthenticationType } from 'azure-maps-control'
import { Constants } from 'src/app/shared/constants'
import { MapsService } from 'src/app/services/maps.service'
import * as azMaps from 'azure-maps-control'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-map-site-registration-component',
  template: "<div id='createNewSiteRegistrationMap'></div>"
})
export class AzureMapSiteRegistrationComponent {
  @ViewChild('createNewSiteRegistrationMap', { static: false })
  updateSiteMapViewElementRef: any

  //   public mapContainer: ElementRef
  mapZoom: number = 12

  @HostListener('window:resize', [])
  private onResize () {
    this.detectScreenSize()
  }

  detectIOS () {
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

    var isAppleDevice = navigator.userAgent.includes('Macintosh')

    var isTouchScreen = navigator.maxTouchPoints >= 1 // true for iOS 13 (and hopefully beyond)

    return isIOS || (isAppleDevice && (isTouchScreen || this.iOS1to12quirk()))
  }

  searchSitename: string
  isIOSDevice = false

  iOS1to12quirk () {
    var audio = new Audio() // temporary Audio object

    audio.volume = 0.5 // has no effect on iOS <= 12

    return audio.volume === 1
  }

  isMobileView: boolean = false
  isTab: boolean = false
  private detectScreenSize () {
    if (window.innerWidth < 1090 && window.innerWidth > 750) {
      this.isMobileView = false
      this.isTab = true
    } else if (window.innerWidth < 740) {
      this.isMobileView = true
      this.isTab = false
    } else {
      this.isTab = false
      this.isMobileView = false
    }
  }

  constructor (private injector: Injector, private _mapsService: MapsService) {
    // if (window.matchMedia("(orientation: portrait)").matches) {
    //     // you're in PORTRAIT mode
    //     alert('Portarait')
    // }

    // if (window.matchMedia("(orientation: landscape)").matches) {
    //     // you're in LANDSCAPE mode
    //     alert('landscape')
    //  }

    this._mapsService.changeEmittedInSIteRegistration$.subscribe(data => {
      // console.log('CoordinatesData---------1-----------' + data == null);
      // console.log('CoordinatesData---------2-----------' + (data == {}));
      if (
        data == null ||
        (Object.entries(data).length === 0 && data.constructor === Object)
      ) {
        new azMaps.Map('createNewSiteRegistrationMap', {
          // center: [null, null],
          // zoom: 12,
          scrollZoomInteraction: true,
          language: 'en-US',
          authOptions: {
            authType: azMaps.AuthenticationType.subscriptionKey,
            subscriptionKey: Constants.AZURE_MAPS_KEY
          }
        })
        return false
      } else {
        this.azureMap(data.lat, data.lon)
      }
    })

    this._mapsService.removeMarkerEmitted$.subscribe(data => {
      var map = new azMaps.Map('createNewSiteRegistrationMap', {
        // center: [null, null],
        // zoom: 12,
        scrollZoomInteraction: true,
        language: 'en-US',
        authOptions: {
          authType: azMaps.AuthenticationType.subscriptionKey,
          subscriptionKey: Constants.AZURE_MAPS_KEY
        }
      })
      // map.clear()
    })

    this.detectScreenSize()
  }

  // @HostListener('window:orientationchange', ['$event'])
  // onOrientationChange(event) {
  //     console.log('orientationChanged');
  //     this.azureMap(this.latData, this.lonData);
  // }

  getTabWidthForMap (isTab) {
    if (!isTab) return null
    if (isTab) {
      let width: number
      let height: number
      let results = { width: null, height: null }
      if (window.innerWidth > 1030 && window.innerWidth < 1042) {
        results = {
          width: 390,
          height: 600
        }
      } else if (window.innerWidth > 746 && window.innerWidth < 763) {
        results = {
          width: 560,
          height: 400
        }
      } else if (window.innerWidth > 805 && window.innerWidth < 815) {
        results = {
          width: 285,
          height: 605
        }
      } else if (window.innerWidth > 1020 && window.innerWidth < 1030) {
        results = {
          width: 378,
          height: 610
        }
      } else if (window.innerWidth > 740 && window.innerWidth < 748) {
        results = {
          width: 300,
          height: 610
        }
      }
      return results
    }
  }

  ngAfterViewInit () {
    this.detectScreenSize()
    const map = new Map('createNewSiteRegistrationMap', {
      center: [0, 0],
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: Constants.AZURE_MAPS_KEY
      }
    })

    //      //Wait until the map resources are ready.
    // map.events.add('ready', () => {
    //     //Create a HTML marker and add it to the map.
    //     map.markers.add(new atlas.HtmlMarker({
    //       color: 'DodgerBlue',
    //       text: '10',
    //       position: [0, 0]
    //     }));
    //   });
    var mapWidth = 610
    var mapHeight = 465
    var isMobileScreen = this.isMobileView
    var isTabScreen = this.isTab
    if (isMobileScreen) {
      mapWidth = 245
    } else {
      mapWidth = 610
    }
    let results = { width: null, height: null }
    if (isTabScreen) {
      results = this.getTabWidthForMap(isTabScreen)
      mapWidth = results.width
      mapHeight = results.height
    }
    if (
      window.innerWidth < 748 &&
      window.innerWidth > 740 &&
      this.detectIOS()
    ) {
      mapHeight = 420
      mapWidth = 560
    }
    map.resize(mapHeight, mapWidth)
  }

  latData: any
  lonData: any
  azureMap (lat, lng) {
    this.latData = lat
    this.lonData = lng
    var mapWidth = 610
    var mapHeight = 465
    var isMobileScreen = this.isMobileView
    var isTabScreen = this.isTab
    if (isMobileScreen) {
      mapWidth = 245
    } else {
      mapWidth = 610
    }
    let results = { width: null, height: null }
    if (isTabScreen) {
      results = this.getTabWidthForMap(isTabScreen)
      mapWidth = results.width
      mapHeight = results.height
    }

    const _$authService = this.injector.get(AuthService)
    const _$mapsService = this.injector.get(MapsService)
    var latitude = lat
    var longitude = lng

    if (latitude == undefined) {
      return false
    }
    var map = new azMaps.Map('createNewSiteRegistrationMap', {
      center: [longitude, latitude],
      zoom: 12,
      scrollZoomInteraction: true,
      language: 'en-US',
      authOptions: {
        authType: azMaps.AuthenticationType.subscriptionKey,
        subscriptionKey: Constants.AZURE_MAPS_KEY
      }
    })

    //Wait until the map resources are ready.
    map.events.add('ready', function () {
      /* Add the zoom control to the map*/

      //Create a HTML marker and add it to the map.
      var marker = new azMaps.HtmlMarker({
        color: '#3dcd58',
        // text: '<i class="fa fa-check"></i>',
        draggable: true,
        position: [longitude, latitude]
        // popup: new azMaps.Popup({
        //     content: '<div style="padding:10px">Hello World</div>',
        //     pixelOffset: [0, -30]
        // })
      })

      var latit = document.getElementById('latitude')
      var longit = document.getElementById('longitude')

      //Add a drag event to get the position of the marker. Markers support drag, dragstart and dragend events.
      map.events.add('drag', marker, function () {
        var pos = marker.getOptions().position
        //Round longitude,latitude values to 5 decimal places.
        var one = Math.round(pos[0] * 100000) / 100000
        var two = Math.round(pos[1] * 100000) / 100000
        // (<HTMLInputElement>longit).value = one.toString();
        // (<HTMLInputElement>latit).value = two.toString();
      })

      // Register the marker drag handler
      map.events.add('dragend', marker, (e: any) => {
        var pos = marker.getOptions().position
        var one = Math.round(pos[0] * 100000) / 100000
        var two = Math.round(pos[1] * 100000) / 100000

        //send a request to Azure Maps reverse address search API */
        var url = 'https://atlas.microsoft.com/search/address/reverse/json?'
        url += 'subscription-key=qWZHWGxeaAOe3QxySU9MQoFJ93uwcFJF8Dr8hBoKgSM&'
        url += '&api-version=1.0'
        url += '&language=en-US'
        url += '&query=' + two + ',' + one

        /*Process request*/
        fetch(url, {
          headers: {
            Authorization: 'Bearer ' + _$authService.getAccessToken(),
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*'
            // subscriptionKey: Constants.AZURE_MAPS_KEY
          }
        })
          .then(function (response) {
            return response.json()
          })
          .then(function (res) {
            var address = res['addresses']
            var newAddrd = address[0]['address']['freeformAddress']
            var newAddrd =
              address.length !== 0
                ? address[0]['address']['freeformAddress']
                : 'No address for that location!'

            let tempObj = {
              street: address[0]['address']['freeformAddress'],
              zip: address[0]['address']['postalCode'],
              city:
                address[0]['address']['municipality'] != null
                  ? address[0]['address']['municipality']
                  : address[0]['address']['countrySecondarySubdivision'],
              country: address[0]['address']['country'],
              lat: two,
              lon: one
            }

            if (
              tempObj.zip == undefined ||
              tempObj.zip == '' ||
              tempObj.zip == null
            ) {
            }

            _$mapsService.siteRegistrationUpdateLocationAddressInUpdateSiteForm(
              tempObj
            )
          })
      })

      if (
        longitude == null ||
        longitude == '' ||
        latitude == null ||
        latitude == ''
      ) {
        map.markers.clear()
      } else {
        map.markers.add(marker)
      }

      //Add a click event to toggle the popup.
      // map.events.add('click', marker, () => {
      //     marker.togglePopup();
      // });
      map.controls.add(
        [
          new azMaps.control.ZoomControl(),
          new azMaps.control.CompassControl(),
          new azMaps.control.PitchControl(),
          new azMaps.control.StyleControl()
        ],
        {
          position: azMaps.ControlPosition.TopRight
        }
      )
    })

    if (
      window.innerWidth < 748 &&
      window.innerWidth > 740 &&
      this.detectIOS()
    ) {
      mapHeight = 420
      mapWidth = 560
    }
    map.resize(mapHeight, mapWidth)
    // map.resize(400, 150);
  }
}
