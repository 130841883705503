<div class="userlogin-pg container mainBgContianer box-shadow"
     style="
background-image: url('../assets/app-images/background_image_.png')">
    <!---->
    <!---->
    <!---->
    <div class="signup-cove-position">
        <div class="darkGreenBar"></div>
        <div class="lightGreenBar"></div>
        <div class="signUpPanel">
            <div class="row">
                <div class="column1 col-sm-7 col-md-7 col-lg-7">
                    <div class="application-group">
                        <div class="td-cell">
                            <!---->
                            <!---->
                            <div class="">
                                <h1 class="conextBrandName">Insight</h1>
                                <h1 class="cloudBrandName">Cloud</h1>

                                <!-- <h6 class="font-weight-light" style="color:#9EE6AC;">Version 2.0</h6> -->
                            </div>
                            <!---->
                        </div>
                    </div>
                </div>
                <div class="column2 col-sm-5 col-md-5 col-lg-5">
                    <!-- <div *ngIf="validToken" class="signin-up index login"> -->
                    <div class="signin-up index login">
                        <form class="form-horizontal"
                              (ngSubmit)="onSubmit()"
                              #f="ngForm"
                              class="form-horizontal needs-validation"
                              novalidate>

                            <div class="form-group padd-bt-13"
                                 dir="auto">
                                <label class="stylelabel float-labels labelClass1">Email:</label>
                                <!-- <div class="inner-addon right-addon">
                  <div id="passwordHelpBlock" class="form-text">
                    <span class='txt12'>New Password</span>
                  </div>
                </div> -->
                            </div>

                            <div class="input-group input-group-sm"
                                 [ngClass]="{'was-validated': myPassword.touched==true}">
                                <div class="input-group-prepend"
                                     style="height: 31px;">
                                    <div class="input-group-text">
                                        <!-- <span class="oi oi-lock-locked" style="color:gray;"></span> -->
                                        <svg _ngcontent-lxy-c3=""
                                             xmlns:xlink="http://www.w3.org/1999/xlink"
                                             class="auth0-lock-icon auth0-lock-icon-box"
                                             focusable="false"
                                             height="14px"
                                             version="1.1"
                                             viewBox="0 0 13 16"
                                             width="11px"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g _ngcontent-lxy-c3=""
                                               fill="none"
                                               fill-rule="evenodd"
                                               stroke="none"
                                               stroke-width="1">
                                                <g _ngcontent-lxy-c3=""
                                                   fill="#888888"
                                                   transform="translate(-288.000000, -1508.000000)">
                                                    <path _ngcontent-lxy-c3=""
                                                          d="M299,1523.998 L290,1523.998 C288.896,1523.998 288,1523.102 288,1521.999 L288,1515.999 C288,1514.895 288.896,1513.998 290,1513.998 L290,1513.998 L290,1512.499 C290,1510.015 292.015,1507.999 294.5,1507.999 C296.985,1507.999 299,1510.015 299,1512.499 L299,1513.999 C300.104,1513.999 301,1514.895 301,1515.999 L301,1521.999 C301,1523.103 300.104,1523.998 299,1523.998 L299,1523.998 Z M298,1512.499 C298,1510.566 296.433,1508.999 294.5,1508.999 C292.567,1508.999 291,1510.566 291,1512.499 L291,1513.998 L298,1513.998 L298,1512.499 L298,1512.499 Z M300,1515.999 C300,1515.446 299.552,1514.998 299,1514.998 L290,1514.998 C289.447,1514.998 289,1515.446 289,1515.999 L289,1521.999 C289,1522.551 289.447,1522.998 290,1522.998 L299,1522.998 C299.552,1522.998 300,1522.551 300,1521.999 L300,1515.999 L300,1515.999 Z M294.5,1520.998 C294.224,1520.998 294,1520.774 294,1520.498 L294,1517.498 C294,1517.223 294.224,1516.999 294.5,1516.999 C294.776,1516.999 295,1517.223 295,1517.498 L295,1520.498 C295,1520.774 294.776,1520.998 294.5,1520.998 L294.5,1520.998 Z">
                                                    </path>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                       
                                <input [type]="typePassword ? 'text' : 'password'" autocomplete="new-password"
                                       value="" class="form-control" [ngModel]="password" name="password"
                                       id="inputPassword" (ngModelChange)="validatePassWordForSpclChar($event)"
                                       required minlength=8 maxlength=25 placeholder="Password"
                                       #myPassword="ngModel" (click)="onKeyPress()">

                                <i (click)="togglePassword()" class="showPassword fa"
                                       [ngClass]="{'fa-eye-slash': !typePassword,'se-eye': typePassword}"
                                       [ngStyle]="{'z-index': showEyeIcon ? 9 : 0 }"></i>

                            </div>
                        
                            <div class="input-group input-group-sm mt-3"
                                 [ngClass]="{'was-validated': myPassword.touched==true}">
                                <div class="input-group-prepend"
                                     style="height: 31px;">
                                    <div class="input-group-text">
                                        <!-- <span class="oi oi-lock-locked" style="color:gray;"></span> -->
                                        <svg _ngcontent-lxy-c3=""
                                             xmlns:xlink="http://www.w3.org/1999/xlink"
                                             class="auth0-lock-icon auth0-lock-icon-box"
                                             focusable="false"
                                             height="14px"
                                             version="1.1"
                                             viewBox="0 0 13 16"
                                             width="11px"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g _ngcontent-lxy-c3=""
                                               fill="none"
                                               fill-rule="evenodd"
                                               stroke="none"
                                               stroke-width="1">
                                                <g _ngcontent-lxy-c3=""
                                                   fill="#888888"
                                                   transform="translate(-288.000000, -1508.000000)">
                                                    <path _ngcontent-lxy-c3=""
                                                          d="M299,1523.998 L290,1523.998 C288.896,1523.998 288,1523.102 288,1521.999 L288,1515.999 C288,1514.895 288.896,1513.998 290,1513.998 L290,1513.998 L290,1512.499 C290,1510.015 292.015,1507.999 294.5,1507.999 C296.985,1507.999 299,1510.015 299,1512.499 L299,1513.999 C300.104,1513.999 301,1514.895 301,1515.999 L301,1521.999 C301,1523.103 300.104,1523.998 299,1523.998 L299,1523.998 Z M298,1512.499 C298,1510.566 296.433,1508.999 294.5,1508.999 C292.567,1508.999 291,1510.566 291,1512.499 L291,1513.998 L298,1513.998 L298,1512.499 L298,1512.499 Z M300,1515.999 C300,1515.446 299.552,1514.998 299,1514.998 L290,1514.998 C289.447,1514.998 289,1515.446 289,1515.999 L289,1521.999 C289,1522.551 289.447,1522.998 290,1522.998 L299,1522.998 C299.552,1522.998 300,1522.551 300,1521.999 L300,1515.999 L300,1515.999 Z M294.5,1520.998 C294.224,1520.998 294,1520.774 294,1520.498 L294,1517.498 C294,1517.223 294.224,1516.999 294.5,1516.999 C294.776,1516.999 295,1517.223 295,1517.498 L295,1520.498 C295,1520.774 294.776,1520.998 294.5,1520.998 L294.5,1520.998 Z">
                                                    </path>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>

                                <input [type]="typePassword2 ? 'text' : 'password'" autocomplete="new-password"
                                value="" class="form-control greyColorInput" [ngModel]="passwordAgain" name="passwordAgain"
                                id="inputPassword2" (ngModelChange)="validatePassWordForSpclChar($event)"
                                required minlength=8 maxlength=25 placeholder="Confirm New Password"
                                #myPassword2="ngModel" (click)="onKeyPress()">

                                <i (click)="togglePassword2()" class="showPassword fa"
                                [ngClass]="{'fa-eye-slash': !typePassword2,'se-eye': typePassword2}"
                                [ngStyle]="{'z-index': showEyeIcon ? 9 : 0 }"></i>

                                <small class="form-text text-muted err-msg"
                                *ngIf='(myPassword2.touched && myPassword2.value!==myPassword.value)'>New password and
                                Confirm New Password do not match</small>

                                <div class="pass-err-container" *ngIf="!validPassword && myPassword.dirty">
                                    <div class="pass-err-inner">
                                        <div>
                                            <p class="err-header" style="padding-bottom: 7px;"> Password must meet the following requirements: </p>
                                            <p class="err-msg{{isMinLengthFail ? 2 : ''}}"> {{isMinLengthFail ? '✔' : '✘'}} At least 8 characters in length </p>			
                                            <p class="err-msg{{isLC ? 2 : ''}}"> {{isLC ? '✔' : '✘'}} Lower case letters (a-z) </p>
                                            <p class="err-msg{{isUC ? 2 : ''}}"> {{isUC ? '✔' : '✘'}} Upper case letters (A-Z) </p>
                                            <p class="err-msg{{isNumeric ? 2 : ''}}"> {{isNumeric ? '✔' : '✘'}} Numbers (0-9) </p>
                                            <p class="err-msg{{isSpclChar ? 2 : ''}}"> {{isSpclChar ? '✔' : '✘'}} Special characters (!&#64;#$%&^*) </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- {{myPassword.value}}
{{passwordAgain}} -->
                            <div class="form-group ">
                                <input class="btn btn-default btn-block active-btn mt-3"
                                       [disabled]="!f.valid || !validPassword || (myPassword2.value!==myPassword.value) || isNotAllowedChar || spaceNotAllowedChar"
                                       type="submit"
                                       value="Ok">
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </div>

    </div>

    <footer class="sch-app">
        <div class="container">
            <div class="row">
                <div class="col-sm-8 col-md-8 col-lg-8 copyright-note ">
                    <a class="privacy-link"
                       style="cursor: pointer;">Privacy Policy</a>


                    <!---->
                    <!---->
                    <div class="hidden-xs"
                         ng-if="isCLlabelAng == 'true'"><span id="j_id0:j_id30">
                            This application is protected by copyright law and international treaties. <a class="privacy-link"
                               onclick="window.open('','_blank');"
                               style="cursor: pointer;"></a>
                            <br></span><span id="j_id0:j_id34">
                            ©&nbsp;2024 Schneider Electric Industries SAS. All Rights Reserved.</span>

                    </div>
                    <!---->
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4 footer-logo">
                    <img class="life-is-on align-self-center"
                         [src]="schneiderLogo">
                    <!-- <img
                        src="https://secureidentity.schneider-electric.com/identity/resource/1544267078000/IDMS_PRM_POMP_Images/img/img02-1.png"> -->
                </div>
            </div>
        </div>
    </footer>
</div>