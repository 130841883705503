export enum LoginStatus {
  LOGGED_IN = "Logged In",
  LOGGED_OUT = "Logged Out",
}

export enum GatewayCredentialsPattern {
  USER_NAME = "^((?!<script>)(?!</script>)(?!<)(?!>).)*$",
  PASSWORD = "^((?!<script>)(?!</script>)(?!<)(?!>).)*$",
}

export enum GatewayLoginBaseKeys {
  KEY = "(0nextIn$!ght-02",
  IV = "$(hne!derE|ectr!",
}

export enum XanbusStatusCode {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  IDLE = "IDLE",
  PROGRESS = "IN_PROGRESS",
  UNAUTHORIZED = "Unauthorized"
}

export enum XanbusStatusMsg {
  SUCCESS = "Success",
  FAILURE = "Failed!",
  IDLE = "Idle",
  ERROR = "Error",
  UPGRADING = "Upgrading...",
}

export enum GatewayStatusCode {
  REQUESTED = "eRequested",
  VERIFY = "eVerifyingPackage",
  DOWNLOAD = "eExtracting",
  DOWNLOAD_FAILED = "eExtractionFailed",
  DONE = "eDone",
  INIT = "eNotStarted",
  ERROR = "Error",
  UNAUTHORIZED = "Unauthorized"
}

export enum GatewayStatusMsg {
  DOWNLOADING = "Downloading...",
  VERIFYING = "Verifying...",
  UPGRADING = "Upgrading...",
  UPGRADE_FAILED = "Upgrade failed!",
  UPGRADE_FINISHED = "Finished upgrade!",
  REBOOTING = "Rebooting... Please wait",
  REBOOTING_FINISHED = "Gateway rebooted successfully!",
  REBOOTS_IN = "Reboot completes with-in 5 minutes",
}

export enum ProgressbarType {
  SUCCESS = "success",
  FAILURE = "danger",
}

export enum DeviceInitStatusMsg {
  WAITING = "Waiting...",
  INITILIZING = "Initializing...",
}

export enum DevicesName {
  GATEWAY = "SCB",
  GATEWAY_NAME = "Gateway",
}

export enum SpinnerType {
  FU = "firmware-upgrade",
  PL = "popup-loader",
  FL = "firmware-loader"
}

export enum Spinners {
  LOGGING_IN = "Logging in...",
  UPGRADE_CHECK = "Checking any upgrade...",
  VERIFY = "Verifying upgrade process...",
  LOGGING_OUT = "Logging out...",
  FETCH_DEVICES = "Fetching devices...",
  FETCH_UPGRADE_DEVICES = "Fetching upgrade devices...",
  INIT_REBOOT = "Initiating reboot..."
}

export enum Selectors {
  LOGIN = "deviceLoginSelector",
  CONFIRM = "upgradeConfirmSelector",
  CCONTINUE_LOGOUT = "continueLogoutSelector",
  REBOOT = "rebootSelector",
  VERSION = "versionSelector"
}

export enum UserMsgs{
  MSG_A = "Process aborted! No communication to device or Session Id experied",
  MSG_B = "Device not ready, Try later",
  MSG_C = "Authentication Successful",
  MSG_D = "Authentication Failed! Enter valid password",
  MSG_E = "Something went wrong, please try later",
  MSG_F = "No updates available. This device have latest firmware",
  MSG_G = "Selected devices not saved, try again",
}

export enum CheckStatus{
  UNAUTHORIZED = "UNAUTHORIZED",
  IDLE ="IDLE",
  INPROGRESS = "IN_PROGRESS",
  ERROR = "ERROR"
}

export enum ProcessType{
  DL = "DEVICE_LIST",
  PU = "PROCEED_UPGRADE"
}
