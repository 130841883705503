/**
 * @author SESA545946 - Brijesh shakya
 * @email Brijesh.Shakya@non.se.com
 * @create date 2019-09-20 17:02:11
 * @modify date 2019-09-20 17:04:13
 * @desc Info dialog box as per scheneider standards
 */

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-info-dialog',
  template: `
  <div class="modal-header">
    <h4 class="modal-title">Information</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    </button>
  </div>
  <div class="modal-body row">
  <div class="col-lg-2 col-md-2 col-xl-2 col-sm-12 col-xs-12">
  <img [src]="infoModalImage" height="50" width="50" />
  </div>
  <div class="col-lg-10 col-md-10 col-xl-10 col-sm-12 col-xs-12">
      <span>{{showMessage}}</span>
      </div>
  </div>
  <div class="modal-footer">
    <!-- <input type="button" (click)="activeModal.close('Close click')" value="Close"> -->
    <button type="button" class="btn modal-cust-btn" (click)="activeModal.close('Close click')">Close</button>
  </div>
  `,
  styleUrls: []
})

export class InfoDialogComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private _authservice: AuthService) { }

  showMessage: any;
  ngOnInit() {
    this.showMessage = this._authservice.getModalMessage();
  }

  infoModalImage: string = "assets/img/power-flow/Info_icon.png";


}
