import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full', data: { toolbar: false } },
  { path: 'dashboard', loadChildren: () => import('./modules/my-dashboard/my-dashboard.module').then(m => m.MyDashboardModule) },
  { path: 'performance', loadChildren: () => import('./modules/performance/performance.module').then(m => m.PerformanceModule) },
  { path: 'site', loadChildren: () => import('./modules/multi-site/multi-site.module').then(m => m.MultiSiteModule) },
  { path: 'configuration', loadChildren: () => import('./modules/configuration/configuration.module').then(m => m.ConfigurationModule) },
  { path: 'events', loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule) },
  { path: 'reporting', loadChildren: () => import('./modules/reporting/reporting.module').then(m => m.ReportingModule) },
  { path: 'userprofile', loadChildren: () => import('./modules/user-profile/profile-modal.module').then(m => m.UserProfileModule)}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
