<nav class="navbar navbar-expand-lg navbar-light navbar-bg" [ngClass]="{'disabledNav': context === 'upgrade'}">
    <div class="container-fluid">
        <ul id="outerUl" class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item" *ngFor="let item of navbarItems" >
                <a id="outerMenu" [class.disabled-link]="!!item.subMenu"
                class="nav-link anchor-text mainAnchorText" routerLinkActive='active-nav-item' [routerLink]="[item.routerLink]">
                    {{ item.itemName }}</a>
                <ul *ngIf="item.subMenu && !isTab" class="dropdown subMenu">
                    <li class="aInit" *ngFor="let submenu of item.subMenu" class="gradientListStyle" (click)="submenuClick(submenu['routerLink'])">
                        <a class="inner-nav-link anchor-text" routerLinkActive='active-nav-item'>
                            <span class="d-flex justify-content-between">
                                <p class="nestedAnchor">{{ submenu.subMenuName }}</p>
                                <!-- <i class="fa fa-angle-right fa-2x pr-2">
                                </i> -->
                            </span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>

        <form class="searchbox" [ngClass]="{'searchbox-open': isOpened, '':!isOpened}">
            <span (click)="cancelAndClose()" [ngClass]="{'searchbox-icon': !isOpened, 'searchbox-icon1': isOpened}"><img
                    src="{{faSearchIcon}}" class="searchIcon"></span>
            <input autocomplete="off" type="text" [(ngModel)]="searchTerm" (input)="search($event)" placeholder="Search" name="search" [ngClass]="{'searchbox-input': !isOpened, 'searchbox-input1': isOpened}">
            <span *ngIf="isOpened" class="fa fa-times form-control-feedback" (click)="cancelAndClose()"></span>
        </form>
        <div *ngIf="routeSearchResults.length > 0" class="dropdownStyle">
            <div *ngFor="let c of routeSearchResults | searchRouteFilter: searchTerm; let last = last;" (click)="goToRoute(c)" class="routeStyle pl-2">
                <div class="routeListStyle">
                    <p>{{c.routeName}}</p>
                    <p class="moduleInfoStyle">{{c.moduleInfo}}</p>
                </div>
                <hr *ngIf="!last" class="listHr">
            </div>
        </div>

    </div>
</nav>
<div>
    <div class="active-bar-home">
        <hr class="active-bar mt-2">
    </div>
</div>