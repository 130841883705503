import { Component, OnDestroy, OnInit } from '@angular/core';
import { ResService } from 'src/app/services/res.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackModalComponent } from '../../header/topbar/feedback-modal/feedback-modal.component';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  public buildVersion: string;
  routeSubscribe: Subscription
  footerCustomClass: boolean = false
  fromTopHeight: number = 83
  constructor(
    private resource: ResService,
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
  ) {
    this.routeSubscribe = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (router.url == '/configuration/emailNotifications' || router.url == '/configuration/userRights') {
          this.footerCustomClass = true
          this.fromTopHeight = 83
        } else if (router.url == '/configuration/financialSetting') {
          this.footerCustomClass = true
          this.fromTopHeight = 96
        } else {
          this.footerCustomClass = false
        }
      }
    });
  }
  ngOnDestroy(): void {
    if (this.routeSubscribe) this.routeSubscribe.unsubscribe();
  }

  ngOnInit() {
    this.http.get('build').subscribe((data) => {
      this.buildVersion = data['appVersion'];
    });
  }

  openFeedBackModal() {
    const modalRef = this.modalService.open(FeedbackModalComponent, { size: 'xl', centered: true });
    modalRef.componentInstance.name = 'SendFeedBackModal';
  }

}
