/**
 * @author SESA480327 - Santhosh raj
 * @email santhosh.raj@non.se.com
 * @create date 2019-08-01 09:38:07
 * @modify date 2019-08-01 09:38:07
 * @desc Modal for device list
 */

export class DeviceList {
    id: string;
    name: string;
    isSelected: boolean;
    status: boolean;
    type: string;
    friendlyName: string;
    checked: boolean;
    actualName: string;

    constructor(id: string, name: string, isSelected: boolean, status: boolean, type: string, friendlyName: string, checked?: boolean, actualName?: string) {
        this.id = id;
        this.name = name;
        this.isSelected = isSelected;
        this.status = status;
        this.type = type;
        this.friendlyName = friendlyName;
        this.checked = checked;
        this.actualName = actualName;
    }
}