/**
 * @author SESA552234 - Soni Kumari
 * @email Soni.kumari@non.se.com
 * @create date 2020-01-07 02:36:13
 * @modify date 2020-01-08 02:36:13
 * @desc Error dialog box as per scheneider standards
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
// import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';

@Component({
    selector: 'app-error-dialog',
    template: `
  <div class="modal-header">
    <h4 class="modal-title">Error</h4>
    <button type
    ="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  
  <div class="row">
  <div class="col-sm-2">
      <img [src]="errorModalImage" height="50" width="50" />&nbsp;&nbsp;&nbsp; 
  </div>
      <div class="col-sm-10">
      <span>{{showMessage}}</span>
      </div> </div>
      <br>

  <div class="modal-footer">
    <button type="button" class="btn modal-cust-btn" (click)="activeModal.close('Close click')">Close</button>
    <button type="button" class="btn modal-cust-btn" (click)="resendMail()">Resend</button>
  </div>
  `,
    styleUrls: []
})


export class ResendEMailVerificationWithUsernameDialogComponent implements OnInit, OnDestroy {

    resendMailConfirmationSubscription: Subscription;
    token: string;

    constructor(private _authService: AuthService, private router: Router, private modalService: NgbModal, private activatedRoute: ActivatedRoute, public activeModal: NgbActiveModal, private _authservice: AuthService) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.token = params['token'];
        });
    }

    showMessage: any;
    ngOnInit() {
        this.showMessage = this._authservice.getModalMessage();

    }

    resendMail() {
        let hasOpenedModal = this.modalService.hasOpenModals();
        if (hasOpenedModal) {
            this.modalService.dismissAll();
        }
        let token = ""
        let username = this._authService.getLoggedInUsername()
        this.resendMailConfirmationSubscription = this._authservice.resendConfirmationMail(token, username).subscribe(data => {
            if (data['status'] == 'Success') {
                this.router.navigate(['/login']);
                this._authservice.setModalMessage(data['msg']);
                // this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
            }
        })
    }


    errorModalImage: string = "assets/img/power-flow/Error_icon.png";

    ngOnDestroy(): void {
        if (this.resendMailConfirmationSubscription) this.resendMailConfirmationSubscription.unsubscribe()
    }

}
