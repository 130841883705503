/**
 * @author SESA545946 - Brijesh shakya
 * @email Brijesh.Shakya@non.se.com
 * @create date 2019-09-18 18:12:11
 * @modify date 2019-09-19 02:36:13
 * @desc Error dialog box as per scheneider standards
 */

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
// import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';

@Component({
  selector: 'app-error-dialog',
  template: `
  <div class="modal-header">
    <h4 class="modal-title">Error</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  <div class="row">
  <div class="col-sm-2">
      <img [src]="errorModalImage" height="50" width="50" />&nbsp;&nbsp;&nbsp; 
  </div>
      <div class="col-sm-10">
      <span>{{showMessage}}</span>
      </div> </div>
      <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn modal-cust-btn" (click)="activeModal.close('Close click')">Close</button>
    <button *ngIf="isTrue" type="button" class="btn modal-cust-btn" (click)="resendMail()">Resend</button>
  </div>
  `,
  styleUrls: []
})


export class ErrorDialogComponent implements OnInit {

  resendMailConfirmationSubscription: Subscription | undefined

  constructor(private _authService: AuthService, private modalService: NgbModal, public activeModal: NgbActiveModal, private _authservice: AuthService) { }

  isTrue: boolean = false
  resendMail() {
    let hasOpenedModal = this.modalService.hasOpenModals();
    if (hasOpenedModal) {
      this.modalService.dismissAll();
    }
    let token = ""
    this.resendMailConfirmationSubscription = this._authservice.resendConfirmationMail(token, this._authService.getLoggedInUsername()).subscribe(data => {
      if (data['status'] == 'Success') {


        this._authservice.setModalMessage(data['msg']);
        // this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
      }
    })
  }

  showMessage: any;
  ngOnInit() {
    this.showMessage = this._authservice.getModalMessage();
    if (this.showMessage == "Your email could not be verified and token expired.") {
      this.isTrue = true
    }
    else {
      this.isTrue = false
    }
  }

  errorModalImage: string = "assets/img/power-flow/Error_icon.png";

}
