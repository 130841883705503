/**
 * @author SESA545946 - Brijesh shakya
 * @email Brijesh.Shakya@non.se.com
 * @create date 2019-09-18 17:32:33
 * @modify date 2019-09-19 02:16:19
 * @desc Success dialog box as per scheneider standards
 */

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-success-dialog',
  template: `
  <div class="modal-header">
    <h4 class="modal-title">Success</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  <div class="row">
  <div class="col-sm-2">
      <img [src]="successModalImage" height="50" width="50" />&nbsp;&nbsp;&nbsp; </div>
      <div class="col-sm-10">
      <span>{{showMessage}}</span>
      </div> </div>
      <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn modal-cust-btn" (click)="activeModal.close('Close click')">Close</button>
  </div>
  `,
  styles: ['']
})

export class SuccessDialogComponent implements OnInit {

  successModalImage: string = "assets/img/power-flow/Sucess_icon.png";

  constructor(public activeModal: NgbActiveModal, private _authservice: AuthService) { }

  showMessage: any;
  ngOnInit() {
    this.showMessage = this._authservice.getModalMessage();
  }

}
