export class ImagePath {

    public static generatorImage: string = "assets/image-icons/generator-new.PNG";
    public static solarImage: string = "assets/image-icons/solar_panel.png";
    public static batteryImage: string = "assets/image-icons/Battery_Green.png";
    public static gridImage: string = "assets/image-icons/grid_new.png";
    public static loadImage: string = "assets/image-icons/load_new.png";
    public static cloudSyncImage: string = "assets/image-icons/cloud_sync.png"
    public static searchIconImage: string = "assets/image-icons/search-icon.png"
    public static alarmIconImage: string = "assets/image-icons/alarm.png"
    public static warningIconImage: string = "assets/image-icons/warning.png"
    public static energyInputIconImage: string = "assets/image-icons/energy-input.png"
    public static energyOutputIconImage: string = "assets/image-icons/energy-output.png"

    public static co2ImageIconImage: string = "assets/image-icons/co2-saved.png"
    public static treeImageIconImage: string = "assets/image-icons/trees.png"
    public static currencyImageIconImage: string = "assets/svg-icons/money.svg"
    public static oilSavedImageIconImage: string = "assets/image-icons/oil-saved.PNG"
    public static oilSavedSvgImageIconImage: string = "assets/svg-icons/oil-saved.svg"

    public static wirelessStatusOnlineIconImage: string = "assets/svg-icons/wirelessStatusOnline.svg"
    public static wirelessStatusOfflineIconImage: string = "assets/svg-icons/wirelessStatusOffline.svg"

    // SVG icons
    public static solarSvgIcon: string = "assets/svg-icons/Solar.svg";
    public static gridSvgIcon: string = "assets/svg-icons/Grid.svg";
    public static gridSvgSmallIcon: string = "assets/svg-icons/grid-small.svg";
    public static batterySvgIcon: string = "assets/svg-icons/Battery.svg";
    public static loadSvgIcon: string = "assets/svg-icons/Load.svg";
    public static loadSvgSmallIcon: string = "assets/svg-icons/home-small.svg";
    public static generatorSvgIcon: string = "assets/svg-icons/Generator.svg";
    public static co2SvgIcon: string = "assets/svg-icons/co2.svg";
    public static treesSvgIcon: string = "assets/svg-icons/trees.svg";
    public static searchSvgIcon: string = "assets/svg-icons/white-search.svg";
    public static searchGreenSvgIcon: string = "assets/image-icons/greysearch.svg";
    public static maintenanceSvgIcon: string = "assets/svg-icons/white-maintenance.svg";
    public static locationSvgIcon: string = "assets/svg-icons/location.svg";
    public static refreshWhiteSvgIcon: string = "assets/svg-icons/refresh-white.svg";
    public static refreshSvgIcon: string = "assets/svg-icons/refresh.svg";

    public static cancelSvgIcon: string = "assets/svg-icons/cross.svg";
    public static menuArrowIcon: string = "assets/image-icons/menu-arrow.jpg";
    //top bar icons
    public static notificationIcon: string = "assets/image-icons/top-bar/notification.svg";
    public static createSiteIcon: string = "assets/image-icons/top-bar/createSite.svg";
    public static multiSiteIcon: string = "assets/image-icons/top-bar/multiSite.svg";
    public static configurationIcon: string = "assets/image-icons/top-bar/configuration.svg";
    public static devicePerformanceAnalysisIcon: string = "assets/image-icons/top-bar/performance.svg";
    public static reportingIcon: string = "assets/image-icons/top-bar/reporting.svg";

    public static cloudSyncImageBW: string = "assets/image-icons/cloud_syncbw.png"


    //siteComparison
    public static treesPlantedImage: string = "assets/image-icons/site-comparison/environment/Trees_Planted.png"
    public static co2AvoidedImage: string = "assets/image-icons/site-comparison/environment/CO2_Avoided.png"

    public static settings:string="assets/image-icons/settings.png"

}