import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function createPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        const value = control.value;
        if (!value) {
            return null;
        }

        const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%&^*]).{8,25}$/;
        if (pattern.test(value)) {
            return null;
        }

        let passwordStrength = null;
        const upperCase = /(?=.*?[A-Z])/;
        const lowerCase = /(?=.*?[a-z])/;
        const numeric = /(?=.*?[0-9])/;
        const spclChar = /(?=.*?[!@#$%&^*])/;
        const notallowedChar = /(?=.*?[-(){}[\].'|<>?=;:+,`~_/\\"])/;

        const hasUpperCase = upperCase.test(value);
        const hasLowerCase = lowerCase.test(value);
        const hasNumeric = numeric.test(value);
        const hasSpecial = spclChar.test(value);
        const hasOther = notallowedChar.test(value);

        passwordStrength = hasUpperCase ? passwordStrength : { ...passwordStrength, upperCase: true };
        passwordStrength = hasLowerCase ? passwordStrength : { ...passwordStrength, lowerCase: true };
        passwordStrength = hasNumeric ? passwordStrength : { ...passwordStrength, numeric: true };
        passwordStrength = hasSpecial ? passwordStrength : { ...passwordStrength, special: true };
        const result = { passwordStrength, notAllowed: hasOther };
        return !!passwordStrength || hasOther ? result : null;
    }
}