<div class="modal-body">
    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <form [formGroup]="siteForm">
                <div class="form-group ">
                    <div class="d-flex justify-content-between">
                        <label for="siteName" class="labelText">Site
                            Name<span class="element-required">*</span></label>
                        <i [ngbTooltip]="siteNameToolTip" tooltipClass="site-create-custom-class" placement="bottom"
                            class="fa fa-info-circle tooltipCss" aria-hidden="true"></i>
                    </div>
                    <input class="form-control input-sm" type="text" minlength="1" maxlength="100"
                        placeholder="Enter name of the site" formControlName="siteName" required />
                    <input class="info-element-value evh element-type-input-right" hidden placeholder="mm-dd-yyyy"
                        ngbDatepicker #d="ngbDatepicker" formControlName="dateCommissioned" />
                </div>
                <div class="fieldErrorSection d-flex flex-column justify-content-start">
                    <div *ngIf="siteForm.controls['siteName'].invalid && (siteForm.controls['siteName'].dirty || siteForm.controls['siteName'].touched)"
                        class="text-danger">
                        <small *ngIf="siteForm.controls['siteName'].errors.required">
                            Site name is required.
                        </small>
                        <small *ngIf="siteForm.controls['siteName'].errors.pattern">
                            Only alphabets and numbers - + & _ are allowed
                        </small>
                    </div>
                    <div *ngIf="isSiteAvailable" class="text-danger">
                        <small>{{isSiteAvailableMsg}}</small>
                    </div>
                </div>
            </form>

            <form [formGroup]="addDeviceForm">
                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label for="macAddress" class="labelText">MAC Address<span class="element-required">*</span>
                        </label>
                        <i [ngbTooltip]="gatewayIdToolTip" tooltipClass="site-create-custom-class" placement="bottom"
                            class="fa fa-info-circle tooltipCss" aria-hidden="true"></i>
                    </div>
                    <input type="text" type="text" validMacAddress class="form-control input-sm"
                        formControlName="gateWayId" required placeholder="Enter MAC Address">

                </div>
                <div class="fieldErrorSection d-flex flex-column justify-content-start">
                    <div *ngIf="addDeviceForm.controls['gateWayId'].invalid && (addDeviceForm.controls['gateWayId'].dirty || addDeviceForm.controls['gateWayId'].touched)"
                        class="text-danger">
                        <small *ngIf="addDeviceForm.controls['gateWayId'].errors.required">
                            MAC Address is required
                        </small>
                        <br *ngIf="addDeviceForm.controls['gateWayId'].errors.required">
                        <!-- <small *ngIf="addDeviceFormValidationFails">
                                                    (Invalid pattern) only alphabets numbers - : are allowed
                                                </small>  -->
                        <!-- <small *ngIf="addDeviceForm.controls['gateWayId'].errors.pattern">
                                                    only alphabets numbers - : are allowed (Max 17 characters)
                                                </small> -->
                        <br *ngIf="addDeviceForm.controls['gateWayId'].errors.pattern">
                        <!-- <small *ngIf="addDeviceForm.controls['gateWayId'].errors.minlength">
                                                    Excat 17 characters are allowed
                                                </small> -->
                        <!-- {{addDeviceFormValidationFails}} 
                                            </div>
                                            <div class="text-danger d-flex justify-content-end float-right"
                                                *ngIf="(!addDeviceForm.valid) && !(addDeviceForm.controls['gateWayId'].errors)">
                                                <small>Invalid MAC Address</small>
                                            </div>
                                            <div class="text-danger d-flex justify-content-end float-right"
                                                *ngIf="addDeviceFormValidationFails">
                                                <small>Invalid MAC Address</small>
                                            </div> -->
                        <!-- {{addDeviceFormValidationFails}} -->
                        <div class="fieldErrorSection d-flex flex-column justify-content-end text-right"
                            *ngIf="(!addDeviceForm.valid || addDeviceFormValidationFails) && !(addDeviceForm.controls['gateWayId'].errors.required)">
                            <small>Invalid MAC Address</small>
                        </div>

                    </div>
                </div>

                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label for="serialNumber" class="labelText">Product
                            Serial Number<span class="element-required">*</span>
                        </label>
                        <i [ngbTooltip]="gatewaySerialIdTooTip" tooltipClass="site-create-custom-class"
                            placement="bottom" class="fa fa-info-circle tooltipCss" aria-hidden="true"></i>
                    </div>
                    <input type="text" class="form-control input-sm" formControlName="gateWaySerialNo" minlength="1"
                        maxlength="20" required placeholder="Enter Product Serial Number ">
                    <!-- <small id="serialNumberhelp" class="form-text text-muted">Present on back of Device</small> -->
                </div>

                <div class="fieldErrorSection d-flex flex-column justify-content-start">
                    <div *ngIf="addDeviceForm.controls['gateWaySerialNo'].invalid && (addDeviceForm.controls['gateWaySerialNo'].dirty || addDeviceForm.controls['gateWaySerialNo'].touched)"
                        class="text-danger">
                        <small *ngIf="addDeviceForm.controls['gateWaySerialNo'].errors.required">
                            Product serial number is required
                        </small>
                        <small *ngIf="addDeviceForm.controls['gateWaySerialNo'].errors.pattern">
                            only alphabets numbers - : are allowed
                        </small>
                        <small *ngIf="addDeviceForm.controls['gateWaySerialNo'].errors.minlength">
                            minimum 1 and maximum 20 characters are allowed
                        </small>
                    </div>
                    <!-- <div *ngIf="f.gateWaySerialNo.touched && f.gateWaySerialNo.errors" class="invalid-feedback">
                    <div *ngIf="f.gateWaySerialNo.errors.required">serial number is required</div>
                  </div> -->
                </div>
            </form>

            <!-- Installed Location -->

            <h6 class="pt-3" style="color: #0F0F0F;">Installed Location </h6>
            <div class="">
                <!-- <small style="color: #4290CF;">Enter an address to find your building and then pinpoint a location by
                    using a spot on the map.
                    GPS
                    coordinates will update automatically</small>
                <br> -->
                <label for="streetAddress" class="labelText">Address<span class="element-required">*</span>
                </label>&nbsp;&nbsp;

                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">
                            <i class="fa" [ngClass]="{ 'fa-cog fa-spin': loadingClients, 'fa-search': !loadingClients }"
                                aria-hidden="true"></i>
                        </span>
                    </div>
                    <input [(ngModel)]="typeaheadClient" validFeedback [ngbTypeahead]="searchClient"
                        [resultTemplate]="rt" [inputFormatter]="formatter" (focus)="focus$.next($event.target.value)"
                        placement="top-left" (click)="click$.next($event.target.value)" #instance="ngbTypeahead"
                        type="text" class="form-control" aria-describedby="basic-addon1">
                </div>

                <ng-template #rt let-r="result" let-t="term">
                    <div class="media">
                        <!-- <div class="d-flex justify-content-center align-content-center align-items-center">
                            <i class="fa fa-user-circle fa-2x mr-3" aria-hidden="true"></i>
                        </div> -->
                        <div class="media-body">
                            <!-- <small class="mt-1">{{r.street+', '+ r.city + ', '+r.country}}</small> -->
                            <!-- <div style="white-space: normal; font-size: 10px;">{{r.city + ', '+r.state+ ',
                                '+r.country}}</div> -->
                            <small class="mt-1">{{r.street+',
                                '+r.country}}</small>
                        </div>
                    </div>
                </ng-template>
                <br>
                <form [formGroup]="siteLocationForm">
                    <div class="form-group">
                        <div class="d-flex justify-content-between">
                            <label for="streetAddress" class="labelText">Street Address
                            </label>
                            <i [ngbTooltip]="installedLocationTooltip" tooltipClass="site-create-custom-class"
                                placement="bottom" class="fa fa-info-circle tooltipCss" aria-hidden="true"></i>
                        </div>
                        <input type="text" type="text" style="background-color: #f1f1f1;" readonly class="form-control"
                            formControlName="street">
                    </div>

                    <div class="loc-zip-state-country-new d-flex flex-row flex-wrap">
                        <div class="loc-zip-new">
                            <label for="streetAddress" class="labelText">Zip
                            </label>&nbsp;&nbsp;
                            <input class="loc-element-value-new evh element-type-input-left" type="text"
                                style="background-color: #f1f1f1;" readonly formControlName="zip" />
                        </div>
                        <div class="loc-state-city">
                            <label for="streetAddress" class="labelText">City<span class="element-required">*</span>
                            </label>&nbsp;&nbsp;
                            <input class="loc-element-value-new evh element-type-input-left" type="text"
                                style="background-color: #f1f1f1;" readonly formControlName="city" />
                        </div>
                        <div class="loc-country-new-1">
                            <label for="streetAddress" class="labelText">Country<span class="element-required">*</span>
                            </label>
                            <input class="loc-element-value-new evh element-type-input-left" type="text"
                                style="background-color: #f1f1f1;" readonly formControlName="country" />
                        </div>
                    </div>

                    <div class="loc-zip-state-country-new d-flex flex-row flex-wrap">
                        <div class="loc-state-new">
                            <label for="latitude" class="labelText">Latitude<span class="element-required">*</span>
                            </label>&nbsp;&nbsp;
                            <input class="loc-element-value-new evh element-type-input-left" type="text"
                                style="background-color: #f1f1f1;" readonly formControlName="latitude" />
                        </div>&nbsp;&nbsp;
                        <div class="loc-country-new">
                            <label for="longitude" class="labelText">Longitude<span class="element-required">*</span>
                            </label>&nbsp;&nbsp;
                            <input class="loc-element-value-new evh element-type-input-left" type="text"
                                style="background-color: #f1f1f1;" readonly formControlName="longitude" />
                        </div>
                    </div>
                </form>
            </div>
        </div>



        <!-- MAP -->

        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 margin-Top">
            <!-- <div class="location-element d-flex flex-column justify-content-start flex-wrap"> -->
            <!-- <div class="third"> -->
            <app-map-site-registration-component></app-map-site-registration-component>
            <!-- <app-settings-map-component-edit-site></app-settings-map-component-edit-site> -->
            <!-- </div> -->  
            <!-- </div> -->
        </div>
    </div>
    <div class="row d-flex justify-content-center p-2">
        <div class="text-center" style="margin-top: 1%; border-top: 0px; margin-bottom: 1%; align-items: center;">
            <button type="button" class="btn  btn-sm" style="background-color: #9FA0A4; color: white;"
                (click)="clearForm()">Reset
                Data</button>&nbsp;&nbsp;
            <button (click)="validateDevice()" class="btn btn-sm" style="background-color: #3dcd58; color: white;"
                type="button"
                [disabled]="siteForm.pristine || siteForm.invalid || isSiteAvailable || addDeviceForm.invalid || addDeviceForm.pristine || siteLocationForm.invalid || siteLocationForm.pristine">Create
                Site</button>
        </div>
    </div>
</div>



<ng-template #gatewayIdToolTip>
    <p>{{gatewayTooTipMessage}}</p>
</ng-template>
<ng-template #gatewaySerialIdTooTip>{{gatewaySerialIdTooTipMessage}}</ng-template>
<ng-template #installedLocationTooltip>Enter An Address To Find Your Location And Then Pinpoint A Building By
    Using A Spot On The Map. GPS Coordinates Will Update Automatically</ng-template>
<ng-template #siteNameToolTip>
    <p>{{siteNameToolTipMessage}}</p>
</ng-template>