import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subscription, timer } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { map, retryWhen, take, delay } from "rxjs/operators";
import { GatewayUpgradeRequest } from '../models/gateway-requests';

@Injectable({
  providedIn: "root",
})
export class FirmWareService {
  private baseUrl: string;
  private dataStore: {
    //    sites: SiteList[]
  };

  private _gatewayUserName: string;
  private _gatewayPassword: string;
  private _loginStatus: boolean;
  private _isDeviceOperator: boolean;
  private _isLoggedIn: boolean = null;
  private _deviceAuthDetails: any = null;

  private _deviceLoginStatus: boolean = null;
  protected counter: number = -1;
  private tick = 1000;
  timeSub : Subscription;
  
  countDown: Observable<number>;
  private _countDown: BehaviorSubject<number>;

  constructor(private http: HttpClient) {
    this.baseUrl = ""; //Base URL + site list end point
    this.dataStore = { sites: [] };

    this._countDown = <BehaviorSubject<number>>new BehaviorSubject(null);
    this.countDown = this._countDown.asObservable();
    
  }
  get GatewayUsername(): string {
    return this._gatewayUserName;
  }

  set GatewayUsername(value: string) {
    this._gatewayUserName = value;
  }

  get GatewayPassword(): string {
    return this._gatewayPassword;
  }

  set GatewayPassword(value: string) {
    this._gatewayPassword = value;
  }

  get loginStatus(): boolean {
    return this._loginStatus;
  }

  set loginStatus(value: boolean) {
    this._loginStatus = value;
  }

  get isDeviceOperator(): boolean {
    return this._isDeviceOperator;
  }

  set isDeviceOperator(value: boolean) {
    this._isDeviceOperator = value;
  }

  get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
  }

  get deviceAuthDetails(): any {
    return this._deviceAuthDetails;
  }

  set deviceAuthDetails(authDetails: any) {
    this._deviceAuthDetails = authDetails;
  }

  get deviceLoginStatus(): any {
    return this._deviceLoginStatus;
  }

  set deviceLoginStatus(status: any) {
    this._deviceLoginStatus = status;
  }
  
  get sessionTime(): number {
    return this.counter;
  }
  set sessionTime(time: number){
    this.counter = time;
  }

  startSessionTimer(){
    this.timeSub = timer(0, this.tick).subscribe(() => {
      if(this.counter < 0){
        this.sessionTime = -1;
        this.timeSub.unsubscribe();
      }else{
        this._countDown.next(--this.counter);
      }
    });
  };

  getAllFirmWareList(userId: number | string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}sites/all/${userId}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getFirmwareData(): Observable<any> {
    return this.http.get<any>(`tsi/firmware`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getFirmwareDetailData(siteName: string): Observable<any> {
    let siteObj = {
      siteName: siteName,
    };
    const headers = new HttpHeaders()
      .set(
        "Cache-Control",
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
      )
      .set("Pragma", "no-cache")
      .set("Expires", "0");
    return this.http
      .post<any>(`tsi/firmwaredetail`, siteObj, { headers })
      .pipe(
        map((data) => {
          return data;
        //   return [
        //     {
        //         "deviceName": "SCB",
        //         "instanceId": "",
        //         "friendlyName": "-",
        //         "connectivity": "Online",
        //         "curVersion": "v1.17",
        //         "curBuild": "-",
        //         "newVersion": "v1.18",
        //         "newBuild": "BN79",
        //         "newBuildNo": "79",
        //         "blobLink": "https://cgci2firmware.blob.core.windows.net/gateway/865-0329-v.17-BN79.epkg",
        //         "md5": null,
        //         "blobFileName": "865-0329-v.17-BN79.epkg",
        //         "selected": false,
        //         "value": "SCB[null]",
        //         "fga": "-",
        //         "updated": false
        //     }
        // ];
        })
      );
  }

  getSiteFirmwareUpgradeStatus(firmwareDetails: any): Observable<any> {
    return this.http
      .post<any>(`tsi/firmware/site-firmware-upgrade-status`, firmwareDetails)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  upgradefirmware(firmwareDetails: any): Observable<any> {
    return this.http.post<any>(`tsi/firmwareupgrade`, firmwareDetails).pipe(
      map((data) => {
        return data;
      })
    );
  }

  upgradeXanbusFirmware(firmwareDetails: any): Observable<any> {
    return this.http.post<any>(`tsi/upgradexanbus`, firmwareDetails).pipe(
      map((data) => {
        return data;
      })
    );
  }

  fetchUserPreference(): Observable<any> {
    return this.http.get<any>(`tsi/splash/fetch`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  checkNewFirmwareVersion(): Observable<any> {
    return this.http.get<any>(`tsi/firmware/has/new-version`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // checkNewFirmwareVersion(): Observable<any> {
  //   return this.http.get<any>(`tsi/firmware/has/upgrade-devices`).pipe(
  //     map((data) => {
  //       return data;
  //     })
  //   );
  // }

  getDeviceFirmware(adminSettings: any): Observable<any> {
    const headers = new HttpHeaders()
      .set(
        "Cache-Control",
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
      )
      .set("Pragma", "no-cache")
      .set("Expires", "0");
    return this.http
      .post<any>(`tsi/firmware/has/upgrade-devices`, adminSettings, { headers })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  updateUserPreference(userPreference: boolean): Observable<any> {
    return this.http.post<any>(`tsi/splash/update`, userPreference).pipe(
      map((data) => {
        return data;
      })
    );
  }

  pullFirmwareUpgradeStatus(firmwareDetails: any): Observable<any> {
    return this.http.post<any>(`tsi/upgradestatus`, firmwareDetails).pipe(
      map((data) => {
        return data;
      })
    );
  }

  pullGatewayUpgradeStatus(firmwareDetails: any): Observable<any> {
    return this.http
      .post<any>(`tsi/gatewayupgradestatus`, firmwareDetails)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  setGatewayReboot(firmwareDetails: any): Observable<any> {
    return this.http
      .post<any>(`tsi/firmware/rebootgateway`, firmwareDetails)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  pullGatewayRebootStatus(firmwareDetails: any): Observable<any> {
    return this.http
      .post<any>(`tsi/firmware/gatewayrebootstatus`, firmwareDetails)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  authenticateFirmwareCredentials(credentials: any): Observable<any> {
    return this.http.post<any>(`tsi/gateway/login`, credentials).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getUpgradeFirmwareDetails(): Observable<any> {
    return this.http.get<any>(`tsi/alarms`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getAllFirmWares(): Observable<any> {
    return this.http.get<any>("tsi/listFirmware").pipe(
      map((data) => {
        return data;
      })
    );
  }
  getSelectedDeviceFirmWares(deviceType, fgaNumber): Observable<any> {
    const url = "tsi/listFirmware/" + deviceType + "/" + fgaNumber;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // ------------------- Firmware New ----------------------
  getConnectedDevices(siteName: string): Observable<any> {
    const headers = new HttpHeaders()
      .set(
        "Cache-Control",
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
      )
      .set("Pragma", "no-cache")
      .set("Expires", "0");
    return this.http
      .get<any>(`tsi/gateway/devices/` + siteName, { headers })
      .pipe(
        map((data) => {
          return data;
        })
      );
  };

  getFirmwareUpgradeStatus(firmwareDetails: any): Observable<any> {
    return this.http
      .post<any>(`tsi/gateway/firmware/status`, firmwareDetails)
      .pipe(
        map((data) => {
          return data;
        })
      );
  };

  getGatewayUpgradeCheck(siteName: string): Observable<any> {
    const headers = new HttpHeaders()
      .set(
        "Cache-Control",
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
      )
      .set("Pragma", "no-cache")
      .set("Expires", "0");
      return this.http
      .get<any>(`tsi/gateway/firmware/status/` + siteName, { headers })
      .pipe(
        map((data) => {
          return data;
        })
      );
  };

  setSiteUpgrade(siteData: GatewayUpgradeRequest): Observable<any> {
    return this.http
      .post<any>(`tsi/gateway/firmware/upgrade`, siteData)
      .pipe(
        map((data) => {
            return data;
        })
      );
  };

  getGatewayDevicesUpgradeStatus(siteName: String): Observable<any>{
    const headers = new HttpHeaders()
    .set(
      "Cache-Control",
      "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
    )
    .set("Pragma", "no-cache")
    .set("Expires", "0");
    return this.http
    .get<any>(`tsi/gateway/firmware/upgrade/status/` + siteName, { headers })
    .pipe(
      map((data) => {
        return data;
      })
    );
  };

  initiateDevicesUpgrade(siteName: String): Observable<any>{
    const headers = new HttpHeaders()
    .set(
      "Cache-Control",
      "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
    )
    .set("Pragma", "no-cache")
    .set("Expires", "0");
    return this.http
    .get<any>(`tsi/gateway/firmware/upgrade/init/` + siteName, { headers })
    .pipe(
      map((data) => {
        return data;
      })
    );
  };

  finishUpgrade(siteName: String): Observable<any>{
    const headers = new HttpHeaders()
    .set(
      "Cache-Control",
      "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
    )
    .set("Pragma", "no-cache")
    .set("Expires", "0");
    return this.http
    .get<any>(`tsi/gateway/firmware/upgrade/remove/` + siteName, { headers })
    .pipe(
      map((data) => {
        return data;
      })
    );
  };

  rebootGateway(siteName: String): Observable<any>{
    const headers = new HttpHeaders()
    .set(
      "Cache-Control",
      "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
    )
    .set("Pragma", "no-cache")
    .set("Expires", "0");
    return this.http
    .get<any>(`tsi/gateway/reboot/` + siteName, { headers })
    .pipe(
      map((data) => {
        return data;
      })
    );
  };

  
  checkUpgradeInProgressForGatewayOXanbus(firmwareDetails: any): Observable<any> {
    return this.http.post<any>(`tsi/firmware/is-upgrade-in-progress`, firmwareDetails).pipe(
      map((data) => {
        return data;
      })
    );
  }
};