/**
 * @author Anuj Grover
 * @email anuj.grover@non.se.com |  SESA625719
 * @create date 2021-07-28 02:26:52
 * @modify date 2021-07-28 02:26:52
 * @desc [description]
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, EmailValidator } from '@angular/forms';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ErrorDialogComponent } from 'src/app/modules/shared/dialogueboxes/error-dialog/error-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { SuccessDialogComponent } from 'src/app/modules/shared/dialogueboxes/success-dialog.component';
import { UpdatedForgetDialogComponent } from 'src/app/modules/shared/dialogueboxes/error-dialog/updatedForgetPassword';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('f', { static: true }) loginForm: NgForm;

  erroMsg: any;
  private erooroMessageForBadCredentials;
  string;
  errorObject: any;

  private forgotUrl = 'forgotPassword';
  email: string;
  msg: string;
  emailtext: string;

  forgotMsg = [];

  constructor(
    private _authService: AuthService,
    private modalService: NgbModal,
    private router: Router,
    private http: HttpClient
  ) { }

  ngOnInit() { }

  public mailSendState: boolean = false;

  schneiderLogo = 'assets/app-images/schneider_LIO_White_RGB.png';

  onSubmit() {
    this.mailSendState = true;
    this._authService.setLoggedUsername(this.loginForm.value.emailtext)
    const headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded; charset=utf-8')
      .set('Authorization', 'Basic ');

      this.http.post<any>(this.forgotUrl, this.loginForm.value.emailtext).pipe(map(res => res))
      .subscribe(
        data => {
          this.mailSendState = false;
          if (data['status'] === 'Success') {
            // this.alertService.success(data['msg']);
            this._authService.setModalMessage(data['msg']);
            this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
          } else if (data['status'] === 'Failure') {
            this._authService.setModalMessage(data['msg']);
            this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
            // this.alertService.error(data['msg']);
          }
        },
        err => {
          // throw error;
          //Backend returns unsuccessful response codes such as 404, 500 etc.		
          // console.log('--------------' + JSON.stringify(err));
          this.mailSendState = false;
          this.errorObject = err.error;
          this.erooroMessageForBadCredentials = this.errorObject.msg;
          if (err.status == 400) {
            this.erooroMessageForBadCredentials = this.errorObject.msg;
          } else if (err.status == 404) {
            this.erooroMessageForBadCredentials = 'server : Not found'
          } else if (err.status == 401) {
            this.erooroMessageForBadCredentials = 'server : You are unauthorized'
          } else if (err.status == 415) {
            this.erooroMessageForBadCredentials = 'server : unsupported media type'
          } else if (err.status == 409) {
            this.erooroMessageForBadCredentials = this.erooroMessageForBadCredentials;
          } else {
            this.erooroMessageForBadCredentials = 'Server error'
          }
          if (this.erooroMessageForBadCredentials == "Your email is not verified. Please check your mailbox.") {
            this._authService.setModalMessage(this.erooroMessageForBadCredentials);
            this.modalService.open(UpdatedForgetDialogComponent, { windowClass: 'global-custom-class' })
            return false
          }
          if (this.erooroMessageForBadCredentials == "Your email could not be verified. Verification link expired.") {
            this._authService.setModalMessage(this.erooroMessageForBadCredentials);
            this.modalService.open(UpdatedForgetDialogComponent, { windowClass: 'global-custom-class' })
            return false
          }
          this._authService.setModalMessage(this.erooroMessageForBadCredentials);
          this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
          // this.alertService.error(this.erooroMessageForBadCredentials);
        }
      );
  }

  redirect(u: string) {
    this.router.navigate(['./' + u + '']);
  }

  incorrectEmailId: boolean = true;
  validateEmailId(email) {
    let emaiId = email.target.value;
    if (!emaiId) return false;
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;
    this.incorrectEmailId = re.test(emaiId);
  }
}
