import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { SharedModule } from '../shared/shared.module';
import { NavbarComponent } from './topbar/navbar/navbar.component';
import { ProfileModalComponent } from './topbar/profile-modal/profile-modal.component';
import { FeedbackModalComponent } from './topbar/feedback-modal/feedback-modal.component';
import { MultisiteService } from '../multi-site/multisite.service';



@NgModule({
  declarations: [
    TopbarComponent,
    NavbarComponent,
    ProfileModalComponent,
    FeedbackModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    TopbarComponent
  ],
  providers: [MultisiteService]
})
export class HeaderModule { }
