import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SiteList } from 'src/app/models/site-list';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SiteListService {
  sites: Observable<SiteList[]>;
  private _sites: BehaviorSubject<SiteList[]>;
  private baseUrl: string;
  siteListCount = new Subject<any>();
  registeredSiteListCount = new Subject<any>();
  private dataStore: {
    sites: SiteList[]
  };

  siteChange: Observable<string>;
  private _siteChange: BehaviorSubject<string>;

  private prevSite: string;

  private siteListVisibilitySource = new BehaviorSubject(false);
  currentSiteListVisibilityState = this.siteListVisibilitySource.asObservable();

  constructor(private http: HttpClient) {
    this.baseUrl = ''; //Base URL + site list end point
    this.dataStore = { sites: [] };
    
    this._sites = <BehaviorSubject<SiteList[]>>new BehaviorSubject([]);
    this.sites = this._sites.asObservable();

    this._siteChange = <BehaviorSubject<string>>new BehaviorSubject(null);
    this.siteChange = this._siteChange.asObservable();
    
  }

  changeSiteListVisibilityState(state: boolean) {
    this.siteListVisibilitySource.next(state);
  }

  getAllSiteList(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}sites/all`).pipe(map(data => {
      this.siteListCount.next(data.list)
      return data;
    }));
  };

  getAllSiteListForUser(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}sites/administrator`).pipe(map(data => {
      this.siteListCount.next(data.list)
      return data;
    }));
  };

  getCLDeviceStatus(siteName): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}config/systemsettings/${siteName}`).pipe(map(data => {
      this.siteListCount.next(data.list)
      return data;
    }));
  };

  getMultisiteList(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}sites/multisites`).pipe(map(data => {
       //this.registeredSiteListCount.next(data.list)
      return data;
    }));
  };

  getMapPositionsBysiteList(): Observable<any> {
    return this.http.get<any>(`sites/map-sites`).pipe(map(data => {
       //this.registeredSiteListCount.next(data.list)
      return data;
    }));
  };

  emptyUserSelectedSite(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}clearselectedsite`).pipe(map(data => {
      return data;
    }));
  }

  publishSiteChange(siteName) {
    this._siteChange.next(siteName);
  }

  set prevSelectSite(prevSite: string){
    this.prevSite = prevSite;
  }

  get prevSelectSite(): string{
    return this.prevSite;
  }
  getFirmwareDetailData(siteName: string): Observable<any> {
    let siteObj = {
      siteName: siteName,
    };
    const headers = new HttpHeaders()
      .set(
        "Cache-Control",
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
      )
      .set("Pragma", "no-cache")
      .set("Expires", "0");
    return this.http
      .get<any>(`sites/site-header/${siteName}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  doGetSiteData(): Observable<any> {
    return this.http.get<any>(`sitetable`)
      .pipe(map(data => {
        return data;
      }));
  }
}
