/**
 * @author Anuj Grover
 * @email anuj.grover@non.se.com |  SESA625719
 * @create date 2021-07-22 17:34:45
 * @modify date 2021-07-22 17:34:45
 * @desc [Login Sign up forgot poassword related module]
 */
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginSignupRoutingModule } from './login-signup.routing';
import { SharedModule } from '../shared/shared.module';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ValidateSignUpComponent } from './validate-sign-up/validate-sign-up.component';

@NgModule({
  declarations: [LoginComponent, SignupComponent, ForgotPasswordComponent, NewPasswordComponent, ValidateSignUpComponent],
  imports: [
    RouterModule,
    LoginSignupRoutingModule,
    //* Shared Modules
    SharedModule,
  ]
})
export class LoginSignupModule { }
