export class FirmwareForceUpgradeList {
  id: string;
  devices: Array<FirmwareForceUpgradeDevices>;
  enableUpgradeSection: boolean;
  enableUpgrade: boolean;
  isUpgradeInProgress: boolean;
  isUpgradeStarted: boolean;
  constructor(
    id: string,
    devices: Array<FirmwareForceUpgradeDevices>,
    enableUpgradeSection: boolean,
    enableUpgrade: boolean,
    isUpgradeInProgress: boolean,
    isUpgradeStarted: boolean
  ) {
    this.id = id;
    this.devices = devices;
    this.enableUpgradeSection = enableUpgradeSection;
    this.enableUpgrade = enableUpgrade;
    this.isUpgradeInProgress = isUpgradeInProgress;
    this.isUpgradeStarted = isUpgradeStarted;
  }
}

export class FirmwareForceUpgradeDevices {
  name: string;
  type: string;
  id: string;
  fga: string;
  version: string;
  build: string;
  progressPrecent: number;
  progressStatus: string;
  progressType: string;
  constructor(
    name: string,
    type: string,
    id: string,
    fga: string,
    version: string,
    build: string,
    progressPercent: number,
    progressStatus: string,
    progressType: string
  ) {
    this.name = name;
    this.type = type;
    this.id = id;
    this.fga = fga;
    this.version = version;
    this.build = build;
    this.progressPrecent = progressPercent;
    this.progressStatus = progressStatus;
    this.progressType = progressType;
  }
}

export class SuperAdminUpgradeFirmwareSettings {

  deviceType: string;
	isLatesVersion: boolean;
	customUpgradeVersion: string;
	lastVerion: string;
  lastBuid: string;
  lastInstanceId: string;
  SiteName: string;
  
  constructor(
    deviceType: string,
    isLatesVersion: boolean,
    customUpgradeVersion: string,
    lastVerion: string,
    lastBuid: string,
    lastInstanceId: string,
    SiteName: string
  ) {
    this.deviceType = deviceType;
    this.isLatesVersion = isLatesVersion;
    this.customUpgradeVersion = customUpgradeVersion;
    this.lastVerion = lastVerion;
    this.lastBuid = lastBuid;
    this.lastInstanceId = lastInstanceId;
    this.SiteName = SiteName;
  }
}