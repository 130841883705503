/**
 * @author SESA545946 - Brijesh shakya
 * @email Brijesh.Shakya@non.se.com
 * @create date 2021-07-27 17:31:38
 * @modify date 2021-08-25 17:31:38
 * @desc This service designed only for handling authentication, obtain the token & refreshing from server
 * This also required for storing & retrieving user specific data from localstorage
 */
import { Injectable, Pipe, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse
} from '@angular/common/http'
import { map, delay } from 'rxjs/operators'
// import { AlertService } from 'src/app/services/alert.service';
import { Observable, of, Subject, BehaviorSubject, throwError } from 'rxjs'
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage'
import { Constants } from '../shared/constants'
import { LoginRequest } from '../models/login'
import { Content } from '@angular/compiler/src/render3/r3_ast'
import { SimpleCrypt } from 'ngx-simple-crypt'
import { APPDATA } from '../shared/data-values/application.properties'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
// import { ErrorDialogComponent } from '../pages/common-page/error-dialog/error-dialog.component';
import { AccessObject } from '../models/login-object'
// import { AppSettings } from '../helpers/AppSettings';
import * as CryptoJS from 'crypto-js'
// import { SuccessDialogComponent } from '../pages/common-page/success-dialog/success-dialog.component';
// import { AuthErrorDialogComponent } from '../pages/common-page/error-dialog/auth-error-dialog.component';
import { PushNotificationsService } from 'ng-push'
import { GatewayLoginBaseKeys } from '../shared/data-values/firmware-enum'
import { GatewayAuthRequest } from '../models/gateway-auth-request'
import { FormGroup } from '@angular/forms'
import { API_URLS } from '../shared/api_urls'
// import { SuperAdminSettingsService } from './super-admin-settings.service';

@Injectable()
export class AuthService {
  public timeInterval!: NodeJS.Timer
  public timer = 0
  public countDownTimer = new BehaviorSubject<number>(0)
  // Assuming this would be cached somehow from a login call.
  downloadReportStarts = new Subject<any>()
  updateHeaderAlarm = new Subject<any>()
  headerAlarmCount = new Subject<any>()
  public authTokenStale: string = 'stale_auth_token'
  conextInsightUserTypeTab = new Subject<any>()
  deviceListCount = new Subject<any>()
  public authTokenNew: string = 'new_auth_token'
  public currentToken: string
  public activeRefreshToken: string | undefined
  public loggedInUserName: string | undefined
  public delayTime: string | undefined
  private simpleCrypt = new SimpleCrypt()
  private appData = APPDATA
  notifyLogout: Observable<string>
  private _notifyLogout: BehaviorSubject<string>
  apiErrorsSource = new BehaviorSubject<any>(null)
  public isLogout: boolean
  // For notification purpose of user.
  public sessionTimer = new Subject<any>()
  public forceFirmwareUpgradeStatus = new Subject<any>()
  public flushSession = new Subject<boolean>()
  conextInsightUserType = new Subject<any>()
  constructor(
    private _pushNotifications: PushNotificationsService,
    private router: Router,
    private http: HttpClient,
    // private alertService: AlertService,
    private modalService: NgbModal,
    private localStorage: LocalStorageService // private superAdminService: SuperAdminSettingsService
  ) {
    this.currentToken = this.authTokenStale
    this.isLogout = false
    this._notifyLogout = <BehaviorSubject<string>>(
      (<unknown>new BehaviorSubject(null))
    )
    this.notifyLogout = this._notifyLogout.asObservable()
  }

  apiErrorsSource$ = this.apiErrorsSource.asObservable()
  emitApiError(apiName, apiError) {
    const newObj = { api: apiName, error: apiError }
    this.apiErrorsSource.next(newObj)
  }

  publishLogout(action: string) {
    this._notifyLogout.next(action)
  }

  getAuthToken() {
    return this.currentToken
  }

  disableParamSelection(value: any) {
    this.downloadReportStarts.next(value)
  }

  updateHeaderAlarms(value: any) {
    this.updateHeaderAlarm.next(value)
  }

  noHeaderAlarms(value: any) {
    this.headerAlarmCount.next(value)
  }

  refreshToken(): Observable<string> {
    /*
            The call that goes in here will use the existing refresh token to call
            a method on the oAuth server (usually called refreshToken) to get a new
            authorization token for the API calls.
        */
    // const params = new URLSearchParams();
    // params.append('refreshToken', this.activeRefreshToken);
    // params.append('scope', 'all');
    var params = { refreshToken: this.getRefreshToken() }
    // const headers = new HttpHeaders().set(Constants.contentType, Constants.applicationUrlEncoder);
    return this.http.post<any>('refresh', params).pipe(
      map(data => {
        // login successful if there's a jwt token in the response
        return data.msg
      })
    )
    // this.http.post(
    //     'refresh',
    //     params)
    //     .pipe(map(res => res))
    //     .subscribe(
    //         data => this.saveRefreshToken(data),
    //         err => {
    //             console.log('EEEEEEEERRRRRRRRRRRRRRRRRROOOOOOOOOOOOORRRRRRRRRRRR Error thrown from URL : ' + JSON.stringify(err));
    //             if (err) {
    //                 this.spinner.hide();
    //                 this.router.navigate(['/login']);
    //                 let hasOpenedModal = this.modalService.hasOpenModals();
    //                 if (hasOpenedModal) {
    //                     this.modalService.dismissAll();
    //                 }
    //                 // this.alertService.error('Authentication Fail');
    //                 // setTimeout(() => {
    //                 //     this.modalService.open(AuthErrorDialogComponent, { windowClass: 'global-custom-class' });
    //                 // }, 1000);
    //                 // return throwError(err);
    //             }
    //             // alert( 'Unable to login due to ' + err.error.error_description);
    //         }
    //     );
    // this.currentToken = this.authTokenNew;
    // return of(this.authTokenNew).pipe(delay(1400));
  }

  getUserDetail(username: any) {
    return this.http.get(`user?username=${username}`)
  }

  handleLogin(credentials: LoginRequest): Observable<any> {
    return this.http.post<any>('ci2login', JSON.stringify(credentials)).pipe(
      map(data => {
        // login successful if there's a jwt token in the response
       
        return data
      })
    )
  }

  handleDemoLogin(): Observable<any> {
    return this.http.get<any>('demologin').pipe(
      map(data => {
        // login successful if there's a jwt token in the response
        
        return data
      })
    )
  }

  // refreshTheToken() {
  //     setTimeout(() => {
  //         this.refreshToken();
  //     },
  //         1740000);
  // }

  saveToken(token: {
    userType: any
    access_token: any
    userrole: any
    refresh_token: string
    selectedSite: string
    firstName: any
    lastName: any
    expires_in: string
    totalRegisteredSites: string
  }) {
    // intiate the token refreshment
    this.setTotalRegisteredSites(token.totalRegisteredSites)
    this.localStorage.store('ci4', token.access_token)
    // this.localStorage.store('refresh_token', token.refresh_token);
    this.setAccessToken(token.access_token)
    this.setUserRole(token.userrole)
    this.setCIUserType(token.userType)
    this.setRefreshToken(token.refresh_token)
    //Currently saving loggedin user name in localstorage. Bad practice of storing User name at client, need to be changed.
    // this.setLoggedInUser(token.uid);
    this.setSelectedSite(token.selectedSite)
    this.setUserRole(token.userrole)
    this.setLoggedInUserFirstname(token.firstName)
    // if (token.lastName == "" || token.lastName == null || token.lastName == undefined) {
    //     let lname = null;
    //     this.setLoggedInUsersLastname(null);
    // } else {
    // }
    this.setLoggedInUsersLastname(token.lastName)
    this.activeRefreshToken = token.refresh_token
    this.delayTime = token.expires_in
    let loggedInUsername = this.getLoggedInUsername()
    this.loggedInUserName =
      loggedInUsername.charAt(0).toUpperCase() + loggedInUsername.slice(1)
    // if (token.selectedSite == '')
    this.setFirmwareUpgradeContext("loggedIn")
    this.router.navigate(['/dashboard'])
  }

  getCIUserByRole(siteName: string): Observable<any> {
    const params = new HttpParams().set('siteName', siteName)
    const url = 'ci/userrole'
    return this.http.get<any>(url, { params })
  }

  saveRefreshToken(token: {
    refresh_token: string
    access_token: any
    expires_in: string
  }) {
    // intiate the token refreshment
    // this.localStorage.store('access_token', token.access_token);
    this.setRefreshToken(token.refresh_token)
    this.setAccessToken(token.access_token)
    // this.localStorage.store('refresh_token', token.refresh_token);
    this.activeRefreshToken = token.refresh_token
    this.delayTime = token.expires_in
    // this.refreshTheToken();
  }

  isAuthenticated() {
    const promise = new Promise((resolve, reject) => {
      resolve(this.checkCredentials()) // checking if the access token exists
    })
    return promise
  }

  // isForceUpgrade() {
  //     const promise = new Promise(
  //         (resolve, reject) => {
  //             resolve(this.superAdminService.hasForceUpgrade); // checking if the access token exists
  //         }
  //     );
  //     return promise;
  // }

  checkCredentials() {
    if (!this.localStorage.retrieve('ci4')) {
      return false
    }
    return true
  }

  logout() {
    // setTimeout(() => {
    //     this.notify();
    // }, 1500);
    this.logoutApiCall().subscribe(
      response => {
      },
      err => {
      }
    )
    this.isLogout = true
    this.publishLogout(Constants.LOGOUT)
    let hasOpenedModal = this.modalService.hasOpenModals()
    if (hasOpenedModal) {
      this.modalService.dismissAll()
    }
    this.sessionTimer.next(undefined)
    // location.assign('')
    // this.router.navigate(['login']);
    this.localStorage.clear()
    location.replace("")
    // setTimeout(() => {
    //   location.reload()
    // }, 230)
  }

  logoutApiCall(): Observable<any> {
    return this.http.get(`${API_URLS.LOGOUT_API}`)
  }

  private loginDataResponse: AccessObject | undefined
  getUserCredential(loginInfo: LoginRequest): AccessObject {
    const username = this.encryptLoginCred(loginInfo.username)
    const password = this.encryptLoginCred(loginInfo.password)
    this.loginDataResponse = new AccessObject(username, password)
    return this.loginDataResponse
  }

  encryptLoginCred(valueToEncrypt: string): string {
    var utf8Stringified = CryptoJS.enc.Utf8.parse(valueToEncrypt)
    let rkEncryptionKey = CryptoJS.enc.Base64.parse('u/Gu5posvwDsXUnV5Zaq4g==')
    let rkEncryptionIv = CryptoJS.enc.Base64.parse('5D9r9ZVzEYYgha93/aUK2w==')
    var encrypted = CryptoJS.AES.encrypt(
      utf8Stringified.toString(),
      rkEncryptionKey,
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: rkEncryptionIv
      }
    )
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
  }

  //Encrypt gateway login details
  encryptGatewayCredentials(
    gatewayCredentialsForm: FormGroup
  ): GatewayAuthRequest {

    let enKey = this.generateBase64Key(GatewayLoginBaseKeys.KEY)
    let enIv = this.generateBase64Key(GatewayLoginBaseKeys.IV)

    let cryptoEnctyptedKey = CryptoJS.enc.Base64.parse(enKey)
    let cryptoEnctyptedIv = CryptoJS.enc.Base64.parse(enIv)

    let utf8StringifiedUsername = CryptoJS.enc.Utf8.parse(
      gatewayCredentialsForm.value.username
    )
    let utf8StringifiedPassword = CryptoJS.enc.Utf8.parse(
      gatewayCredentialsForm.value.password
    )

    let cryptoEncryptedUsername = CryptoJS.AES.encrypt(
      utf8StringifiedUsername.toString(),
      cryptoEnctyptedKey,
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: cryptoEnctyptedIv
      }
    )
    let cryptoEncryptedPassword = CryptoJS.AES.encrypt(
      utf8StringifiedPassword.toString(),
      cryptoEnctyptedKey,
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: cryptoEnctyptedIv
      }
    )

    let ecnryptedUsername = cryptoEncryptedUsername.ciphertext.toString(
      CryptoJS.enc.Base64
    )
    let encryptedPassword = cryptoEncryptedPassword.ciphertext.toString(
      CryptoJS.enc.Base64
    )
    let selectedSite = this.getSelectedSite()

    return new GatewayAuthRequest(
      selectedSite,
      ecnryptedUsername,
      encryptedPassword
    )
  }

  //Generate encryption key
  private generateBase64Key(key: string): string {
    let btoaKey = btoa(key)
    return btoaKey
  }

  // ng-push

  notify() {
    //our function to be called on click
    let options = {
      //set options
      body: `Successfully Logged Out`,
      icon: 'assets/img/icons/nav-logo.jpg' //adding an icon
    }
    this._pushNotifications.create('InsightCloud', options).subscribe(
      //creates a notification
      (res: any) => console.log(res),
      (err: any) => console.log(err)
    )
  }

  isUsernameExists(username: string): Observable<any> {
    const url = 'userexist/' + username
    return this.http.get(url)
  }

  getBenefitsData(requestParam: any): Observable<any> {
    const url = 'benefits'
    return this.http.post(url, requestParam)
  }

  // isSiteExists(siteName): Observable<any> {
  //     const url = 'sites/siteExist/' + siteName;
  //     return this.http.get(url).pipe(
  //       catchError(this.handleError)
  //     );
  //   }

  setLoggedInUserFirstname(name: string) {
    let key = this.appData[1].cnxtId
    let encodedString = this.simpleCrypt.encode(key, name)
    // console.log('key--------------'+key+'------setLoggedInUserFirstname-------------------'+encodedString);
    this.localStorage.store(key, encodedString)
  }

  setLoggedInUsersLastname(name: string) {
    let key = this.appData[8].cnxtId
    let encodedString = this.simpleCrypt.encode(key, name)
    // console.log('key--------------'+key+'------setLoggedInUsersLastname-------------------'+encodedString);
    this.localStorage.store(key, encodedString)
  }

  setTotalRegisteredSites(data: any) {
    let key = this.appData[19].cnxtId
    let encodedString = this.simpleCrypt.encode(key, data)
    this.localStorage.store(key, data)
  }

  getTotalRegisteredSites() {
    let key = this.appData[19].cnxtId
    let retriveKey = this.localStorage.retrieve(key)
    if (
      key !== null &&
      key !== undefined &&
      retriveKey !== null &&
      retriveKey !== undefined
    ) {
      let decodedString = this.simpleCrypt.decode(key, retriveKey)
      return retriveKey
    } else {
      retriveKey = 'error'
      return retriveKey
    }
    // return this.localStorage.retrieve('selected_site');
  }

  setLoggedUsername(username: string) {
    let key = this.appData[6].cnxtId
    let encodedString = this.simpleCrypt.encode(key, username)
    // console.log('key--------------' + key + '------setLoggedUsername-------------------' + encodedString);
    this.localStorage.store(key, encodedString)
  }

  setDeletetedSiteId(siteId: any) {
    this.localStorage.store('delSiteId', siteId)
  }

  setResend(isSent: string) {
    let key = this.appData[16].cnxtId
    let encodedString = this.simpleCrypt.encode(key, isSent)
    this.localStorage.store(key, encodedString)
  }

  getResend() {
    let key = this.appData[16].cnxtId
    let decodedString = this.simpleCrypt.decode(
      key,
      this.localStorage.retrieve(key)
    )
    return decodedString
  }

  navchange: EventEmitter<number> = new EventEmitter()
  emitNavChangeEvent(number: number | undefined) {
    this.navchange.emit(number)
  }

  getNavChangeEmitter() {
    return this.navchange
  }

  setSelectedSite(siteName: string) {
    if (siteName == '') siteName = 'emptyWithNoSite'
    let key = this.appData[2].cnxtId
    let encodedString = this.simpleCrypt.encode(key, siteName)
    // console.log('key--------------'+key+'------setSelectedSite-------------------'+encodedString);
    this.localStorage.store(key, encodedString)
  }

  // setCurrentTimerForMobile(count) {
  //   let key = this.appData[21].cnxtId;
  //   let encodedString = this.simpleCrypt.encode(key, count);
  //   this.localStorage.store(key, encodedString);
  // }

  // getCurrentTimerForMobile() {
  //   try {
  //     let key = this.appData[21].cnxtId;
  //     let decodedString = this.simpleCrypt.decode(key, this.localStorage.retrieve(key));
  //     console.log('key--------------' + key + '------setCreateSiteSuccessMsg-------------------' + decodedString);
  //     return decodedString;
  //   } catch (e) {

  //   }
  // }

  setCurrentTimerForMobile(count) {
    this.localStorage.store('ci22', count)
  }

  getCurrentTimerForMobile() {
    try {
      return this.localStorage.retrieve('ci22')
    } catch (e) { }
  }

  goOpenUpdateDevicePage() {
    this.localStorage.store('DPG', 1)
  }

  doGetUpdateDevicePage() {
    return this.localStorage.retrieve('DPG')
  }

  // setSelectedSiteId(id) {
  //     this.localStorage.store('selected_id', id);
  // }

  setCreateSiteSuccessMsg(msg: string) {
    let key = this.appData[5].cnxtId
    let encodedString = this.simpleCrypt.encode(key, msg)
    // console.log('key--------------' + key + '------setCreateSiteSuccessMsg-------------------' + encodedString);
    this.localStorage.store(key, encodedString)
  }

  setUserRole(role: string) {
    
    let key = this.appData[4].cnxtId
    let encodedString = this.simpleCrypt.encode(key, role)
    this.localStorage.store(key, encodedString)
  }

  setSelectedSiteUrn(urn: string) {
    let key = this.appData[9].cnxtId
    let encodedString = this.simpleCrypt.encode(key, urn)
    this.localStorage.store(key, encodedString)
  }

  setModalMessage(msg: string) {
    let key = this.appData[10].cnxtId
    let encodedString = this.simpleCrypt.encode(key, msg)
    this.localStorage.store(key, encodedString)
  }

  setTimeoutTime(lstdt: any) {
    this.localStorage.store('lstactm', lstdt)
  }

  getTimeoutTime() {
    return this.localStorage.retrieve('lstactm')
  }

  getModalMessage() {
    let key = this.appData[10].cnxtId
    let decodedString = this.simpleCrypt.decode(
      key,
      this.localStorage.retrieve(key)
    )
    // console.log('key--------------' + key + '------getModalMessage after decoding ------ ' + decodedString);
    return decodedString
  }

  getRefreshToken() {
    // let key = this.appData[7].cnxtId;
    // let decodedString = this.simpleCrypt.decode(key, this.localStorage.retrieve(key));
    // // console.log('key--------------' + key + '------getRefreshToken after decoding ------ ' + decodedString);
    // return decodedString;
    return this.localStorage.retrieve('ci8')
  }

  // getLoggedInUser() {
  //     return this.localStorage.retrieve('uid');
  // }

  getLoggedInUsername() {
    let key = this.appData[6].cnxtId
    if (this.localStorage.retrieve(key)) {
      let decodedString = this.simpleCrypt.decode(
        key,
        this.localStorage.retrieve(key)
      )
      // console.log('key--------------' + key + '------setLoggedUsername after decoding ------ ' + decodedString);
      return decodedString
    } else {
      return null
    }
  }

  setAccessToken(token: string) {
    let key = this.appData[0].cnxtId
    let encodedString = this.simpleCrypt.encode(key, token)
    // console.log('key--------------' + key + '------setAccessToken-------------------' + encodedString);
    this.localStorage.store(key, encodedString)
  }

  setRefreshToken(token: any) {
    // let key = this.appData[7].cnxtId;
    // let encodedString = this.simpleCrypt.encode(key, token);
    // // console.log('key--------------' + key + '------setRefreshToken-------------------' + encodedString);
    this.localStorage.store('ci8', token)
  }

  setLoginStatus(status: string) {
    let key = this.appData[11].cnxtId
    let encodedString = this.simpleCrypt.encode(key, status)
    this.localStorage.store(key, encodedString)
  }

  getLoginStatus() {
    let key = this.appData[11].cnxtId
    let retriveKey = this.localStorage.retrieve(key)
    let decodedString
    if (retriveKey != null) {
      decodedString = this.simpleCrypt.decode(key, retriveKey)
    } else {
      decodedString = null
    }
    return decodedString
  }

  getAccessToken() {
    return this.localStorage.retrieve('ci4')
  }

  // getAccessToken() {
  //     let key = this.appData[0].cnxtId;
  //     let decodedString = this.simpleCrypt.decode(key, this.localStorage.retrieve(key));
  //     console.log('key--------------'+key+'------getAccessToken after decoding ------ ' + decodedString);
  //     return decodedString;
  // }

  getSelectedSite() {
    let key = this.appData[2].cnxtId
    let decodedString

    if (this.localStorage.retrieve(key)) {
      decodedString = this.simpleCrypt.decode(
        key,
        this.localStorage.retrieve(key)
      )
      // console.log('key--------------' + key + '------getSelectedSite after decoding ------ ' + decodedString);
      if (decodedString == 'emptyWithNoSite') {
        decodedString = ''
      }
    }
    return decodedString
    // return this.localStorage.retrieve('selected_site');
  }

  setIsDashboardRouting(value: any) {
    this.localStorage.store('mdpr', value)
  }

  getIsDashboardRouting() {
    return this.localStorage.retrieve('mdpr')
  }

  setInverterWidgets(value: any) {
    this.localStorage.store('iitewt', value)
  }

  getInverterWidgets() {
    return this.localStorage.retrieve('iitewt')
  }

  setSelectedPowerflowDevice(device: string) {
    let key = this.appData[18].cnxtId
    let encodedString = this.simpleCrypt.encode(key, device)
    // console.log('key--------------'+key+'------setSelectedSite-------------------'+encodedString);
    this.localStorage.store(key, encodedString)
  }

  getSelectedPowerflowDevice() {
    let key = this.appData[18].cnxtId
    let decodedString = this.simpleCrypt.decode(
      key,
      this.localStorage.retrieve(key)
    )
    // console.log('key--------------' + key + '------getSelectedSite after decoding ------ ' + decodedString);
    return decodedString
    // return this.localStorage.retrieve('selected_site');
  }

  // getDeletetedSiteId() {
  //     return this.localStorage.retrieve('delSiteId');
  // }

  getCreateSiteSuccessMsg() {
    let key = this.appData[5].cnxtId
    let decodedString
    // console.log('key--------------' + key + '------getCreateSiteSuccessMsg after decoding ------ ' + decodedString);
    let value = this.localStorage.retrieve(key)
    if (value == undefined || value == null || value == '') {
      return ''
    } else {
      decodedString = this.simpleCrypt.decode(key, value)
    }
    return decodedString
  }

  // getSelectedSiteId() {
  //     return this.localStorage.retrieve('selected_id');
  // }

  getLoggedInUserFirstname() {
    let key = this.appData[1].cnxtId
    let decodedString = this.simpleCrypt.decode(
      key,
      this.localStorage.retrieve(key)
    )
    // console.log('key--------------'+key+'------getLoggedInUserFirstname after decoding ------ ' + decodedString);
    return decodedString
  }

  getLoggedInUsersLastname() {
    let key = this.appData[8].cnxtId
    let decodedString = this.simpleCrypt.decode(
      key,
      this.localStorage.retrieve(key)
    )
    // console.log('key--------------'+key+'------getLoggedInUsersLastname after decoding ------ ' + decodedString);
    if (
      decodedString == 'ci9' ||
      decodedString == '' ||
      decodedString == '' ||
      decodedString == null ||
      decodedString == undefined
    ) {
      decodedString = ''
    }
    return decodedString
  }

  getSelectedSiteUrn() {
    let key = this.appData[9].cnxtId
    let decodedString = this.simpleCrypt.decode(
      key,
      this.localStorage.retrieve(key)
    )
   
    return decodedString
  }

  getUserRole() {
    let key = this.appData[4].cnxtId
    let decodedString = this.simpleCrypt.decode(
      key,
      this.localStorage.retrieve(key)
    )
   
    return decodedString
  }

  setFirmwareUpgradeContext(data) {
    let key = this.appData[22].cnxtId
    let encodedString = this.simpleCrypt.encode(key, data)
    // console.log('key--------------'+key+'------setSelectedSite-------------------'+encodedString);
    this.localStorage.store(key, encodedString)
  }

  getFirmwareUpgradeContext() {
    let key = this.appData[22].cnxtId
    let decodedString = this.simpleCrypt.decode(
      key,
      this.localStorage.retrieve(key)
    )

    return decodedString
  }

  doSetFirmwareUpgradeStatus(data) {
    let key = this.appData[23].cnxtId
    let encodedString = this.simpleCrypt.encode(key, data)
    // console.log('key--------------'+key+'------setSelectedSite-------------------'+encodedString);
    this.localStorage.store(key, encodedString)
  }

  doGetFirmwareUpgradeStatus() {
    let decodedString = ''
    let key = this.appData[23].cnxtId
    let xyz = this.localStorage.retrieve(key)
    if (!xyz || xyz == '') {
      return ''
    }
    decodedString = this.simpleCrypt.decode(
      key, xyz
    )
    
    return decodedString
  }

  getUserProfile(siteName: string) {
    let sendUsername = {
      siteName: siteName
    }
    return this.http.post<any>('userProfile', sendUsername).pipe(
      map(data => {
        // console.log('sendUsername base on site --- ' + JSON.stringify(sendUsername));
        return data
      })
    )
  }

  updateUserProfile(updateObj: any) {
    return this.http.post<any>('user/update', JSON.stringify(updateObj)).pipe(
      map(data => {
        // console.log('User Profile --- ' + JSON.stringify(data));
        return data
      })
    )
  }

  updateTouStatus(){
    return this.http.post<any>('updatetou', {
      touAccepted: "true"
    })
  }

  updateUserPwd(oldPassword: string, newPassword: string) {
    const encOld = this.encryptLoginCred(oldPassword)
    const encNew = this.encryptLoginCred(newPassword)
    return this.http.post<any>('password', {
      oldPassword: encOld,
      newPassword: encNew
    })
  }

  getDeviceListData(siteName: String): Observable<any> {
    let siteObj = {
      siteName: siteName
    }
    return this.http.post<any>(API_URLS.DEVICE_DETAILS, siteObj).pipe(
      map(data => {
        // console.log('deviceList  response  from TSI --- ' + JSON.stringify(data));
        return data
      })
    )
  }

  getDeviceTypeForESAData(
    siteName: String,
    showHistoric?: boolean
  ): Observable<any> {
    let siteObj = {
      siteName: siteName
    }
    if (showHistoric) siteObj['showHistoric'] = showHistoric
    return this.http.post<any>(API_URLS.DEVICE_TYPE, siteObj).pipe(
      map(data => {
        const listData = data.response
        // alert(listData.length)
        this.deviceListCount.next(listData.length)
        return data
      })
    )
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error)
  }

  resendConfirmationMail(token: string, userId: string): Observable<any> {
    const params = new HttpParams().set('userId', userId).set('token', token)
    // this.router.navigate(['/login']);
    return this.http.get<any>('reVerifyEmail', { params })
  }

  /**
   * Gets count down timer
   */
  public getCountDownTimer() {
    this.sessionTimer.subscribe((data: any) => {
      if (data) {
        clearInterval(this.timeInterval)
        this.timer = 5 * 60
        this.timeInterval = setInterval(() => {
          if (this.timer > 0) {
            this.countDownTimer.next(this.timer--)
          } else {
            clearInterval(this.timeInterval)
            this.flushSession.next(true)
            this.countDownTimer.next(0)
          }
        }, 1000)
      } else {
        clearInterval(this.timeInterval)
        this.countDownTimer.next(0)
      }
    })
  }

  /**
   * Sets key
   * @param name
   * @param index
   */
  public setKey(name: string, index: number) {
    let key = this.appData[index].cnxtId
    let encodedString = this.simpleCrypt.encode(key, name)
    this.localStorage.store(key, encodedString)
  }

  /**
   * Gets key value
   * @param index
   * @returns decodedString
   */
  public getKeyValue(index: number) {
    let key = this.appData[index].cnxtId
    // let encodedString = this.simpleCrypt.encode(key, name);
    if (this.localStorage.retrieve(key)) {
      let decodedString = this.simpleCrypt.decode(
        key,
        this.localStorage.retrieve(key)
      )
      return decodedString
    }
    return undefined
  }

  /**
   * Removes key
   * @param index
   */
  public removeKey(index: number) {
    this.localStorage.clear(this.appData[index].cnxtId)
  }

  public removeTimerKeyForMobile() {
    this.localStorage.clear('ci22')
  }

  // All the references to this method has to be removed

  getCIUserType() {
    let key = this.appData[12].cnxtId
    let decodedString = this.simpleCrypt.decode(
      key,
      this.localStorage.retrieve(key)
    )
    return decodedString
  }

  setCIUserType(name) {
    let key = this.appData[12].cnxtId
    let encodedString = this.simpleCrypt.encode(key, name)
    
    this.localStorage.store(key, encodedString)
  }

  getConextInsightUserType(siteName: string): Observable<any> {
    const params = new HttpParams().set('siteName', siteName)
    const url = 'ci/usertype'
    // alert("called")
    return this.http.get<any>(url, { params }).pipe(
      map(data => {
        this.setCIUserType(data.list)
        this.conextInsightUserType.next(data.list)
        return data
      })
    )
  }
  updateConextInsightUserType(data) {
    this.setCIUserType(data)
    this.conextInsightUserType.next(data)
  }
}
