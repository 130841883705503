import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { FirmwareSettingsRequest } from "../models/firmware-settings-request";

@Injectable({
  providedIn: "root",
})
export class SuperAdminSettingsService {

  private _hasForceUpgrade: boolean = false;

  constructor(private http: HttpClient) {}

  get hasForceUpgrade(): boolean{
    return this._hasForceUpgrade;
  }

  set hasForceUpgrade(value: boolean){
    this._hasForceUpgrade = value;
  }

  registerSettingDevice(deviceTypeForm, deviceTypeFile): Observable<any> {
    const url = "super-admin/devicetype/upload";
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.set("Content-Type", undefined);
    formData.append(
      "deviceTypeForm",
      new Blob([JSON.stringify(deviceTypeForm)], { type: "application/json" })
    );
    formData.append("deviceTypeFile", deviceTypeFile);
    return this.http
      .post<any>(url, formData, { headers })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  fetchDeviceTypeList(): Observable<any> {
    const url = "super-admin/devicetype/all";
    return this.http.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  removeDeviceType(data): Observable<any> {
    return this.http.post<any>("super-admin/devicetype/delete", data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  saveForceUpgradeSettings(data: FirmwareSettingsRequest): Observable<any> {
    const url = "super-admin/firmware-settings/save";
    return this.http.post<any>(url, data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateForceUpgradeSettings(data: FirmwareSettingsRequest): Observable<any> {
    const url = "super-admin/firmware-settings/update";
    return this.http.post<any>(url, data).pipe(
      map((data) => {
        return data;
      })
    );
  }

  retriveForceUpgradeSettings(): Observable<any> {
    const url = "super-admin/firmware-settings/retrive";
    return this.http.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
