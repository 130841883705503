/**
 * @author SESA552234 - Soni Kumari
 * @email Soni.kumari@non.se.com
 * @create date 2020-01-07 02:36:13
 * @modify date 2020-01-08 02:36:13
 * @desc Error dialog box as per scheneider standards
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
// import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-error-dialog',
    template: `
  <div class="modal-header">
    <h4 class="modal-title">Error</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  <div class="row">
  <ngx-spinner name="resendMailSpinner" [fullScreen]="false" size="small" color="#3dcd58" type="ball-clip-rotate"></ngx-spinner>
  <div class="col-lg-2 col-md-2 col-xl-2 col-sm-12 col-xs-12">
      <img [src]="errorModalImage" height="50" width="50" />
  </div>
      <div class="col-lg-10 col-md-10 col-xl-10 col-sm-12 col-xs-12">
      <p>{{errorMessage}}</p>
      </div>
      <br>
      <div class="col-lg-12 col-md-12 col-xl-12 col-sm-12 col-xs-12">
      <div *ngIf='registerSuccess' class='thankyouwrapper'>
      <br>
      <h6 *ngIf="!isMessageSent">Not Received e-mail ?</h6>
      <h6 *ngIf="!isMessageSent" class="hyperlink-blue hyperLink-cursor-style" (click)="resendEmailVerification()"><u>Resend email confirmation</u>
      </h6>
      <small>{{spamMessage}}</small>
      <h6 *ngIf="isMessageSent" style="color:#3dcd58;">
         {{responseMessage}}
      </h6>
      <br>
      <br> 
      <br> 
      <h6>Still facing trouble ?</h6>
      <h6 class="hyperlink-blue hyperLink-cursor-style" (click)="goToSESupportWebPortal()">Contact SE Solar Support</h6>
  </div>

      </div>
      </div>
      </div>
  <div class="modal-footer">
    <button type="button" class="btn modal-cust-btn" (click)="activeModal.close('Close click')">Close</button>
  </div>
  `,
    styles: [`.thankyouwrapper {
        text-align: center;
        color: #666666;
    }
    
    .thankyouwrapper a {
        font-weight: 500;
        color: #09c;
        text-decoration: none;
    }`]
})

export class UpdatedForgetDialogComponent implements OnInit, OnDestroy {

    resendMailConfirmationSubscription: Subscription;
    token: string;
    errorMessage: string;
    responseMessage: string = null
    registerSuccess = true;
    isMessageSent = false;
    spamMessage: string = 'Please check spam folder or firewall settings if not received'

    constructor(private _authService: AuthService, private modalService: NgbModal,
        private spinner: NgxSpinnerService, public activeModal: NgbActiveModal, private _authservice: AuthService) {

    }

    ngOnInit() {
        this.errorMessage = this._authservice.getModalMessage();
    }

    goToSESupportWebPortal() {
        window.open("https://solar.schneider-electric.com/netsuite/", "_blank");
    }

    resendEmailVerification() {
        const username = this._authservice.getLoggedInUsername();
        const token = ""
        this.spinner.show("resendMailSpinner")
        this.resendMailConfirmationSubscription = this._authservice.resendConfirmationMail(token, username).subscribe(data => {
            this.spinner.hide("resendMailSpinner")
            if (data['status'] == 'Success') {
                this.isMessageSent = true
                this.responseMessage = data['msg']
                // this._authservice.setModalMessage(data['msg']);
                // this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
            }
        }, err => {
            this.spinner.hide("resendMailSpinner")
            this.isMessageSent = false
        })

    }

    resendMail() {
        let hasOpenedModal = this.modalService.hasOpenModals();
        if (hasOpenedModal) {
            this.modalService.dismissAll();
        }
        let token = ""
        this.resendMailConfirmationSubscription = this._authservice.resendConfirmationMail(token, this._authService.getLoggedInUsername()).subscribe(data => {
            if (data['status'] == 'Success') {
                this.isMessageSent = true;
                this._authservice.setModalMessage(data['msg']);
                // this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
            } else {
                this.isMessageSent = false
            }
        }, error => {
            this.isMessageSent = false;
        })
    }


    errorModalImage: string = "assets/img/power-flow/Error_icon.png";


    ngOnDestroy(): void {
        if (this.resendMailConfirmationSubscription) this.resendMailConfirmationSubscription.unsubscribe()
    }

}
