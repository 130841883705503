import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { PerformanceService } from 'src/app/services/performance.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css'],
})
export class TermsConditionsComponent {
  pdfSource = '/assets/pdf/terms-conditions.pdf';
  @Input() showAcceptBtn: boolean = false;
  constructor(
    private authService: AuthService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private performanceService: PerformanceService
  ) {}


  downloadFile() {

    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = '/assets/pdf/terms-conditions.pdf';
    link.download = 'Terms_and_conditions.pdf';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  closeModal() {
    this.activeModal.close();
  }


  save() {
    this.authService.updateTouStatus().subscribe(response=>{
      if(response.status==='Success'){
        this.closeModal();
        this.performanceService.emitTermsConditionsEvent('accepted')

      }
      
    })
    
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass:'modalSizeFix' })
      
  }
}
