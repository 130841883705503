/**
 * @author SESA480327 - Santhosh raj
 * @email santhosh.raj@non.se.com
 * @create date 2019-07-19 17:43:06
 * @modify date 2019-07-19 17:43:06
 * @desc Any http calls will be consumed by this interceptor for validating the request and apend requied headers and access token.
 */

import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of } from "rxjs";
import { catchError, filter, take, switchMap, finalize, map } from "rxjs/operators";
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../shared/constants';
import { LocalStorageService } from "ngx-webstorage";
import { search } from "../shared/api_urls";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER: string = "Authorization";
  private token: string = "";
  private refreshTokenInProgress: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private localStorage: LocalStorageService, private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.getAccessToken() !== null) {
      this.token = this.authService.getAccessToken();
    }
    //Add application domain to endpoint
    req = this.addAppDomain(req);

    //Add content type to header
    if (!req.headers.has(Constants.contentType)) {
      req = this.addContentType(req);
    }

    //Add auth token to header
    // req = this.addAuthToken(req);
    const apiKey = search(environment.baseUrl,req.url);
    return next.handle(this.addAuthToken(req, this.authService.getAccessToken())).pipe(
      map((data: any) => {        
        if (apiKey  && data.status === 200)
          this.authService.emitApiError(apiKey, null);
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401 || error.status === 403) {
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              // switchMap(() => next.handle(this.addAuthToken(req, result)))
              switchMap(token => {
                return next.handle(this.addAuthToken(req, token));
              })
            );
          } else {
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            return this.authService.refreshToken().pipe(
              switchMap((data: any) => {
                // alert("")
                if (data) {
                  this.token = data.access_token;
                  this.localStorage.store('ci4', data.access_token);
                  this.authService.setRefreshToken(data.refresh_token);
                  this.refreshTokenSubject.next(data.access_token);
                  return next.handle(this.addAuthToken(req, data.access_token));
                }
                this.logout();
              }),
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => this.refreshTokenInProgress = false)
            );
          }
        }
        else if (error && error.status === 400 && (error.error
          && (error.error.error === Constants.invalidGrant
            || error.error.msg === Constants.oauthError))) {
          this.logout();
          return Observable.throw(error);
        }
        else {
          if (apiKey)
            this.authService.emitApiError(apiKey, error);
          return throwError(error);
        }
      })
    )
  };

  private refreshAccessToken(): Observable<any> {
    return this.authService.refreshToken();
  }

  private addAuthToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    if (!token) {
      return request;
    }

    // If you are calling an outside domain then do not add the token.
    if (request.url.includes(Constants.mapboxDomainName)) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, Constants.bearer + token)
    });
  };

  private addContentType(request: HttpRequest<any>): HttpRequest<any> {
    //NOTE: Use condition if any other content type is to be added to request

    if (request.url.includes(Constants.mapboxDomainName)) {
      this.addHeaderTypeInAzuremap(request)
      return request;
    }

    if (request.url.includes('sites/new-cloud') ||
      request.url.includes('sites/update') ||
      request.url.includes('devicetype/upload') ||
      request.url.includes('sites/documents') ||
      request.url.includes('feedback') ||
      request.url.includes('config/devicesettings/configfile/download') ||
      request.url.includes('app/user')) {
      return request;
    } else {
      return request.clone({
        headers: request.headers.set(Constants.contentType, Constants.applicationJson)
      });
    }
    // return request.clone({
    //   headers: request.headers.set(Constants.contentType, Constants.applicationJson)
    // });
  };

  private addHeaderTypeInAzuremap(request: HttpRequest<any>): HttpRequest<any> {
    request.headers.set(Constants.AccessControlAllowOrigin, '*')
    return request.clone({
      headers: request.headers.set(Constants.credentials, Constants.omit)
    });
  };

  private addAppDomain(request: HttpRequest<any>): HttpRequest<any> {
    // If you are calling an outside domain then do not add the appliaction domain.
    if (request.url.includes(Constants.mapboxDomainName)) {
      return request;
    }
    if (request.url.includes(Constants.azureMapDomainName)) {
      return request;
    }
    return request.clone({ url: environment.baseUrl + `${request.url}` });
  }

  private logout() {
    this.authService.logout();
  }
}
