export class SelectedDevicetype {
    deviceType: string;
    deviceCategory: string;
    deviceFriendlyName: string;
    deviceObjectSelected: any;

    constructor(deviceType: string, deviceCategory: string, deviceFriendlyName: string, deviceObjectSelected?: any) {
        this.deviceType = deviceType;
        this.deviceCategory = deviceCategory;
        this.deviceFriendlyName = deviceFriendlyName;
        this.deviceObjectSelected = deviceObjectSelected
    }
}