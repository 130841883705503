/**
 * @author SESA480327 - Santhosh raj
 * @email santhosh.raj@non.se.com
 * @create date 2019-11-11 11:11:07
 * @modify date 2019-11-11 11:11:07
 * @desc Multi site service
 */

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
import { ErrorFormat } from '../models/error-format';
import { MSRequest } from '../models/multisite-request';
import { MultiSiteURL } from '../shared/data-values/multi-site-enum';
import { API_URLS } from '../shared/api_urls';

@Injectable({
  providedIn: 'root'
})
export class MulitSiteService {
  private baseUrl: string;
  multiSites: Observable<string[]>;
  private _multiSites: BehaviorSubject<string[]>;
  private multisiteDataStore: {
    mulitSites: string[]
  };
  multisiteListItems: any[];
  multiSiteDateChange: Observable<string>;
  private _multiSiteDateChange: BehaviorSubject<string>;
  sitePushPopChange: Observable<string>;
  private _sitePushPopChange: BehaviorSubject<string>;
  dropDownDate: string;
  powerProductionSource: string;

  constructor(private http: HttpClient) {
    this.baseUrl = ''; //Base URL
    this.multisiteDataStore = { mulitSites: [] };
    this._multiSites = <BehaviorSubject<string[]>>new BehaviorSubject([]);
    this.multiSites = this._multiSites.asObservable();
    this.multisiteListItems = null;
    this._multiSiteDateChange = <BehaviorSubject<string>>new BehaviorSubject(null);
    this.multiSiteDateChange = this._multiSiteDateChange.asObservable();
    this._sitePushPopChange = <BehaviorSubject<string>>new BehaviorSubject(null);
    this.sitePushPopChange = this._sitePushPopChange.asObservable();
    this.dropDownDate = null;
    this.powerProductionSource = null;
  }

  publishMultiSites(siteNames) {
    this._multiSites.next(siteNames);
  }

  publishMultiSiteDateChange(dateData) {
    this._multiSiteDateChange.next(dateData);
  }

  publishSitePushPopChange(siteData) {
    this._sitePushPopChange.next(siteData);
  }

  set multisiteList(list: any[]) {
    this.multisiteListItems = list;
  }

  get multisiteList(): any[] {
    return this.multisiteListItems;
  }

  set dropDownDateData(dateData: string) {
    this.dropDownDate = dateData;
  }

  get dropDownDateData(): string {
    return this.dropDownDate;
  }

  set powerProductionSourceData(sourceValue: string) {
    this.powerProductionSource = sourceValue;
  }

  get powerProductionSourceData(): string {
    return this.powerProductionSource;
  }

  fetchMultisiteWidgetData = (data: MSRequest): Observable<MSRequest> => {
    return this.http.post<MSRequest>(API_URLS.MS, data)
      .pipe(retry(0), // retry a failed request up to 0 times
        catchError(this.handleError),
        map(data => {
          return data;
        }))
  };


  fetchMultisiteEarningWidgetData = (data: MSRequest): Observable<MSRequest> => {
    const url = MultiSiteURL.ER;
    return this.http.post<MSRequest>(url, data)
      .pipe(retry(0), // retry a failed request up to 0 times
        catchError(this.handleError),
        map(data => {
          return data;
        }))
  };
  private handleError(error: HttpErrorResponse) {
    var msg = '';
    var errorType = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      errorType = 'network';
      msg = 'Detected network slow / disconnection, please try again.';
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      errorType = 'server';
      msg = 'Settings request failed! please try again later.';
    }
    // return an observable with a user-facing error message
    return throwError(
      new ErrorFormat(errorType, msg)
    );
  };
}
