/**
 * @author sesa 523962 - Suchrita Jain
 * @email suchrita.jain@non.se.com
 * @create date 2019-06-17 11:32:10
 * @modify date 2019-06-17 11:32:10
 * @desc This componnet will check for valid user, control will come here once verification link 
 * from mail is clicked .
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResService } from 'src/app/services/res.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { ResendDialogComponent } from 'src/app/modules/shared/dialogueboxes/error-dialog/resendMailVerfication.component';
import { ErrorDialogComponent } from 'src/app/modules/shared/dialogueboxes/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from 'src/app/modules/shared/dialogueboxes/success-dialog.component';

@Component({
  selector: 'app-validate-sign-up',
  templateUrl: './validate-sign-up.component.html',
  styleUrls: ['./validate-sign-up.component.scss']
})
export class ValidateSignUpComponent implements OnInit {

  private token: string;
  private validateTokenUrl: string = 'verifyEmail?token=';

  constructor(private activatedRoute: ActivatedRoute, private resource: ResService, private modalService: NgbModal, private _authservice: AuthService,
    private router: Router) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      if (this.token !== '' && this.token !== undefined) {
        this.validateTokenUrl += this.token;
        this.validateToken();
      }
    });
  }

  validateToken() {
    this.resource.getResource(this.validateTokenUrl)
      .subscribe((data) => {
        if (data['status'] === 'Success' && data['msg'] == "Verified successfully") {
          // this.alertService.success(data['msg'], true);
          this._authservice.setModalMessage("Account Verified Successfully");
          this.modalService.open(SuccessDialogComponent, { windowClass: 'global-custom-class' });
        } else if (data['status'] === 'Failure') {
          // this.alertService.error(data['msg'], true);
          this._authservice.setModalMessage(data['msg']);
          this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
        }
        this.router.navigate(['/login']);
      }, err => {
        let errorObject = err.error;
        let errorObjectFailed = err.error;
        let errorObjectMessage = errorObject.msg;
        let signUpErrorMsgFailed = errorObjectFailed.message;
        if (errorObjectMessage == 'Your authentication link expired. Please register again.') {
          this._authservice.setModalMessage(errorObjectMessage);
          this.modalService.open(ResendDialogComponent, { windowClass: 'global-custom-class' });
        } else if (signUpErrorMsgFailed == 'message.invalidVerifyEmailToken') {
          this._authservice.setModalMessage("Please try again");
          this.modalService.open(ResendDialogComponent, { windowClass: 'global-custom-class' });
        }
        // if (signUpErrorMsg) {
        //   this._authservice.setModalMessage(signUpErrorMsg);
        //   this.modalService.open(ErrorDialogComponent, { windowClass: 'global-custom-class' });
        // }
      });
  }

  ngOnInit() {

  }

}
