<!-- <div class="modalContainer">
    <div class="modal-header">
           <h3>Terms and Condition</h3>
    </div>
    <div *ngIf="!pdfSource">Loading</div>
    <div class="modal-content" *ngIf="pdfSource">
        <pdf-viewer
        [src]="pdfSource"
        [original-size]="true"
        [show-all]="true"
        [fit-to-page]="true"
        style="width: 100%; height: 100%; box-shadow: none;overflow: auto;"
        ></pdf-viewer>
    </div>
    <div class="modal-footer">
        
        <button  type="button"
        class="btn modal-cust-btn accept-btn" 
        (click)="save()"
        >Accept</button>
    </div>
    
  
</div> -->

<div class="modalContainer">
  <div class="modal-header">
    <h4 class="modal-title modal-basic-title-heading" id="modal-basic-title">
      <small
        ><a target="_blank" href="./assets/pdf/terms-conditions.pdf">
          Terms of Use & Data Privacy Statement
        </a></small
      >
    </h4>
  </div>
  <div class="modal-body" style="overflow-y: hidden;">
    <div class="container">
      <div class="header">
        <div class="textHeader">
          <h5>Terms of Use for Cloud-Based Digital Offer</h5>
          <h5>Insight Cloud Monitoring Tool</h5>
        </div>
        <div class="download">
          <span class="action-iconFilter">
            <em class="se-icon-action_download_stroke icons clickable"
            (click)="downloadFile()"></em>
          </span>
        </div>
      </div>

      <div class="content">
        <section>
          <p style="font-size: 12px; opacity: 0.5">
            June 15, 2024
          </p>
          <div style="font-weight: bold; font-size: 13px">
            <p>
                PLEASE READ CAREFULLY THESE TERMS OF USE INCLUDING SUCH OTHER LEGAL DOCUMENTS 
                INCORPORATED HEREIN BY REFERENCE (“TERMS OF USE") BEFORE USING OUR CLOUD-BASED DIGITAL 
                OFFER: INSIGHT CLOUD MONITORING TOOL (“DIGITAL OFFER”), AS THESE ARE TERMS OF A LEGAL 
                AGREEMENT BETWEEN YOU AND SCHNEIDER ELECTRIC WHICH DEFINES AND GOVERNS THE CONDITIONS 
                AND RIGHTS TO ACCESS AND USE THE DIGITAL OFFER AND THE DATA COLLECTED AND PROCESSED 
                THROUGH THE USE THEREOF
            </p>
            <p>
              THE INTENDED PURPOSE FOR WHICH THE DIGITAL OFFER IS MADE IS THAT IT IS
              USED ONLY BY PROFESSIONALS IN THEIR ORDINARY COURSE OF BUSINESS. YOU
              HAVE NO RIGHT NOR LICENSE TO USE THE DIGITAL OFFER IF YOU ARE NOT A
              PROFESSIONAL USING THE DIGITAL OFFER IN THE ORDINARY COURSE OF YOUR
              BUSINESS.
            </p>
            <p>
              IF YOU ARE AN INDIVIDUAL PERSON ACCESSING OR USING THE DIGITAL OFFER
              ON BEHALF OF A CORPORATION, COMPANY OR ANY OTHER LEGAL ENTITY, YOU ARE
              AGREEING TO THESE TERMS OF USE ON BEHALF OF THAT CORPORATION, COMPANY
              OR OTHER LEGAL ENTITY AND DECLARE TO SCHNEIDER ELECTRIC THAT YOU HAVE
              THE AUTHORITY TO DO SO AND THEREBY BIND THAT CORPORATION, COMPANY OR
              OTHER LEGAL ENTITY TO THESE TERMS OF USE IN WHICH EVENT, "CUSTOMER",
              "YOU" AND "YOUR" WILL BE DEEMED TO REFER TO THAT CORPORATION, COMPANY
              OR OTHER LEGAL ENTITY.
            </p>
            <p>
              IF YOU DO NOT ACCEPT THESE TERMS OF USE, YOU WILL NOT BE ABLE TO
              CREATE A USER ACCOUNT IN THE DIGITAL OFFER AND YOU WILL THEREFORE NOT
              BE ABLE TO MAKE USE OF THEDIGITAL OFFER.
            </p>
            <p>
              IF YOU AGREE WITH THESE TERMS OF USE, PLEASE CONFIRM YOUR AGREEMENT BY
              CHECKING THE CORRESPONDING TICK BOX ON THE REGISTRATION PAGE THAT IS
              MADE AVAILABLE TO YOU FOR THE PURPOSE OF CREATING A USER ACCOUNT IN
              THE DIGITAL OFFER.
            </p>
            <p>
              THESE TERMS OF USE WILL ALSO APPLY TO YOU AND SHALL REMAIN ENFORCEABLE
              BETWEEN SCHNEIDER ELECTRIC AND YOU EVEN IF YOU HAVE ORDERED OR
              OTHERWISE SUBSCRIBED TO THE DIGITAL OFFER THROUGH AN AUTHORIZED
              SCHNEIDER ELECTRIC DISTRIBUTOR OR RESELLER OR A CERTIFIED SCHNEIDER
              ELECTRIC PARTNER.
            </p>
          </div>
        </section>
    
        <ol>
          <li class="headerListItem">
            <section>
              <h6>Definitions</h6>
    
              <p>
                For the purposes of these Terms of Use, the capitalized terms below
                shall have the meaning defined herein below:
              </p>
              <p>
                <b>"Affiliates":</b>&nbsp;means with respect to either Schneider
                Electric or the Customer, any corporation, company or other legal
                entity that is directly or indirectly (i) controlling Schneider
                Electric or the Customer, or (ii) controlled by Schneider Electric
                or the Customer, or (iii) under common control with Schneider
                Electric or the Customer, and the term "control" shall, for the
                purpose of this definition, mean the direct or indirect control of
                at least 50% of the stock capital and/or of the voting rights.
              </p>
              <p>
                <b>"Application":</b>&nbsp;means the software program hosted on or
                interfaced with the Cloud Platform, that is designed to provide the
                functions defined in the Documentation and to enable the use of the
                Services defined in the Services Description. The Application
                includes any updated or upgraded version(s) to such software program
                if any is provided to Customer, when applicable, under and subject
                to the conditions defined in the Order Confirmation.
              </p>
              <p>
                <b>"Authorized User":</b>&nbsp; means any employee or contractor
                acting under the authority or control and on behalf of the Customer
                when accessing the Cloud Platform and making use of the Digital
                Offer in accordance with these Terms of Use.
              </p>
              <p>
                <b>"Cloud Platform":</b>&nbsp; means the cloud-based platform
                hosting or interfaced with the Application through which Customer
                Data and, as applicable, Customer's Clients Data are created or
                otherwise processed as part of the Services in accordance with these
                Terms of Use.
              </p>
              <p>
                <b>"Customer" or "You":</b>&nbsp; means each corporation, company or
                other legal entity carrying out a professional activity on behalf of
                which any Authorized User creates or is provided with a user account
                on the Cloud Platform or, as applicable, into the Application, to
                access and use the Digital Offer and, as applicable, the Services.
              </p>
              <p>
                <b>"Customer's Clients":</b>&nbsp; means any third party to whom
                Customer provides its own services through Customer’s use of the
                Digital Offer and, as applicable, the Services.
              </p>
              <p>
                <b>"Customer Data":</b>&nbsp; means all information, content and
                data proprietary or related to Customer, such as but not limited to
                text, sound, video or image files, and computer programs, that
                Customer or the Application or, as applicable Schneider Electric for
                the performance of the Services, creates and/or, as applicable,
                uploads, downloads, collects, stores, uses, shares, modifies or
                otherwise processes through the Cloud Platform as a result of
                Customer's use of the Application. Customer Data may include
                Personal Data
              </p>
              <p>
                <b>"Customer's Clients Data":</b>&nbsp; : means all information,
                content, and data, proprietary or related to Customer's Clients,
                such as but not limited to text, sound, video or image files, and
                computer programs, that Customer or the Application or, as
                applicable Schneider Electric acting on behalf of Customer, creates
                and/or, as applicable, uploads, downloads, collects, stores, uses,
                shares, modifies or otherwise processes through the Cloud Platform
                as a result of Customer's use of the Digital Offer in connection
                with Customer's provision of its own services to Customer's Clients.
                Customer’s Clients Data may include Personal Data
              </p>
              <p>
                <b>“Data Protection Laws”:</b>&nbsp; means all applicable laws
                relating to the protection of Personal Data, privacy and electronic
                communications as amended from time to time after these Terms of Use
                have been entered into. Depending upon (i) the scope of the
                activities performed in connection with Customer Data or Customer’s
                Clients Data and/or (ii) the country or state where such activities
                are performed, these laws may include the European General Data
                Protection Regulation 2016/679 (“GDPR”), the California Data
                Protection Laws, the UK Data Protection Laws, the Data Protection
                Law(s) of the People’s Republic of China, the Data Protection Law(s)
                of Mexico, the Data Protection Law(s) of Switzerland, and the Data
                Protection Law(s) of any other relevant country.
              </p>
              <p>
                <b>“Data Controller” and “Data Processor”: </b>&nbsp; have the
                meaning given by GDPR unless other Data Protection Laws apply, in
                which case, each of these terms will be interpreted in light of the
                closest equivalent term in the applicable Data Protection Laws.
              </p>
              <p>
                <b>“Digital Offer”</b>&nbsp; means the cloud-based software solution
                consisting in the provision by Schneider Electric to the Customer of
                the right to use the Application hosted on or interfaced with the
                Cloud Platform and to receive the Services as the case may apply
                through Customer's access to the Cloud Platform, subject to and in
                accordance with these Terms of Use.
              </p>
              <p>
                <b>“Documentation”: </b>&nbsp; means the manual and any other
                materials, in any format whatsoever, describing the functions of the
                Digital Offer and, as applicable, the procedures or instructions
                relating to its use, which are made available to the Customer on
                Schneider Electric webpages presenting the Digital Offer or
                otherwise in the manner set out in the Order Confirmation.
              </p>
              <p>
                <b>"Intellectual Property Rights":</b>&nbsp; means any and all
                rights of any kind and any type arising under statute, regulation,
                ordinance, common law, treaty, convention or otherwise, and
                including, without limitation any patents, utility models, moral
                rights, copyright and neighboring rights, trademarks and trade dress
                rights, mask work rights, service marks, right in domain names,
                designs rights, rights in computer software, database rights, rights
                in confidential information (including right in know-how and trade
                secrets) and any other intellectual property rights, in each case
                whether registered or not, including but not limited to applications
                and renewals, and all rights and forms of protection having
                equivalent or similar effect, recognized under the laws of any
                country.
              </p>
              <p>
                <b>“Order”:</b>&nbsp; means any purchase order placed by Customer to
                Schneider Electric or any Schneider Electric Affiliate, in writing
                or electronically, for the purchase by Customer of a subscription to
                the Digital Offer or, as applicable, the purchase of the Services,
                either in pursuance of (i) a Schneider Electric Affiliate’s
                commercial proposal accepted by Customer, (ii) a Services Contract,
                or (iii) through the website(s) or online portals in case the
                Digital Offer and/or the Services can be subscribed to online, as
                the case may be as part of a broader scope of supplies and/or
                services.
              </p>
              <p>
                <b>“Order(s) Confirmation(s)”:</b>&nbsp; means the confirmation for
                acceptance of Customer’s Order, issued by Schneider Electric or
                Schneider Electric Affiliate to Customer in writing or
                electronically.
              </p>
              <p>
                <b>“Personal Data”:</b>&nbsp; has the meaning given to this term
                under the GDPR unless other Data Protection Laws apply, in which
                case, this term will be interpreted in light of the closest
                equivalent term in the applicable Data Protection Laws.
              </p>
              <p>
                <b>"Schneider Electric":</b>&nbsp;  means Schneider Electric USA, Inc., a Delaware corporation with principal place of business located 
                at 201 Washington Street, One Boston Place, Suite 2700, Boston, MA 02108 United States.
                
              </p>
              <p>
                <b>“Schneider Electric Affiliate”: </b>&nbsp; means any Affiliate of
                Schneider Electric, in any country whatsoever, which accepts
                Customer's Order and therefore issues Order Confirmation.
              </p>
              <p>
                <b>"Services”:</b>&nbsp; means the provision to Customer of the
                Digital Offer and any services provided by Schneider Electric or its
                Affiliates through Customer's use of the Digital Offer.
              </p>
              <p>
                <b>"Services Contract":</b>&nbsp; means the commercial agreement
                signed between Customer and Schneider Electric or any Schneider
                Electric Affiliate, under which the Services are provided to
                Customer as the case may be as part of a broader scope of services.
              </p>
              <p>
                <b>“Services Description":</b>&nbsp; means the functional
                description of the applicable Services, as set forth in either or
                several of the following documents or instances: the Services
                Contract and/or the Order Confirmation, the web pages presenting the
                Digital Offer and/or, as applicable, the website(s) or online
                portals through which the Digital Offer and/or the Services can be
                subscribed to online, as such web pages, websites or portals may be
                updated from time to time by Schneider Electric or its relevant
                Affiliate. In case of any conflict between the description of the
                Services stated on such web pages, websites or portals on the one
                hand and in the Services Contract and/or the Order Confirmation on
                the other hand, the description stated in the Services Contract
                and/or the Order Confirmation shall prevail with respect only to the
                project under which Customer places the corresponding Order.
              </p>
              <p>
                <b>"Services Period":</b>&nbsp; means the timeframe during which
                Customer may access and use the Services as set forth in either or
                several of the following documents or instances: the Services
                Contract and/or the Order Confirmation, the web pages presenting the
                Digital Offer and/or, as applicable, the website(s) or online
                portals through which the Digital Offer and/or the Services can be
                subscribed to online, as such web pages, websites or portals may be
                updated from time to time by Schneider Electric or its relevant
                Affiliate. In case of any conflict between the Services Period
                stated on such web page, websites or portals on the one hand and in
                the Services Contract and/or the Order Confirmation on the other
                hand, the Services Period defined in the Services Contract and/or
                the Order Confirmation shall prevail with respect only to the
                project under which Customer places the corresponding Order.
              </p>
              <p>
                <b>“Standard Contractual Clauses”:</b>&nbsp; means (i) the standard
                data protection clauses for the transfer of Personal Data outside
                the European Economic Area (“EEA”) approved by the European
                Commission in decision 2021/914/EU dated 4 June 2021 (Module One
                Controller to Controller, Module Two Controller to Processor and
                Module Three Processor to Processor as applicable) and (ii) the
                International Data Transfer Addendum to the EU Standard Contractual
                Clauses issued by the UK Information Commissioner version B1.0 and
                entered into force on 21 March 2022. Under the Standard Contractual
                Clauses, the Customer and its Affiliates are the Data Exporter;
                Schneider Electric and its Affiliates are the Data Importer. The
                data transfers are continuous. The details of the data transfers and
                other information pertaining to the annexes of the Standard
                Contractual Clauses are specified in Sections 7.3 and 7. 4 of these
                Terms of Use. Under Clause 9(a) of the Standard Contractual Clauses,
                Option 2 “General written authorization” is selected. Under Clause 7
                of the Standard Contractual Clauses, the option is selected. Under
                Clause 11 of the Standard Contractual Clauses, the option is not
                selected. Under Clause 17 of the Standard Contractual Clauses,
                Option 1 and the laws of France are selected, unless Customer and
                Schneider Electric agree otherwise. Under Clause 18 of the Standard
                Contractual Clauses, the courts of France are selected, unless
                Customer and Schneider Electric agree otherwise. In case of transfer
                outside of Switzerland, if the Swiss Federal data protection law
                applies, any reference to the supervisory authority including in
                Clause 13 and Annex I.C of the Standard Contractual Clauses must be
                understood as a reference to the Swiss Federal data protection
                supervisory authority; the applicable law under Clause 17 of the
                Standard Contractual Clauses must be understood as Swiss law; any
                reference to “Member State” must be understood as “Switzerland”; any
                reference to Regulation (EU) 2016/679 of the European Parliament and
                of the Council of 27 April 2016 (General Data Protection Regulation)
                must be understood as the Swiss Federal Data protection law
              </p>
            </section>
          </li>
    
          <li>
            <section>
              <h6>User Account</h6>
              <ol>
                <li>
                  You need to create or request Schneider Electric to create on your
                  behalf a user account on the Cloud Platform or, as applicable, in
                  the Application (“User Account”) in order for you to access and
                  use the Services. To create Your User Account, You shall complete
                  the online registration form made available by Schneider Electric
                  with the information required thereon and thereby provide
                  Schneider Electric with profile information which include but may
                  not be limited to Your company name, the name and email address of
                  the individual person(s) You authorize to use the Digital Offer
                  and the Services , it being understood that, depending upon the
                  jurisdiction, such information may be considered as Personal Data
                  and shall in such case be treated as such by each of You and
                  Schneider Electric in compliance with these Terms of Use and any
                  applicable Data Protection Laws.
                </li>
                <li>
                  When You or Schneider Electric create Your User Account, You agree
                  (a) to use a unique password or change the one created for You by
                  Schneider Electric to a unique password that You do not use with
                  any other online product or service; (b) to provide accurate,
                  truthful, current and complete information; (c) to promptly update
                  the information You provided to create Your User Account; (d)
                  maintain the confidentiality and security of Your User Account by
                  protecting Your identification information and password and
                  restricting access to Your User Account; (e) promptly notify
                  Schneider Electric if You discover or otherwise suspect any
                  security breach related to Your User Account or any misuse
                  thereof; and (f) take responsibility for all activities that occur
                  under Your User Account and accept all risks of any authorized or
                  unauthorized access thereto.
                </li>
                <li>
                  You may authorize Your Authorized Users to use Your User Account,
                  access and make use of the Digital Offer and the Services on your
                  behalf and only for the purposes described in these Terms of Use.
                  You shall be responsible to ensure these Terms of Use are made
                  available in a legible manner to each of Your Authorized Users
                  before any of them can log to the Digital Offer and make use of
                  the Services. You shall be fully responsible for the acts and
                  omissions of Your Authorized Users, including for all activities
                  that are made through the use of Your User Account, and Your
                  Authorized Users’ compliance with these Terms of Use. You shall
                  bear responsibility for all activities that are made through the
                  use of Your User Account and for any resulting harm caused to You,
                  Your Authorized Users, any third-party, the Digital Offer, the
                  Services and/or Schneider Electric or Schneider Electric
                  Affiliates. Schneider Electric will have the right to rely upon
                  any information received from any legal or individual person
                  accessing and/or using Your User Account and Schneider Electric
                  will incur no liability arising out of such reliance.
                </li>
                <li>
                  Unless earlier termination by Schneider Electric or by You of Your
                  access to the Digital Offer and thereby the earlier termination of
                  Your right to use the Services in accordance with these Terms of
                  Use, Your User Account shall remain active, and the Services shall
                  be provided to You as long as the last applicable Services Period
                  has not expired and You pay the sums payable to Schneider Electric
                  or Schneider Electric Affiliate for the Services in accordance
                  with the Order Confirmation. The foregoing shall apply unless You
                  exercise the option to disable or cancel Your User Account or You
                  elect not to renew the Services Period at any time before expiry
                  of the then-current Services Period or You electronically
                  terminate Your subscription to the Digital Offer or, as
                  applicable, the Services if and when any such possibility shall be
                  made available to You by Schneider Electric or its relevant
                  Affiliate(s). Your User Account shall be automatically and
                  immediately disabled upon expiration of the last applicable
                  Services Period, with the effect that You shall automatically and
                  immediately have the obligation to cease any access to and use of
                  the Digital Offer and the applicable Services. 
                  <p>The renewal or
                    cancellation conditions of the Services Period shall be defined
                    where the Services Period is defined.</p>
                </li>
                <li>
                  While not obligated to perform investigations, Schneider Electric
                  may investigate violations of these Terms of Use or misuse of the
                  Digital Offer and cooperate with appropriate law enforcement
                  authorities with respect to security threats, fraud, or other
                  illegal, malicious, or inappropriate activity by You or any
                  third-party through Your access to and/or Your use of the Digital
                  Offer
                </li>
              </ol>
            </section>
          </li>
    
          <li>
            <section>
              <h6>License Grant to the Digital Offer</h6>
              <ol>
                <li>
                  Subject to Your continued compliance with these Terms of Use and,
                  unless the Digital Offer is made available to You free of charge,
                  Your full payment of the fees when due to acquire the right to
                  access and use the Digital Offer, Schneider Electric grants to You
                  a non-exclusive, non-transferable and limited license right to use
                  the Application as part of the Services for the time period and in
                  the territory (worldwide unless geographically restricted) for
                  which the Services are available to You according to the Order
                  Confirmation, unless this license is earlier terminated in
                  accordance with Section 13 of these Terms of Use. Your use of the
                  Digital Offer must also comply with the Documentation and any
                  other conditions agreed upon with You in the Services Contract or
                  Order Confirmation.
                </li>
                <li>
                  <p> 
                    Any right to receive the Application under any tangible form or
                    media or to download and install the Application on any device, is
                    expressly excluded, unless the Application is made available to
                    You as a mobile application for installation on a mobile device.
    
                  </p>
                  <p>
                    To the extent applicable to the Digital Offer, the Application may
                    only be used as part of the Services by the particular named
                    user(s), in the particular location(s), on the particular
                    device(s) and/or on the particular system(s) for which You have
                    acquired the right to access and use the Digital Offer in
                    accordance with these Terms of Use, as those named user(s),
                    location(s), device(s) and/or system(s) are identified in the
                    Documentation, the Services Description or the Order Confirmation.
                  </p>
                 
                  
                  <p>
                    The Application will be made available to You in object code
                    (machine-readable) form only and under no circums
                ` </p>
                </li>
                <li>
                  These Terms of Use describe and govern Your right to use the
                  Digital Offer as part of the Services. It is expressly agreed that
                  these Terms of Use and any Order Confirmation will prevail over
                  and supersede the terms and conditions contained in any purchase
                  order or other document You may issue or submit in connection with
                  Your use of the Digital Offer and such other terms and conditions
                  are expressly rejected by Schneider Electric to the extent such
                  other terms and conditions conflict with these Terms of Use.
                </li>
                <li>
                  Customer may not sublicense or otherwise grant the access and the
                  right to use the Digital Offer and/or the Services to any
                  Customer's Client, unless Schneider Electric or Schneider Electric
                  Affiliate receiving Customer's Order expressly authorizes Customer
                  in writing through the Services Contract or the Order Confirmation
                  to provide such access and right to use the Digital Offer and/or
                  the Services to Customer's Clients.
                  <ul class="romanList">
                    Customer hereby agrees that:
                    <li>
                      the conditions defined under paragraphs (a) to (d) below shall
                      apply to the provision by Customer to Customer's Clients of
                      the right to access and use the Digital Offer and the
                      Services, and
                    </li>
                    <li>
                      Customer has the obligation to inform Customer's Clients of
                      such conditions and to obtain their express written agreement
                      to such conditions under any purchase order or contract
                      accepted or entered into between Customer and Customer's
                      Clients, and Customer shall indemnify and hold Schneider
                      Electric and its Affiliates harmless against any damages
                      resulting from Customer's failure to provide such information
                      and/or obtain Customer's Client's agreement to such
                      conditions:
                      <ul class="alphaList">
                        <li>
                          Customer shall not grant to Customer's Clients more rights
                          to the Digital Offer and, as applicable, to the Services
                          than the rights granted to Customer under these Terms of
                          Use and the rights granted by Customer to Customer's
                          Clients may not be granted for any period exceeding the
                          Services Period applicable to Customer under the Services
                          Contract or Order Confirmation;
                        </li>
                        <li>
                          Customer's Client shall not be able to access the Digital
                          Offer and, as applicable, the Services until Customer's
                          Client creates its own User Account or requests Customer,
                          and Customer agrees, to create on its behalf the User
                          Account of Customer's Client; and
                        </li>
                        <li>
                          such User Account cannot be created, and Customer's Client
                          will therefore not be able to make use of the Digital
                          Offer and, as applicable, the Services, if Customer's
                          Client does not accept these Terms of Use through the
                          registration page or any other consent interface made
                          available by Schneider Electric in relation to the Digital
                          Offer and/or the Services; and
                        </li>
                        <li>
                          Upon their acceptance by Customer's Client, these Terms of
                          Use shall bind upon Customer's Client and shall be
                          enforceable by Schneider Electric against Customer's
                          Client directly, irrespective whether Customer's Client
                          places or not any order or enters or not into any contract
                          with any Schneider Electric Affiliate in relation to the
                          Digital Offer and/or the Services.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Restrictions on use of the Digital Offer</h6>
              <ol>
                <li>
                  <p>
                    Your use of the Digital Offer may be subject to certain
                    restrictions set forth in these Terms of Use and other legal
                    documents incorporated or referred to herein, which may include
                    without limitation certain limitations on the scope of use,
                    capacity, metrics, types and quantities of system resources,
                    and/or duration of the Services. Your use of the Digital Offer in
                    a manner inconsistent with such restrictions may adversely impact
                    the performance of the Services, may result in additional charges
                    to You and/or may result in suspension of the Services or
                    termination of Your User Account in accordance with these Terms of
                    Use. You agree that You will comply with such restrictions or
                    technical limitations of the Digital Offer.
                  </p>
                <p> 
                  You acknowledge that
                  You shall solely bear the cost of any toll charges applicable to
                  Your access to the Digital Offer through the use of internet or
                  telephone.
                </p>
                <p>
                  If technological measures are designed to prevent
                  unlicensed or illegal use of the Digital Offer, You agree that
                  Schneider Electric may use those measures and You agree to comply
                  with any requirements regarding such technological measures and
                  that You will not nor will You permit others to circumvent such
                  measures. Such measures do not constitute a defect in the Digital
                  Offer nor do they entitle You to any warranty rights.
                </p> 
                </li>
                <li>
                  In using the Digital Offer, You agree:
                  <ul class="romanList">
                    <li>not to reverse engineer,
                      decompile, disassemble, modify, adapt or translate any part of the
                      Digital Offer such as but not limited to the Application, or
                      create derivative works based on any part of the Digital Offer;
                      further, in case of errors, bugs or defects in any part of the
                      Digital Offer such as but not limited to the Application, only
                      Schneider Electric or its designated Affiliate(s), to the
                      exclusion of the Customer or any third-party not authorized by
                      Schneider Electric, shall have the right to provide Customer with
                      any correction or work-around thereof; 
                    </li>
                    <li> not to perform or
                      disclose any benchmark or performance tests of the Digital Offer
                      without Schneider Electric's prior express written consent;
                    </li>
                    <li>not to perform or disclose any security testing of the Digital
                      Offer or associated infrastructure without Schneider Electric's
                      prior express written consent, such as but not limited to network
                      discovery, port and service identification, vulnerability
                      scanning, password cracking, remote access testing, or penetration
                      testing;
                    </li>
                    <li>unless otherwise expressly permitted under these
                      Terms of Use, You must not transfer, license, sublicense, rent,
                      lease, sell, lend, distribute, host, outsource, disclose, permit
                      timesharing or service bureau use, assign, or otherwise
                      commercially exploit or make available the Digital Offer or any
                      part thereof including without limitation any materials or
                      programs pertaining to or otherwise comprised in the Application,
                      to any third party without Schneider Electric's prior express
                      written consent and such consent may be withheld in the sole
                      discretion of Schneider Electric;
                    </li>
                    <li>not to disrupt or interfere
                      with the security of, or otherwise abuse, the Digital Offer and/or
                      the web site(s) and/or any online portals or associated
                      infrastructure proprietary to or authorized by Schneider Electric
                      or its relevant Affiliate through which You are given access to
                      the Digital Offer and/or the Services; 
                    </li>
                    <li>not to disrupt or
                      interfere with the access to the Digital Offer and/or the
                      provision by Schneider Electric of the Services to any other
                      customer or user thereof; 
                    </li>
                    <li>not to upload, post, or otherwise
                      transmit any virus or other harmful, disruptive or destructive
                      files into the Digital Offer and/or the web site(s) and/or any
                      online portals or associated infrastructure proprietary to or
                      authorized by Schneider Electric or its relevant Affiliate through
                      which you are given access to the Digital Offer and/or the
                      Services;
                    </li>
                    <li>not to use or attempt to use the data of any other
                      user of the Digital Offer and the Services, or create or use a
                      false identity in accessing or using the Digital Offer and/or the
                      web site(s) and/or any online portals or associated infrastructure
                      proprietary to or authorized by Schneider Electric or its relevant
                      Affiliate through which You are given access to the Digital Offer
                      and/or the Services;
                    </li>
                    <li>not to transmit through or into the
                      Digital Offer and/or the web site(s) and/or any online portals or
                      associated infrastructure proprietary to or authorized by
                      Schneider Electric or its relevant Affiliate through which You are
                      given access to the Digital Offer and/or the Services, any spam
                      mail, chain letters, junk mail or any other type of unsolicited
                      mass e-mail to people or entities who have not agreed to be part
                      of such mailings;
                    </li>
                    <li> not advertise or offer to sell any goods or
                      services through or into the Digital Offer and/or the web site(s)
                      and/or any online portals or associated infrastructure proprietary
                      to or authorized by Schneider Electric or its relevant Affiliate
                      through which You are given access to the Digital Offer and/or the
                      Services;
                    </li>
                    <li>not to use the Digital Offer and/or the web site(s)
                      and/or any online portals or associated infrastructure proprietary
                      to or authorized by Schneider Electric or its relevant Affiliate
                      through which you are given access to the Digital Offer and/or the
                      Services, to defame, abuse, harass, stalk, threaten or otherwise
                      violate the legal rights (such as rights of privacy or
                      Intellectual Property Rights where applicable) of Schneider
                      Electric or any third party;
                    </li>
                    <li>not to use the Digital Offer
                      and/or the web site(s) and/or any online portals or associated
                      infrastructure proprietary to or authorized by Schneider Electric
                      or its relevant Affiliate through which You are given access to
                      the Digital Offer and/or the Services, to publish, post,
                      distribute or disseminate any information or material which is
                      obscene, defamatory, indecent or unlawful; and
                    </li>
                    <li>
                      not to use
                      the Digital Offer and/or the web site(s) and/or any online portals
                      or associated infrastructure proprietary to or authorized by
                      Schneider Electric or its relevant Affiliate through which You are
                      given access to the Digital Offer and/or the Services, to take any
                      action which would cause Schneider Electric to be in violation of
                      any law or regulation.
                    </li>
                  </ul> 
                  
                </li>
                <li>
                  Schneider Electric may make changes or updates to the Digital
                  Offer (such as but not limited to infrastructure, technical
                  configurations, application features), the Documentation and, as
                  applicable, to the Services and the Services Description, at any
                  time during the Services Period, including to reflect changes in
                  technology, industry practices, and patterns of systems used to
                  operate, deliver, support and/or maintain the Digital Offer and/or
                  the Services. Furthermore, Schneider Electric may need at any time
                  during the Services Period to change, update, add or remove
                  functionalities or features of the Digital Offer and/or the
                  Services, and/or to add or create new limitations to the Digital
                  Offer and/or the Services or to suspend or stop providing the
                  Services, (i) to comply with any legal obligation applicable to
                  Schneider Electric or any order, injunction or decision by a court
                  or other competent jurisdiction or any governmental or regulatory
                  agency, or (ii) to prevent fraud, abuse or security threat of the
                  Digital Offer and/or the Services, or (iii) where Schneider
                  Electric is subject to any circumstances out of its control which
                  creates hardship conditions for Schneider Electric to continue
                  operating, delivering, supporting and/or maintaining the Digital
                  Offer and/or the Services without modification, or (iv) where such
                  modification, suspension or discontinuance is imposed to Schneider
                  Electric by any of its third-party hosting service providers,
                  third-party licensors or other third-parties operating,
                  delivering, supporting and/or maintaining the Digital Offer and/or
                  the Services on behalf of Schneider Electric, or (v) where such
                  modification, suspension or discontinuance results from the
                  modification, suspension or discontinuance of service to Schneider
                  Electric from any of its third-party hosting service providers,
                  third-party licensors or other third parties operating, supporting
                  and/or maintaining the Digital Offer and/or the Services.
                  Schneider Electric will make commercially reasonable efforts to
                  inform You prior to implementing such change, update, addition,
                  removal, limitation, suspension or discontinuance. If a feature or
                  functionality of the Digital Offer and/or the Services is removed
                  or stopped, and unless otherwise required under the law governing
                  these Terms of Use, Schneider Electric shall have no obligation to
                  provide a functionally equivalent feature or functionality.
                </li>
                <li>
                  From time to time and at its discretion, Schneider Electric may
                  choose to design additional functionality or enhancements to
                  existing functionality of the Digital Offer and the Services,
                  either directly or through a subcontractor acting on its behalf.
                  Schneider Electric will freely determine whether it includes any
                  such additional or enhanced functionality in the Digital Offer and
                  the Services and shall have no obligation to disclose or propose
                  it to Customer unless otherwise agreed upon in writing between
                  Schneider Electric and Customer.
                </li>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Price and payment for the Services</h6>
              <ol>
                <p>
                  Unless the Order Confirmation or any separate agreement between
                  Customer and Schneider Electric or Schneider Electric Affiliate
                  specify that the Services are provided by Schneider Electric on a
                  free of charge basis for whole or part of the Services Period, the
                  Services shall be provided by Schneider Electric against payment
                  by Customer of the fee amount(s) set forth in the Order
                  Confirmation and, as the case may apply, on the web pages
                  presenting the Digital Offer and/or the website(s) or online
                  portals through which the Services can be subscribed to online, as
                  such pages may be updated from time to time by Schneider Electric
                  or its relevant Affiliate. In case of any conflict between the fee
                  amount(s) stated on such web pages, websites or portals and the
                  fee amount(s) stated in the Order Confirmation, the latter shall
                  prevail with respect only to the project under which Customer
                  places the corresponding Order of Services.
                </p>
                <p>
                  Any revision of said fee amount(s) shall be applied to Services
                  provided to Customer under any Order of Services placed by
                  Customer after the date of validity of such revision.
                </p>
                <p>
                  Terms of payment and taxes applicable to the Services' price(s)
                  shall apply as defined in the Order Confirmation or, by default,
                  in Schneider Electric Affiliate's terms of sale applicable to the
                  Order Confirmation.
                </p>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Customer Data</h6>
              <ol>
                <li>
                  <b>Rights to Customer Data.</b>
    
                  <ol>
                    <li>
                      Customer retains all rights, title, and interest in the
                      Customer Data. Customer hereby grants to Schneider Electric a
                      non-exclusive, perpetual, irrevocable, worldwide, free of
                      charge and royalty-free license right and authorization
                      (“License”) to use, host, store, upload, import, collect,
                      create, translate, copy, modify, distribute modifications of,
                      perform, create and display or distribute derivative works of,
                      Customer Data for the purpose of and in conjunction only with
                      the following limited purposes as further detailed here below
                      which Customer considers compatible with its own data
                      processing purposes:
                      <ul class="alphaList">
                        <li>providing the Services to Customer,</li>
                        <li>
                          improving and modifying the Digital Offer and/or the
                          Services, and creating any new Schneider Electric
                          products, services and solutions,
                        </li>
                        <li>
                          generating business and/or sales between Customer and
                          Schneider Electric or its Affiliates,
                        </li>
                        <li>conducting business management operations, and</li>
                        <li>
                          allowing compliance and/or enforcement of legal
                          requirements.
                        </li>
                      </ul>
                      <p>
                        The License granted in this Section 6.1.1 includes the right
                        for Schneider Electric to sublicense the same rights to its
                        Affiliates and any third-party subcontractors providing all
                        or part of the Services on behalf of Schneider Electric, in
                        either case under Schneider Electric's responsibility
                        towards Customer in accordance with the provisions of these
                        Terms of Use.
                      </p>
                      <p>
                        Any objection by Customer to the exercise by Schneider
                        Electric or any Schneider Electric Affiliate of the License
                        rights granted under this Section 6.1.1, may only be raised
                        with respect to the purposes b) to c) in writing to
                        Schneider Electric through any means made available to
                        Customer.
                      </p>
                      <p>
                        For each use case listed above, Schneider Electric’s
                        processing of the Customer Data shall respect all
                        obligations under applicable Data Protection Legislation,
                        including restrictions that apply when Schneider Electric is
                        processing the data as a processor or sub-processor in
                        accordance with Section 7.3 below.
                      </p>
                      <p>
                        For purposes of these Terms of Use, “providing” the Services
                        as referred to under (a) above consists of:
                      </p>
                      <ul class="bulletList">
                        <li>
                          delivering the functional capabilities of the Digital
                          Offer to Customer and its Authorized Users, including
                          providing personalized user experience(s) of the Digital
                          Offer;
                        </li>
                        <li>
                          providing technical support and maintenance including
                          preventing, detecting, investigating, mitigating and/or
                          repairing issues in or with the Digital Offer; and
                        </li>
                        <li>
                          providing any professional services (i) related to the
                          Digital Offer such as but not limited to conducting
                          operations and tasks related to the installation,
                          configuration, set up and/or commissioning of the Digital
                          Offer with or within Customer’s operating environment or
                          any hosting environment agreed upon with Customer, or (ii)
                          resulting from Customer’s use of the Digital Offer such as
                          but not limited to the delivery to Customer of reports,
                          analysis and any other type of deliverables created by
                          Schneider Electric or its Affiliates in pursuance of
                          Services Agreement(s).
                        </li>
                      </ul>
                      <p>
                        For purposes of these Terms of Use, “improving, modifying
                        and creating the Services” as referred to under (b) above,
                        consists of:
                      </p>
                      <ul class="bulletList">
                        <li>
                          updating, upgrading, improving and/or modifying whole or
                          part of the Digital Offer and/or the Services
                          (“Improvement”), including tests executed for such
                          purposes; and
                        </li>
                        <li>
                          creating any new Schneider Electric product, service or
                          solution, which may or not be complementary or
                          substitutable to the Digital Offer and/or the Services
                        </li>
                      </ul>
                      <p>
                        Schneider Electric will de-identify any data used for those
                        purposes.
                      </p>
                      <p>
                        When Schneider Electric will propose, promote or provide any
                        Improvement and/or any new Schneider Electric products,
                        services or solutions to any third-party other than Customer
                        whose Customer Data are used by Schneider Electric in
                        accordance with the aforementioned limited purposes,
                        Schneider Electric shall ensure that the Improvement and/or
                        its new product, service or solution shall not contain any
                        information enabling the identification of such Customer
                        and/or such Customer’s equipment, utilities, processes,
                        infrastructures, buildings, facilities or other assets in
                        relation to which such Customer is using the Digital Offer
                        and has subscribed to the Services
                      </p>
                      <p>
                        For purposes of these Terms of Use, “generating business
                        and/or sales between Customer and Schneider Electric or its
                        Affiliates” as referred to under (c) above consists of the
                        following:
                      </p>
                      <ul class="bulletList">
                        <li>
                          identifying potential business opportunities at Schneider
                          Electric with Customer; and
                        </li>
                        <li>
                          as the case may be, submitting to Customer technical
                          and/or commercial proposals to purchase a license or
                          subscription to the Digital Offer, its updated, upgraded,
                          improved or modified versions, or other Schneider Electric
                          products, services or solutions and/or related
                          professional services.
                        </li>
                      </ul>
                      <p>
                        Such opportunities’ identification and, as applicable, such
                        proposals may notably be based on (i) Schneider Electric’s
                        analysis of Customer Data collected through Customer’s use
                        of the Digital Offer, or (ii) professional services, such as
                        but not limited to studies or audits, conducted or performed
                        at Customer’s sites with Customer’s agreement or on
                        Customer’s request.
                      </p>
    
                      <p>
                        For purposes of these Terms of Use, “conducting business
                        management operations” as referred to under (d) above
                        consists of the following, each being incident to delivery
                        of the Services to Customer:
                      </p>
                      <ul class="bulletList">
                        <li>billing and account management;</li>
                        <li>
                          internal business reporting and modeling (e.g.,
                          forecasting, revenue, capacity planning, product
                          strategy);
                        </li>
                        <li>financial reporting; and</li>
                        <li>
                          corporate governance, including mergers, acquisitions and
                          divestitures, or other corporate reorganization of
                          Schneider Electric or any of its Affiliates
                        </li>
                      </ul>
                      <p>
                        For purposes of these Terms of Use, “allowing compliance
                        and/or enforcement of legal requirements” as referred to
                        under (e) above consists of the following, each being
                        incident to delivery of the Services to Customer
                      </p>
                      <ul class="bulletList">
                        <li>
                          compliance with any legal obligation applicable to
                          Schneider Electric and/or any of its Affiliates or any
                          order, injunction or decision by any court of competent
                          jurisdiction or any governmental or regulatory agency;
                        </li>
                        <li>
                          compliance with company policies and procedures, such as
                          but not limited to anti-money laundering programs,
                          security and incident response programs, intellectual
                          property protection programs, corporate ethics and
                          compliance hotlines; corporate audits, analysis and
                          reporting;
                        </li>
                        <li>
                          preventing or combatting fraud, abuse or security threats
                          that may affect the Digital Offer, the Services, Schneider
                          Electric and/or any of its Affiliates and their business
                          continuity; protecting Schneider Electric and any of its
                          Affiliates against disasters, injury, theft or legal
                          liability, and as applicable, enabling Schneider Electric
                          and its Affiliates to protect individuals or property; and
                        </li>
                        <li>
                          enforcing or defending Schneider Electric's rights (or
                          those of any of its Affiliates) under these Terms of Use
                          such as but not limited in the frame of any litigation or
                          other proceedings.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        In case Customer's Clients Data are used, uploaded,
                        collected, stored, created, modified or otherwise processed
                        on the Cloud Platform or in the Application by Customer such
                        as but not limited in connection with Customer’s acting as
                        service provider to any Customer's Client, Customer shall be
                        solely and fully responsible to obtain from such Customer's
                        Client or other relevant third-party in relation to the
                        Customer’s Clients Data the necessary approvals, consents,
                        authorizations and, as applicable, licenses to perform any
                        of the aforesaid actions and to grant to Schneider Electric
                        the License defined in Section 6.1.1 above. Subject to this
                        License, Schneider Electric acknowledges that, unless
                        otherwise agreed between Customer and Customer's Clients
                        under their own responsibility, Customer's Clients retain
                        all rights to Customer's Clients Data.
                      </p>
                      <p>
                        Customer represents that Customer has and will keep in
                        effect during its use of the Services, all the aforesaid
                        approvals, consents, authorizations and licenses at no
                        charge to Schneider Electric, its Affiliates and
                        subcontractors.
                      </p>
                      <p>
                        Customer represents that Customer is not exceeding any
                        specific entitlement or permitted use or violating
                        applicable license agreements or applicable laws to grant
                        the foregoing license rights. When applicable license
                        agreements or applicable laws require specific arrangements,
                        including obtaining an approval, an authorization or a
                        license from any competent authority, Customer, Customer’s
                        Clients or a consent from any individual person, Customer
                        shall obtain such approval, authorization or license and
                        seek consent from any such individual persons, in accordance
                        with applicable law and without infringement of any
                        Intellectual Property Rights, using such methods which will
                        either be made available by Schneider Electric within the
                        Services or that will be decided and deployed by the
                        Customer.
                      </p>
                      <p>
                        Customer agrees to indemnify and hold Schneider Electric and
                        its Affiliates harmless from any third-party claims and any
                        costs and other amounts that Schneider Electric or its
                        Affiliates may incur or otherwise be subject to because of
                        Customer's breach of this Section 6.1.2.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <b> Security and back-ups</b>
                  <ol>
                    <li>
                      <b> Security of the Digital Offer.</b
                      ><span
                        >Customer acknowledges that Customer has reviewed the
                        security features of the Digital Offer and, as applicable,
                        the Services as such features are described by Schneider
                        Electric with the Digital Offer and/or the Services or upon
                        Customer’s request, and Customer has determined that they
                        meet Customer's security needs including any specific
                        requirement of security applicable in the field of activity
                        of Customer or Customer's Clients for which Customer is
                        acting as service provider</span
                      >
                    </li>
                    <li>
                      <b>Data security. </b
                      ><span
                        >Customer is solely responsible for determining the
                        requirements regarding security of Customer Data and
                        Customer's Clients Data. If the security features,
                        procedures and/or controls offered by Schneider Electric
                        with respect to the Digital Offer and the Services do not
                        meet the requirements determined by Customer regarding
                        security of Customer Data and Customer's Clients Data,
                        Customer should not use the Digital Offer and the Services.
                        Schneider Electric does not accept any liability for any
                        corruption, loss or theft of data caused by security
                        breaches resulting from internet connectivity and/or the
                        environment or systems used by Customer or Customer's
                        Clients to access and use the Digital Offer and the
                        Services, such as but not limited to security breaches
                        resulting from hackers, unlawful entry or unauthorized
                        access. Schneider Electric shall not be responsible, and
                        Customer is solely responsible at its own costs for the
                        performance, the safety and integrity of backups of Customer
                        Data and Customer's Clients Data. Except to the extent that
                        Schneider Electric and Customer have agreed on back-up or
                        disaster recoveries, Customer acknowledges that the Digital
                        Offer and the Services do not include any dedicated data
                        back-up or disaster recovery plans and facilities and that
                        Customer should ensure it maintains safe and regular backups
                        of all Customer Data and Customer's Clients Data as
                        necessary to ensure the continuation of Customer’s
                        business(es) in accordance with the needs of Customer, its
                        Authorized User and/or as applicable Customer’s Clients.
                        Though Schneider Electric, its Affiliates and/or its/their
                        third-party hosting service providers may routinely
                        undertake regular backups of the Services (which may include
                        Customer Data and Customer's Clients Data) for its/their own
                        business continuity purposes, Customer acknowledges that
                        such steps do not in any way make Schneider Electric, its
                        Affiliates and/or said third-party hosting service providers
                        responsible for ensuring that Customer Data and/or
                        Customer's Clients Data does not become inaccessible,
                        damaged, or corrupted.</span
                      >
                      <p>
                        Except to the extent Schneider Electric has control or
                        monitoring obligations under applicable laws with respect to
                        Customer Data and/or Customer's Clients Data hosted or
                        otherwise processed as part of the Services, Customer
                        acknowledges that Schneider Electric might not actively
                        control or monitor the contents of any Customer Data or
                        Customer's Clients Data hosted or otherwise processed as
                        part of the Services. Customer must ensure and is
                        exclusively responsible for the accuracy, quality,
                        integrity, and legality of Customer Data and Customer's
                        Clients Data.
                      </p>
                    </li>
                    <li>
                      <p><b> Customer security program</b></p>
                      <p></p>
                      <p>
                        Customer is solely responsible for the implementation and
                        maintenance of a comprehensive security program that
                        contains reasonable and appropriate security measures and
                        safeguards to protect its computer network, systems,
                        machines and data (“Customer’s Systems”) against any
                        circumstance or event with the potential to adversely
                        impact, compromise, damage, or disrupt Customer’s Systems or
                        that may result in any unauthorized access, acquisition,
                        loss, misuse, destruction, disclosure, and/or modification
                        of Customer’s Systems, including through malware, hacking,
                        or similar attacks (“Cyber Threats”), and Customer agrees to
                        regularly monitor its systems with respect to Cyber Threats
                      </p>
                    </li>
                    <li>
                      <p><b> Reporting a vulnerability </b></p>
                      <p></p>
                      <p>
                        If Customer identifies or otherwise becomes aware of any
                        vulnerabilities or other Cyber Threats relating to the Cloud
                        Platform, the Services or the Application for which
                        Schneider Electric has not released a patch or update,
                        Customer shall promptly notify Schneider Electric of such
                        vulnerability via the Schneider Electric ‘Report a
                        Vulnerability’ page
                        (https://www.se.com/ww/en/work/support/cybersecurity/report-a-vulnerability.jsp)
                        and further provide Schneider Electric with any reasonably
                        requested information relating to such vulnerability or
                        other Cyber Threat(s) (collectively, “Cybersecurity
                        Feedback”). Schneider Electric shall have a non-exclusive,
                        perpetual and irrevocable right to use, display, reproduce,
                        modify and distribute the Cybersecurity Feedback (including
                        any confidential information or intellectual property
                        contained therein) in whole or part, including to analyze
                        and fix the vulnerability, to create patches or updates, and
                        to otherwise modify the Cloud Platform, the Services or the
                        Application, in any manner without restrictions, and without
                        any obligation of attribution or compensation to Customer;
                        provided, however, Schneider Electric shall not publicly
                        disclose Customer’s name in connection with such
                        Cybersecurity Feedback or the use thereof (unless Customer
                        consents otherwise). By submitting Cybersecurity Feedback,
                        Customer represents and warrants to Schneider Electric that
                        Customer has all necessary rights in and to such
                        Cybersecurity Feedback and all information it contains,
                        including to grant the rights to Schneider Electric
                        described herein, and that such Cybersecurity Feedback does
                        not infringe any proprietary or other rights of third
                        parties or contain any unlawful information
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    <b>
                      Access to Customer Data, Return of Customer Data or transfer
                      of Customer Data to a third-party</b
                    >
                  </p>
                  <ol>
                    <li>
                      <p>
                        Customer may at any time during any Services Period request
                        in writing that Schneider Electric (a) makes available to
                        Customer (b) extracts and returns to Customer, or (c)
                        transfers to a third-party all or part of the Customer Data
                        (as well, as applicable per Customer’s request, Customer's
                        Clients Data) stored on the Cloud Platform and/or in the
                        Application. These requests will be handled at Customer’s
                        expense, except when applicable law provides otherwise.
                        Following receipt of such request, Schneider Electric shall
                        confirm the cost, if any, to be charged to the Customer.
                        Subject to Customer’s agreement to the cost charged by
                        Schneider Electric, Schneider Electric shall use technically
                        and commercially reasonable efforts to (a) make available,
                        or (b) extract and return, or (c) transfer such Customer
                        Data and as applicable Customer's Clients Data, in Schneider
                        Electric’s standard format or any other format lawfully
                        selected by Schneider Electric without unreasonable delay
                        from receipt of such request. Where applicable and
                        technically possible, and subject to an agreement between
                        Customer and Schneider Electric, access or transfer can be
                        done continuously or in real time.
                      </p>
                      <p>
                        For the purpose of this Section 6.3, except as required by
                        law or except as otherwise agreed between Schneider Electric
                        and Customer, Customer Data which are subject to access,
                        return and transfer is limited to (i) data which is recorded
                        intentionally by Customer in the Digital Offer and/or the
                        Services, and (ii) data generated by the Digital Offer
                        and/or the Services, to the exclusion of information,
                        content and data which are derived or inferred. Derived or
                        inferred information, content and data are those resulting
                        from any software process implemented by Schneider Electric
                        that substantially modifies the recorded or generated data.
                        Customer shall not use the Customer Data obtained pursuant
                        to a request made per this Section 6.3 to develop or cause
                        or allow a third party to develop a product that competes
                        with the Digital Offer or the Services from which the data
                        originates.
                      </p>
                      <p>
                        Customer acknowledges and agrees that such access,
                        extraction and return, or transfer by Schneider Electric may
                        be subject to prior consent and/or limitations imposed to
                        Schneider Electric by its third-party hosting service
                        providers or third-party licensors
                      </p>
                      <p>
                        When such access, extraction and return or transfer by
                        Schneider Electric are subject to prior consent and/or
                        limitations or specific regulatory requirements or standards
                        imposed to Customer or Customer’s Clients, Customer shall
                        represent that they have obtained all consents and approvals
                        as well as provided to Schneider Electric all information
                        required to proceed with such access, extraction and return
                        or transfer in compliance with any such requirements or
                        standards. Customer agrees to indemnify and hold Schneider
                        Electric harmless from any third-party claims and any costs
                        and other amounts that Schneider Electric may incur or
                        otherwise be subject to because of Customer's breach of this
                        Section 6.3.1.
                      </p>
                      <p>
                        This Section 6.3.1 applies without prejudice to the
                        provisions of Section 7.3.9 related to Personal Data.
                      </p>
                    </li>
                    <li>
                      If the Digital Offer or the Services can connect with
                      Schneider Electric's or a third party's Application
                      Programming Interface (API), the use of these API may be
                      subject to their own terms & conditions. Schneider Electric or
                      its relevant Affiliate may request You to accept separate
                      Schneider Electric’s API terms & conditions, and will request
                      Your approval (i) before Schneider Electric grants access to
                      Your Customer Data or Customer’s Clients Data through the use
                      of Schneider Electric's or a third party's API, or (ii) before
                      Schneider Electric generates, or allows the generation of, any
                      technological measures such as but not limited to access
                      tokens which, through the use of any API, allow access to Your
                      Customer Data or Customer's Clients Data
                    </li>
                  </ol>
                </li>
                <li>
                  <b>
                    Persistence of the Services when Customer requests for return or
                    transfer of Customer Data.</b
                  >
                  <span
                    >Extraction and return or transfer by Schneider Electric of
                    Customer Data and/or Customer's Clients Data upon Customer’s
                    request as defined in Section 6.3.1, shall not cause Customer’s
                    User Account to be disabled: Customer's User Account shall
                    remain active, and the Services shall be continued, until the
                    Services Period expires or is earlier terminated. The provisions
                    of Section 13 of these Terms of Use shall apply to the return of
                    Customer Data and Customer's Clients Data following the
                    expiration or termination of the Services Period.
                  </span>
                </li>
                <li>
                  <b> Disclosure of Customer Data. </b>
                  <span>
                    Schneider Electric shall not disclose Customer Data and
                    Customer's Clients Data to any third party without Customer's
                    prior express consent, except that Customer’s prior consent
                    shall not be required (i) for disclosure by Schneider Electric
                    to any of its Affiliates, suppliers or any third-party
                    subcontractors acting on behalf of Schneider Electric and
                    involved in the performance of the activities authorized under
                    the License granted to Schneider Electric under Section 6.1
                    above, or (ii) when disclosure of Customer Data and/or
                    Customer's Clients Data is necessary for Schneider Electric to
                    comply with any legal obligation applicable to Schneider
                    Electric or any order, injunction or decision by any court of
                    competent jurisdiction or any governmental or other regulatory
                    agency or authority, or to prevent fraud, abuse or security
                    threat of the Digital Offer or the Services, or to enforce or
                    defend Schneider Electric's rights under these Terms of Use such
                    as but not limited in the frame of any litigation or other
                    proceedings, or (iii) to the extent such disclosure is necessary
                    to facilitate corporate governance, including mergers
                    acquisitions, divestitures or other corporate reorganization of
                    Schneider Electric or any of its Affiliates that processes or
                    has processed Customer Data and/or Customer's Clients Data.
                  </span>
                  <p>
                    In case Customer is not entitled to allow Schneider Electric to
                    disclose Customer Data or Customer’s Client Data pursuant to the
                    exceptions described in this Section 6.5, Customer shall be
                    solely and fully responsible to obtain any necessary permissions
                    or approvals from those legal or individual person(s) including
                    as applicable Customer's Clients, and from those authorities or
                    other third parties, which have the right and authority to grant
                    such permissions or approvals
                  </p>
                </li>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Personal Data and Privacy</h6>
              <ol>
                <li>
                  Each of Schneider Electric and Customer will comply with its
                  respective obligations under Data Protection Laws and must procure
                  compliance with any such laws by its respective Affiliates,
                  suppliers and users. Customer must ensure that all data processing
                  activities it performs when using the Services and the Digital
                  Offer comply with all applicable laws and regulations. Customer
                  must obtain all required consents and make all required
                  notifications for the performance of these Terms of Use, including
                  with respect to the use of cookies and similar technologies by
                  Customer or Customer’s Clients for their own purposes. Customer is
                  responsible for any Personal Data that Customer or Customer’s
                  Clients upload, download, collect, store, use, share, create,
                  modify, delete or otherwise process with the Application and/or
                  the Services
                </li>
                <li>
                  If and to the extent that Schneider Electric processes Personal
                  Data as Data Controller, Customer’s and Customer’s Clients
                  Personal Data will be processed as described in privacy notices
                  and policies made available by Schneider Electric and in Schneider
                  Electric’s Data Privacy & Cookie Policy published on
                  https://www.se.com/. You are advised to check these privacy
                  notices and privacy policies. Schneider Electric will act as an
                  independent controller and no joint controllership, or
                  equivalence, over the data processing activity may be assumed
                  between Schneider Electric, Customer and/or Customers’ Clients.
                </li>
                <li>
                  If and to the extent that Schneider Electric processes Personal
                  Data as Data Processor, Customer acts as Data Controller with
                  respect to any Personal Data comprised in Customer Data, except
                  when Customer acts as Data Processor with respect to its Clients’
                  Personal Data, in which case Schneider Electric is a
                  sub-processor.
    
                  <ol>
                    <li>
                      When Schneider Electric acts as a Data Processor or a
                      sub-processor, Schneider Electric will process Personal Data
                      only on documented instructions from Customer, including where
                      required by Data Protection Laws with respect to transfers of
                      Personal Data to third countries. Customer agrees that the
                      Services Description, the Documentation and these Terms of Use
                      constitute documented instructions. Customer’s instructions
                      must comply with applicable laws and regulations including
                      Data Protection Laws. Customer is responsible for the
                      accuracy, quality and legality of Personal Data and of the
                      data processing activity and for determining if the technical
                      and organizational measures meet the Customer’s requirements.
                      Schneider Electric will inform Customer if Schneider Electric
                      or its sub-processors can no longer meet their obligations
                      under this Section 7.3. Where required by Data Protection
                      Laws, Schneider Electric will inform Customer if it believes
                      that its instructions infringe Data Protection Laws.
                    </li>
                    <li>
                      Schneider Electric will ensure that the personnel involved in
                      processing Personal Data will be bound to maintain the
                      confidentiality and security of Personal Data and to act on
                      instructions from Customer.
                    </li>
                    <li>
                      To the extent Customer, in its use of the Services and the
                      Application, does not have the ability to address a request
                      from a data subject exercising their data protection rights,
                      Schneider Electric, taking into consideration the nature of
                      the processing activity, will provide reasonable assistance to
                      Customer, at Customer cost, for the fulfillment of Customer’s
                      obligations to address this request
                    </li>
                    <li>
                      Schneider Electric will notify Customer without undue delay
                      after becoming aware of a Personal Data breach and will assist
                      Customer in addressing the breach as appropriate taking into
                      consideration the circumstances of the breach, the nature of
                      the processing activity and the information available to
                      Schneider Electric.
                    </li>
                    <li>
                      Where required by Data Protection Laws, taking into
                      consideration the nature of the data processing activity and
                      the information available, Schneider Electric will assist
                      Customer, at Customer cost, for the fulfillment of Customer’s
                      obligations relating to security, data protection impact
                      assessments and consultation of data protection authorities.
                    </li>
                    <li>
                      Unless agreed otherwise in writing between Schneider Electric
                      and Customer, the details of the data processing are available
                      at the following link:
                      <a
                        href="https://schneiderelectric.sharepoint.com/:f:/r/sites/PublishedDigitalOfferLegalDocumentation/Shared%20Documents/Published%20digital%20offer%20legal%20documentation/Cloud%20Offer%20TOU/SE%20installer%20Portal?csf=1&web=1&e=2PAzeE"
                        >Link</a
                      >
                    </li>
                    <li>
                      Customer authorizes the use of the following sub-processors:
                      <ul class="romanList">
                        <li>
                          Sub-processors listed at the following link:
                          <a
                            href="https://schneiderelectric.sharepoint.com/:f:/r/sites/PublishedDigitalOfferLegalDocumentation/Shared%20Documents/Published%20digital%20offer%20legal%20documentation/Cloud%20Offer%20TOU/SE%20installer%20Portal?csf=1&web=1&e=2PAzeE"
                            >Link</a
                          >
                        </li>
                        <li>Schneider Electric Affiliates.</li>
                      </ul>
                      <p>
                        Customer agrees that Schneider Electric may involve other
                        sub-processors provided that Customer is given a fifteen
                        (15) calendar day prior written notice during which Customer
                        may provide grounded objection to the use of a new
                        sub-processor. In case such an objection is made by
                        Customer, Schneider Electric and Customer will attempt to
                        find an alternative solution. If it appears not possible for
                        Schneider Electric to continue providing the Services and
                        Application at the same conditions without processing
                        Personal Data by the objected-to sub-processor(s), then
                        Schneider Electric or the Customer will have the right to
                        terminate the Services in accordance with Section 13.1(b).
                      </p>
                      <p>
                        When engaging a sub-processor to carry-out specific data
                        processing activities, Schneider Electric will do so by way
                        of a written contract that provides in substance for the
                        same data protection obligations as those binding upon
                        Schneider Electric under these Terms of Use. Schneider
                        Electric will ensure that the sub-processor complies with
                        such obligations. Schneider Electric remains responsible
                        towards Customer for the performance of the sub-processor’s
                        obligations
                      </p>
                    </li>
                    <li>
                      Following a Customer’s request, at reasonable intervals or if
                      there are indications of non-compliance and subject to
                      confidentiality obligations, Schneider Electric will (i)
                      provide Customer with information necessary to demonstrate
                      compliance with Schneider Electric’s obligations under Data
                      Protection Laws and (ii) allow for and contribute to audits on
                      the compliance with such obligations, at Customer’s cost,
                      subject to a thirty (30) calendar day prior written notice
                      served to Schneider Electric. In deciding for a review of
                      information or an audit, Customer will take into consideration
                      certifications held by Schneider Electric. Audits may be
                      conducted by Customer or an independent auditor bound by
                      confidentiality, who is not a competitor of Schneider Electric
                      and does not have a conflict of interest.
                    </li>
                    <li>
                      At the end of the provision of the Services, Schneider
                      Electric will, at the choice of Customer, delete all Personal
                      Data processed on its behalf or on behalf of Customers’
                      Clients or return to Customer such Personal Data and delete
                      existing copies unless any applicable laws and regulations
                      require to continue storing it, in which case Schneider
                      Electric will maintain confidentiality and security of such
                      Personal Data in accordance with this Section 7.
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Technical and organizational measures.</b>
                  &nbsp;
                  <span
                    >Schneider Electric will implement technical and organizational
                    measures to ensure a security level for the Personal Data which
                    is appropriate and proportionate to the risk and which takes
                    into consideration the state of technological developments and
                    the cost of implementation. Such measures will address risks of
                    accidental or unlawful destruction, loss, alteration,
                    unauthorized disclosure of or access to Personal Data. Unless
                    agreed otherwise in writing between Schneider Electric and
                    Customer, the details of the technical and organizational
                    measures are available at the following link:
                    <a
                      href="https://schneiderelectric.sharepoint.com/:f:/r/sites/PublishedDigitalOfferLegalDocumentation/Shared%20Documents/Published%20digital%20offer%20legal%20documentation/Cloud%20Offer%20TOU/SE%20installer%20Portal?csf=1&web=1&e=2PAzeE"
                      >Link</a
                    >
                  </span>
                </li>
                <li>
                  <b> Data transfers.</b>
                  &nbsp;
                  <span
                    >Schneider Electric operates globally. Its Affiliates, teams and
                    suppliers involved in the provision of the Services and
                    Application and the performance of these Terms of Use can be
                    located in different geographies. The Standard Contractual
                    Clauses will apply to Personal Data that is transferred outside
                    of the EEA, the UK and Switzerland to a country not deemed as
                    providing an adequate level of protection by competent
                    institutions and authorities. The Standard Contractual Clauses
                    at
                    https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj
                    are incorporated to these Terms of Use by reference
                  </span>
                </li>
                <li>
                  <b> CCPA/CPRA. </b>
                  &nbsp;
                  <span
                    >For purposes of this Section, “California Privacy Laws” means
                    collectively, the California Consumer Privacy Act of 2018 (CCPA,
                    codified at Civil Code section 1798.100 et seq.) as amended by
                    the California Privacy Rights Act (CPRA), and all applicable
                    regulations issued by the California Attorney General and/or the
                    California Privacy Protection Agency implementing CCPA and CPRA.
                    Words used in the section shall have the meanings given to them
                    in the California Privacy Laws. To the extent the Customer Data
                    or Customer’s Client Data contain any “personal information”
                    subject to the California Privacy Laws, Schneider Electric will
                    process such personal information as a “service provider” or
                    “contractor” in accordance with the following terms:
                  </span>
    
                  <ul class="romanList">
                    <li>
                      Schneider Electric shall not “sell” or “share” the personal
                      information.
                    </li>
                    <li>
                      Schneider Electric shall only process the personal information
                      for the following Purposes: (1) to provide the services
                      contemplated by these Terms of Use, (2) for the specific
                      business purposes set forth in Section 6.1 above, to the
                      extent permitted by the California Privacy Laws, and (3) for
                      the following defined “business purposes” permitted by the
                      California Privacy Laws: auditing, ensuring security and
                      integrity, debugging, short term transient use (including as
                      needed to create deidentified data sets for research),
                      performing services, internal research, and undertaking
                      activities to verify or maintain the quality or safety of the
                      Schneider Electric’s products and services, and (4) for the
                      other purposes explicitly permitted by the California Privacy
                      Laws, namely retaining other service providers and/or
                      subcontractors, for internal use to build or improve the
                      quality of its products and services, to prevent, detect and
                      investigate data security incidents or protect against
                      malicious, deceptive, fraudulent or illegal conducts, and for
                      the purposes enumerated in Civil Code section
                      1798.145(a)(1)-(7).
                    </li>
                    <li>
                      Schneider Electric shall not retain, use or disclose the
                      personal information collected from Customer pursuant to these
                      Terms of Use (1) for any purposes or commercial purpose other
                      than the Purposes, including in the servicing of a different
                      business, unless expressly permitted by the California Privacy
                      Laws; (2) outside the direct business relationship between it
                      and Customer unless expressly permitted by the California
                      Privacy Laws
                    </li>
                    <li>
                      Schneider Electric shall comply with all sections of the
                      California Privacy Laws applicable to it as a service provider
                      or contractor
                    </li>
                    <li>
                      Schneider Electric grants Customer the right to take
                      reasonable and appropriate steps to ensure that it uses the
                      personal information in a manner consistent with the
                      Customer’s obligations under the California Privacy Laws.
                    </li>
                    <li>
                      Schneider Electric shall notify Customer after it makes a
                      determination that it can no longer meet its obligations under
                      the California Privacy Laws.
                    </li>
                    <li>
                      Schneider Electric grants Customer the right, upon notice, to
                      take reasonable and appropriate steps to stop and remediate
                      its unauthorized use of personal information.
                    </li>
                    <li>
                      Customer shall notify Schneider Electric of any consumer
                      request made pursuant to the CCPA that it must comply with and
                      provide the information necessary for Schneider Electric to
                      comply with the request.
                    </li>
                    <li>
                      To the extent that Schneider Electric subcontracts with
                      another person in providing services to Customer, Schneider
                      Electric shall have a contract with the subcontractor that
                      complies with the California Privacy Laws.
                    </li>
                  </ul>
                  <p>
                    Customer and Schneider Electric further agree that, should the
                    Regulations implementing the California Privacy Laws require
                    additional mandatory terms for service provider contracts, this
                    Section 7.6 shall automatically incorporate those terms by
                    reference.
                  </p>
                </li>
              </ol>
            </section>
          </li>
          <li class="headerListItem">
            <section>
              <h6>Intellectual Property Rights</h6>
              <ol>
                <li>
                    All right, title and interest in and to the Application, the Cloud Platform, the Services, any and all hardware, software and
                    other items used by Schneider Electric or any Schneider Electric Affiliate to provide the Services as well as any technology 
                    or know-how embodied or otherwise implemented in the Application, the Cloud Platform, the Services and/or such other 
                    items, and all Intellectual Property Rights pertaining thereto, are and shall remain the full and sole property of Schneider 
                    Electric and/or, if applicable, its relevant Affiliate, third-party licensors or hosting infrastructure providers. No title to or 
                    ownership of any Intellectual Property Rights related to the Application, the Cloud Platform or the Services is transferred or 
                    shall be deemed to be conveyed to You or any third-party pursuant to these Terms of Use or under any business transaction 
                    performed between You and Schneider Electric or any Schneider Electric Affiliate in pursuance of the Services Contract or 
                    the Order Confirmation. Where the Application or, as applicable, the Services is made available through a Cloud Platform that
                    is hosted or otherwise operated by a third-party licensor of Schneider Electric or any Schneider Electric Affiliate, You may be 
                    required to agree and accept such third-party licensor’s or Schneider Electric Affiliate’s terms and conditions upon accessing 
                    said Cloud Platform.
                  
                  All rights
                  not explicitly granted to You under these Terms of Use regarding
                  the Application, the Cloud Platform and the Services, are reserved
                  by Schneider Electric.
                </li>
                <li>
                  2 All Intellectual Property Rights pertaining to any third-party
                  software embedded or implemented in the Cloud Platform, the
                  Application or the Services or otherwise provided to You with the
                  Cloud Platform, the Application or the Services, shall remain
                  vested in the relevant third party and there will be no deemed or
                  implied transfer of ownership to You of such third-party
                  proprietary rights.
                </li>
                <li>
                  All trademarks on the Application, the Cloud Platform, the
                  Services and any and all hardware, software and other items used
                  by Schneider Electric to provide the Services, are registered
                  trademarks of Schneider Electric and/or its Affiliates or its
                  licensors or are otherwise protected under any applicable
                  Intellectual Property Rights, and may not be copied, reproduced or
                  used, in whole or in part, without Schneider Electric's prior
                  written permission. The same shall apply to all page headers,
                  custom graphics, button icons and scripts displayed or otherwise
                  available on the Application, the Cloud Platform, the Services
                  and/or any and all hardware, software and other items used by
                  Schneider Electric to provide the Services. No transfer or grant
                  of rights under any such trademark, page headers, custom graphics,
                  button icons and scripts or any related Intellectual Property
                  Rights is made or implied by any provision of these Terms of Use,
                  Your access to the Cloud Platform or Your use of the Services or
                  the Application.
                </li>
                <li>
                  If any third-party software not provided by Schneider Electric or
                  Schneider Electric Affiliates is necessary for Your use of the
                  Digital Offer and/or the Services in Your environment, You shall
                  be responsible for obtaining and complying with the license terms
                  and conditions set forth by the third-party vendor(s) of such
                  software. Schneider Electric shall in no event bear any liability
                  in consequence of Your failure to comply with said license terms
                  and conditions.
                </li>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Warranties and disclaimers of warranty</h6>
              <ol>
                <li>
                  SCHNEIDER ELECTRIC AGREES TO PROVIDE THE SERVICES TO THE CUSTOMER
                  USING A COMMERCIALLY REASONABLE LEVEL OF SKILL AND CARE, AND IN
                  ACCORDANCE WITH THE THEN-CURRENT APPLICABLE SERVICES DESCRIPTION
                  AND THE ORDER CONFIRMATION.
                </li>
                <li>
                    <p>IN THE CASE THE SERVICES SHALL BE PROVIDED BY SCHNEIDER ELECTRIC ON A FREE OF CHARGE 
                        BASIS FOR WHOLE OR PART OF THE SERVICES PERIOD, THE FOLLOWING SHALL APPLY FOR EACH 
                        RELEASE OF THE APPLICATION ISSUED BY SCHNEIDER ELECTRIC:
                    </p>
                    <p>
                        FOR THE WHOLE PERIOD RUNNING UNTIL SCHNEIDER ELECTRIC RELEASES THE NEXT UPDATE OR 
                        UPGRADE OF THE APPLICATION (WARRANTY PERIOD), SCHNEIDER ELECTRIC WARRANTS THAT THE 
                        SERVICES WILL SUBSTANTIALLY PERFORM THE FUNCTIONALITIES DESCRIBED IN THE THEN-CURRENT 
                        APPLICABLE SERVICES DESCRIPTION. THE CUSTOMER'S SOLE AND EXCLUSIVE REMEDY AND SCHNEIDER 
                        ELECTRIC'S ENTIRE LIABILITY FOR A BREACH OF THIS WARRANTY SHALL BE FOR SCHNEIDER ELECTRIC, 
                        AT NO FURTHER CHARGE TO CUSTOMER, TO USE TECHNICALLY AND COMMERCIALLY REASONABLE 
                        EFFORTS TO PROVIDE A PATCHED VERSION OF THE APPLICATION OR A WORKAROUND RELATING TO THE 
                        USE OF THE SERVICES SUCH AS, IN PARTICULAR, AT SCHNEIDER ELECTRIC’S SOLE DISCRETION, THE USE 
                        OF THE SERVICES IN DOWNGRADED MODE, PROVIDED THAT (i) THE CUSTOMER GIVES NOTICE IN 
                        WRITING OF THE WARRANTY BREACH TO SCHNEIDER ELECTRIC WITHIN THE ABOVEMENTIONED 
                        WARRANTY PERIOD, AND (ii) THE WARRANTY BREACH DOES NOT FALL UNDER THE EXCLUSIONS 
                        DEFINED IN SECTION 9.4 BELOW. 

                    </p>
                </li>
                <li>
                  <p>
                    IN THE CASE THE SERVICES SHALL BE PROVIDED BY SCHNEIDER ELECTRIC AGAINST PAYMENT BY 
                    CUSTOMER OF ANY FEE AMOUNT AND SUCH FEE INCLUDES THE MAINTENANCE OF THE DIGITAL OFFER, 
                    THE FOLLOWING SHALL APPLY FOR EACH RELEASE OF THE APPLICATION ISSUED BY SCHNEIDER 
                    ELECTRIC:

                  </p>
                  <p>
                    AS PART OF THE DIGITAL OFFER’S MAINTENANCE PROVIDED DURING THE SERVICES PERIOD AGAINST 
                    PAYMENT BY THE CUSTOMER OF THE FEE APPLICABLE TO THE SERVICES, SCHNEIDER ELECTRIC 
                    WARRANTS THAT THE DIGITAL OFFER WILL SUBSTANTIALLY PERFORM IN ACCORDANCE WITH ITS 
                    DOCUMENTATION AND THE SERVICES WILL SUBSTANTIALLY PERFORM THE FUNCTIONALITIES 
                    DESCRIBED IN THE THEN-CURRENT APPLICABLE SERVICES DESCRIPTION AND, WHEN APPLICABLE, THE 
                    ORDER CONFIRMATION. THE CUSTOMER'S SOLE AND EXCLUSIVE REMEDY AND SCHNEIDER ELECTRIC'S 
                    SOLE LIABILITY FOR A BREACH OF THIS WARRANTY SHALL BE THE IMPLEMENTATION BY SCHNEIDER 
                    ELECTRIC OF TECHNICALLY AND COMMERCIALLY REASONABLE EFFORTS TO PROVIDE A PATCHED 
                    VERSION OF THE APPLICATION OR A WORKAROUND RELATING TO THE USE OF THE SERVICES SUCH AS, 
                    IN PARTICULAR, AT SCHNEIDER ELECTRIC’S SOLE DISCRETION, THE USE OF THE DIGITAL OFFER AND THE 
                    SERVICES IN DOWNGRADED MODE, PROVIDED THAT (i) THE CUSTOMER GIVES NOTICE IN WRITING OF 
                    THE WARRANTY BREACH TO SCHNEIDER ELECTRIC WITHIN THE SERVICES PERIOD, AND (ii) THE 
                    WARRANTY BREACH DOES NOT FALL UNDER THE EXCLUSIONS DEFINED IN SECTION 9.4 BELOW.

                  </p>
                </li>
                <li>
                    <p>
                        SCHNEIDER ELECTRIC’s WARRANTY SHALL BE EXCLUDED TO THE EXTENT THE DIGITAL OFFER AND/OR 
THE SERVICES FAIL TO PERFORM IN ACCORDANCE WITH THE DOCUMENTATION, THE SERVICES 
DESCRIPTION AND/OR, WHEN APPLICABLE, THE ORDER CONFIRMATION, AS THE RESULT OF: (a) THE 
CUSTOMER’S MISUSE OF THE DIGITAL OFFER; OR (b) THE UNAUTHORIZED CONFIGURATION, 
PARAMETERING, COMMISSIONING OR USE OF THE DIGITAL OFFER INCLUDING BUT NOT LIMITED TO THE 
USE OF THE DIGITAL OFFER WITH ANY HARDWARE, SOFTWARE, FIRMWARE, OPERATING SYSTEM OR 
SOLUTION NOT SPECIFIED OR NOT APPROVED BY SCHNEIDER ELECTRIC TO BE USED WITH THE DIGITAL 
OFFER; OR (c) THE UNAUTHORIZED MAINTENANCE OF THE DIGITAL OFFER; OR (d) THE CUSTOMER FAILED 
TO IMPLEMENT OR USE ANY UPDATE OR UPGRADE TO THE DIGITAL OFFER THAT SCHNEIDER ELECTRIC 
MADE AVAILABLE TO THE CUSTOMER IN ANY MANNER WHATSOEVER
                    </p>
                </li>
                <li>
                    <p>
                        NOTWITHSTANDING THE FOREGOING, SCHNEIDER ELECTRIC DOES NOT WARRANT OR PROMISE THAT 
                        THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT ALL FAILURES OR DEFECTS OF THE 
                        SERVICES WILL BE CORRECTED. 
                    </p>
                    <p>
                        THE CUSTOMER UNDERSTANDS THAT THE SERVICES MAY BE INTERRUPTED OR COMPLETELY 
                        UNAVAILABLE FOR PERIODS OF TIME DUE TO CERTAIN CAUSES WHICH MAY INCLUDE, WITHOUT 
                        LIMITATION, SCHEDULED OR UNSCHEDULED MAINTENANCE WORK OF THE APPLICATION AND/OR THE 
                        CLOUD PLATFORM. ONLY SCHEDULED MAINTENANCE WORKS WILL GIVE RISE TO PRIOR NOTIFICATION 
                        BY SCHNEIDER ELECTRIC.
                    </p>
                    <p>
                        ALTHOUGH SCHNEIDER ELECTRIC SHALL MAKE EVERY REASONABLE ENDEAVOR TO KEEP THE 
                        APPLICATION, THE CLOUD PLATFORM AND THE SERVICES FREE FROM VIRUSES OR OTHER 
                        CONTAMINATION FEATURES, DUE TO THE INHERENT RISKS OF TELECOMMUNICATION NETWORKS AND 
                        THE INTERNET, SCHNEIDER ELECTRIC CANNOT WARRANT THAT THE APPLICATION, THE CLOUD 
                        PLATFORM AND THE SERVICES SHALL BE FREE OF VULNERABILITIES OR CYBER THREATS OR 
                        PROTECTED AGAINST ALL VIRUSES OR OTHER CONTAMINATION FACTORS THAT MAY THREATEN THE 
                        SECURITY OR INTEGRITY OF THE DIGITAL OFFER, THE SERVICES, CUSTOMER DATA OR CUSTOMER’ S 
                        CLIENTS DATA.
                    </p>

                    <p>
                        CUSTOMER ACKNOWLEDGES THAT ACCESS TO THE APPLICATION, THE CLOUD PLATFORM AND/OR USE 
                        OF THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS AND POTENTIAL FAILURE DUE TO THE 
                        INHERENT RISKS OF TELECOMMUNICATION NETWORKS AND THE INTERNET. SCHNEIDER ELECTRIC 
                        SHALL NOT BE RESPONSIBLE FOR ANY DELAY OR FAILURE IN THE SERVICES OR ANY LOSS OR DAMAGE 
                        RESULTING FROM THE AFORESAID LIMITATIONS, DELAYS AND POTENTIAL FAILURES.
                    </p>
                </li>
                <li>
                    <p>
                        WITHOUT PREJUDICE TO ANY STATUTORY WARRANTIES THAT CANNOT BE EXCLUDED AND TO THE 
                        FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ALL OTHER WARRANTIES, REPRESENTATIONS OR 
                        CONDITIONS, EXPRESS OR IMPLIED, EITHER IN FACT OR BY OPERATION OF LAW OR OTHERWISE, 
                        INCLUDING WARRANTIES OR CONDITIONS OF MERCHANTABILITY, SATISFACTORY QUALITY, NONINFRINGEMENT OF THIRD- PARTY RIGHTS AND FITNESS OR ACCURACY FOR CUSTOMER'S PARTICULAR 
                        PURPOSE, REQUIREMENTS, SPECIFICATIONS OR EXPECTATIONS, ARE EXCLUDED REGARDING THE 
                        DIGITAL OFFER AND THE SERVICES.
                    </p>
                </li>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Limitations of liability</h6>
              <ol>
                <li>
                  IN NO EVENT SHALL SCHNEIDER ELECTRIC NOR ITS AFFILIATES, LICENSORS
                  OR CONTRACTORS INVOLVED IN THE CREATION, PRODUCTION OR DELIVERY OF
                  THE CLOUD PLATFORM, THE APPLICATION, THE SERVICES OR ANY COMPONENT
                  THEREOF, BE LIABLE TO THE CUSTOMER OR ANY THIRD PARTY FOR ANY
                  INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES,
                  LOSS OF REVENUE, LOSS OF BUSINESS, LOSS OF PROFITS OR CONTRACTS,
                  LOSS OF ANTICIPATED SAVINGS, BUSINESS INTERRUPTION, LOSS OF
                  AVAILABILITY OF OR CORRUPTION OR DAMAGE TO ANY DATA (INCLUDING
                  CUSTOMER DATA OR CUSTOMER's CLIENT DATA), LOSS OF REPUTATION OR
                  WASTE OF MANAGEMENT OR OFFICE TIME, ARISING OUT OF OR IN
                  CONNECTION WITH THE CUSTOMER'S ACCESS TO OR INABILITY TO ACCESS
                  THE CLOUD PLATFORM OR THE CUSTOMER'S USE OR INABILITY TO USE THE
                  SERVICES OR ANY MATERIAL, DOCUMENT OR INFORMATION MADE AVAILABLE
                  OR OTHERWISE PROVIDED TO YOU FROM THE CUSTOMER'S USE OF THE CLOUD
                  PLATFORM, THE APPLICATION, THE SERVICES OR ANY COMPONENT THEREOF,
                  WHETHER BASED ON CONTRACT, TORT, WARRANTY OR OTHER LEGAL GROUND
                  AND EVEN IF SCHNEIDER ELECTRIC HAS BEEN ADVISED OF THE POSSIBILITY
                  OF SUCH DAMAGES.
                </li>
                <li>
                  EXCEPT IN CASE OF (i) SCHNEIDER ELECTRIC'S FRAUD OR FRAUDULENT
                  MISREPRESENTATION, (ii) SCHNEIDER ELECTRIC'S WILFUL MISCONDUCT OR
                  GROSS NEGLIGENCE (TO THE EXTENT LIABILITY FOR GROSS NEGLIGENCE CAN
                  BE EXCLUDED UNDER APPLICABLE LAW), OR (iii) DEATH OR INJURY TO
                  INDIVIDUAL PERSON(S), WHERE NO LIMITATION OF LIABILITY SHALL
                  APPLY, SCHNEIDER ELECTRIC'S TOTAL LIABILITY ARISING OUT OF OR IN
                  CONNECTION WITH THESE TERMS OF USE, THE CLOUD PLATFORM, THE
                  APPLICATION, THE SERVICES OR ANY COMPONENT THEREOF, SHALL BE
                  LIMITED TO AND SHALL IN NO EVENT EXCEED (I) THE AMOUNT PAID BY
                  CUSTOMER TO SCHNEIDER ELECTRIC OR SCHNEIDER ELECTRIC AFFILIATE FOR
                  THE SERVICES WITH RESPECT TO WHICH LIABILITY IS CLAIMED OVER THE
                  TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE LIABILITY
                  CLAIM, OR (II) TWENTY-FIVE DOLLARS ($25 USD) IN THE CASE WHERE THE
                  SERVICES ARE PROVIDED FREE OF CHARGE TO CUSTOMER BY SCHNEIDER
                  ELECTRIC OR SCHNEIDER ELECTRIC AFFILIATE.
                </li>
                <li>
                  SCHNEIDER ELECTRIC SHALL NOT BE LIABLE TO THE CUSTOMER OR ANY
                  THIRD PARTY FOR ANY DAMAGE, LOSS, PENALTY, SURCHARGES, INTEREST OR
                  MONETARY LIABILITIES ARISING OUT OF OR IN CONNECTION WITH THE
                  CUSTOMER'S ACCESS TO OR INABILITY TO ACCESS THE APPLICATION OR THE
                  CLOUD PLATFORM OR THE CUSTOMER'S USE OR INABILITY TO USE THE
                  SERVICES OR ANY MATERIAL, DOCUMENT OR INFORMATION MADE AVAILABLE
                  OR OTHERWISE PROVIDED TO THE CUSTOMER FROM ITS USE OF THE
                  SERVICES, THAT IS DUE TO (i) THE SUPPLY BY THE CUSTOMER OR ANY
                  THIRD PARTY NOT BEING UNDER THE CONTROL OF SCHNEIDER ELECTRIC OF
                  INCORRECT OR INCOMPLETE INFORMATION, (ii) THE FAILURE BY THE
                  CUSTOMER OR ANY THIRD PARTY NOT BEING UNDER CONTROL OF SCHNEIDER
                  ELECTRIC TO SUPPLY ANY REQUISITE INFORMATION REQUESTED BY
                  SCHNEIDER ELECTRIC, (iii) ANY ACT, OMISSION, NON-PERFORMANCE OR
                  DELAY ATTRIBUTABLE TO OR CAUSED BY THE CUSTOMER, ITS
                  SUBCONTRACTORS, EMPLOYEES OR ANY OTHER THIRD PARTY BEING UNDER THE
                  CUSTOMER'S CONTROL, OR (v) ANY MATTER OUTSIDE CONTROL OF SCHNEIDER
                  ELECTRIC.
                </li>
                <li>
                  SCHNEIDER ELECTRIC'S LIABILITY ARISING OUT OF THESE TERMS OF USE
                  IS REDUCED PROPORTIONALLY TO THE EXTENT TO WHICH THE ACT OR
                  OMISSION OF YOU OR ANY OTHER THIRD PARTY CONTRIBUTED TO THE LOSS
                  OR DAMAGE INCURRED.
                </li>
                <li>
                  SCHNEIDER ELECTRIC SHALL NOT BE LIABLE TO THE CUSTOMER OR ANY
                  THIRD PARTY FOR THE USE OF ANY CUSTOMER DATA OR CUSTOMER's CLIENTS
                  DATA BY THE CUSTOMER OR ANY THIRD PARTY NOT BEING UNDER CONTROL OF
                  SCHNEIDER ELECTRIC, SUCH AS BUT NOT LIMITED TO CUSTOMER DATA AND
                  CUSTOMER's CLIENTS DATA THAT ARE CREATED OR OTHERWISE PROCESSED AS
                  PART OF THE SERVICES OR IN CONNECTION WITH THE CUSTOMER'S USE OF
                  THE SERVICES. SCHNEIDER ELECTRIC SHALL ALSO NOT BE LIABLE FOR THE
                  CONSEQUENCES OF ANY DECISION, ACT OR OMISSION, SUCH AS BUT NOT
                  LIMITED TO THE ASSEMBLY, INSTALLATION OR MAINTENANCE OF ANY
                  EQUIPMENT, UTILITY, PROCESS, INFRASTRUCTURE, BUILDING, FACILITY OR
                  OTHER ASSET, THAT THE CUSTOMER OR ANY THIRD-PARTY MAY MAKE ON
                  BASIS OF ANY CUSTOMER DATA OR CUSTOMER's CLIENTS DATA
                </li>
                <li>
                  IN CASE THE RIGHT TO ACCESS AND USE THE SERVICES WAS FURNISHED TO
                  YOU BY ANY AUTHORIZED SCHNEIDER ELECTRIC DISTRIBUTOR OR RESELLER
                  OR ANY SCHNEIDER ELECTRIC CERTIFIED PARTNER, WITH OR WITHOUT A
                  THIRD-PARTY PRODUCT, SCHNEIDER ELECTRIC SHALL IN NO EVENT BE A
                  PARTY TO ANY PURCHASE ORDER OR OTHER AGREEMENT BETWEEN YOU AND
                  SUCH DISTRIBUTOR, RESELLER OR PARTNER AND SHALL NOT ASSUME OR
                  OTHERWISE BEAR ANY LIABILITY THEREUNDER.
                </li>
                <li>
                  SCHNEIDER ELECTRIC SHALL NOT BEAR ANY LIABILITY AND MAKES NO
                  REPRESENTATION, NOR PROVIDES ANY WARRANTY, THAT THE DIGITAL OFFER
                  AND THE SERVICES WILL BE ACCESSIBLE, AVAILABLE OR APPROPRIATE FOR
                  ANY OR ALL OF CURRENT OR POTENTIAL CUSTOMERS (INCLUDING YOU) IN
                  ALL GEOGRAPHIC LOCATIONS IN THE WORLD.
                </li>
                <li>
                  THE EXCLUSIONS, LIMITATIONS AND DISCLAIMERS OF WARRANTY OR
                  LIABILITY STATED IN THESE TERMS OF USE SHALL APPLY ONLY TO THE
                  EXTENT PERMITTED BY THE LAW APPLICABLE TO THESE TERMS OF USE AND
                  DO NOT AFFECT OR PREJUDICE STATUTORY RIGHTS WHICH, AS THE CASE MAY
                  BE, BENEFIT YOU UNDER MANDATORY OR PUBLIC ORDER LAWS OR
                  REGULATIONS APPLICABLE IN ANY COUNTRY FROM WHERE YOU WILL ACCESS
                  AND/OR WHERE YOU WILL USE THE SERVICES.
                </li>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Indemnification</h6>
              <ol>
                <li>
                  You shall defend, indemnify, and hold harmless Schneider Electric,
                  its Affiliates and each of their respective officers, directors,
                  employees, licensors, contractors, successors and assigns
                  (“Schneider Electric Indemnitees”) from and against any and all
                  claims, actions, suits, demands, proceedings, judgments,
                  liabilities, losses, damages, costs and expenses (including
                  without limitation reasonable attorneys' fees and court costs)
                  brought or claimed against Schneider Electric Indemnitees by any
                  third-party, arising from or related to: (i) any Customer Data or
                  Customer's Clients Data; (ii) any breach by You of any of Your
                  obligations under these Terms of Use such as but not limited to
                  any unauthorized access to the Digital Offer and/or any
                  unauthorized use of the Services; (iii) any misuse by You of the
                  Digital Offer and/or the Services such as but not limited to Your
                  use of the Digital Offer and/or the Services in a manner contrary
                  to the Documentation, the Services Description or any other
                  instruction given to You by Schneider Electric, (iv) any failure
                  by You to comply with any applicable law and/or regulation
                  including without limitation any failure of You to obtain all
                  legally required consents to upload and/or otherwise use Customer
                  Data or Customer's Clients Data in connection with Your use of the
                  Digital Offer and/or the Services, (v) any use of the Digital
                  Offer and/or the Services in combination with any equipment,
                  software or solution not supplied, not specified or otherwise not
                  approved by Schneider Electric; (vi) any modification or
                  alteration of the Digital Offer and/or the Services by anyone
                  other than Schneider Electric or its subcontractors without the
                  written approval of Schneider Electric; and/or (vii) any claim
                  that any information (including but not limited to Customer Data
                  or Customer's Clients Data), designs, specifications,
                  instructions, software, service, data, hardware or material
                  furnished or otherwise made available by Customer to Schneider
                  Electric, its Affiliates or subcontractors in connection with or
                  for the purpose of the provision of the Services or otherwise used
                  or uploaded by Customer in connection with the Digital Offer
                  and/or the Services, infringe(s) or misappropriate(s) the
                  Intellectual Property Rights or other right or interest of any
                  third party. The foregoing shall apply regardless of whether such
                  damage is caused by the conduct of Customer and/or its Authorized
                  Users or by the conduct of any third-party using Customer's User
                  Account.
                </li>
                <li>
                  Subject to the limitations set forth in Section 10, Schneider
                  Electric will defend and indemnify You against a third party claim
                  that the Digital Offer and/or the Services infringe any
                  Intellectual Property Right enforceable in the jurisdiction where
                  Schneider Electric has its registered office or principal place of
                  business, or misappropriates any trade secret protected under the
                  laws of such jurisdiction (the "Included Jurisdiction")
                  hereinafter the "IP Claim", provided that (i) You notify Schneide
                  r Electric of the IP Claim in writing immediately upon Your
                  awareness of such IP Claim; (ii) You give Schneider Electric sole
                  authority and control of the defense of such IP Claim and all
                  related settlement negotiations; and (iii) You provide the
                  assistance, information and authority necessary in order for
                  Schneider Electric to handle the defense and settlement of such IP
                  Claim and perform its obligations under this Section 11.2. If the
                  Digital Offer and/or the Services is held or believed by Schneider
                  Electric to constitute an infringement or misappropriation as per
                  this Section 11.2, then Schneider Electric will have the option,
                  at its expense, to: (i) obtain for You the right to continue using
                  the Digital Offer and/or as applicable, the Services; (ii) replace
                  or modify the Digital Offer and/or, as applicable, the Services to
                  make it/them non-infringing; or (iii) if in Schneider Electric's
                  sole discretion, it is not economically or commercially reasonable
                  to perform either (i) or (ii) above, then Schneider Electric may
                  terminate Your right to access the Digital Offer and to use the
                  Services and refund to You any amount for the Services You would
                  have pre-paid to Schneider Electric or Schneider Electric
                  Affiliate on a pro-rated basis for the corresponding unused
                  portion of the Services Period. This Section 11.2. states Your
                  sole and exclusive remedy against Schneider Electric and Schneider
                  Electric's sole liability for any IP Claim under this Section
                  11.2.
                </li>
                <li>
                  To the extent permitted by law, Schneider Electric will have no
                  liability to the Customer under Section 11.2. of these Terms of
                  Use for any IP Claim that arises out of: (i) any Customer Data or
                  Customer's Clients Data; (ii) any breach by You of any of Your
                  obligations under these Terms of Use such as but not limited to
                  any unauthorized access to the Digital Offer and/or any
                  unauthorized use of the Services; (iii) any misuse by You of the
                  Digital Offer and/or the Services such as but not limited to Your
                  use of the Digital Offer and/or the Services in a manner contrary
                  to the Documentation, the Services Description or any other
                  instruction given to You by Schneider Electric; (iv) any failure
                  by You to comply with any applicable law and/or regulation
                  including without limitation any failure of You to obtain all
                  legally required consents to upload and/or otherwise use the
                  Customer Data or Customer's Clients Data in connection with Your
                  use of the Digital Offer and/or the Services; (v) any use of the
                  Digital Offer and/or the Services in combination with any
                  equipment, software or solution not supplied, specified or
                  otherwise approved by Schneider Electric; (vi) any modification or
                  alteration of the Digital Offer and/or the Services by anyone
                  other than Schneider Electric or its subcontractors without the
                  written approval of Schneider Electric; (vii) the access to the
                  Digital Offer and/or the use of the Services by the Customer after
                  notice by Schneider Electric or any appropriate authority to the
                  Customer of the alleged or actual infringement or misappropriation
                  of any third party's Intellectual Propert y Right by the Digital
                  Offer and/or the Services, or (viii) Customer's failure to use
                  corrections or enhancements to the Digital Offer and/or the
                  Services made available by Schneider Electric.
                </li>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Third-party websites and contents</h6>
              <ol>
                In case the Digital Offer and/or the Services indicate the web
                address of or redirect Customer to third parties' websites or third
                parties' content, products, services or information, or enable
                Customer to transmit Customer Data or Customer's Clients Data to
                third parties' websites, Customer shall bear all risks associated
                with its access to and use of such third parties' websites and third
                parties' contents, products, services and information. Schneider
                Electric does not control and is not responsible for such third
                parties' websites or any such third parties' content, products,
                services and information accessible from or provided through such
                websites. Any access to third parties' websites or any use of third
                parties' contents, products, services or information through Your
                access to the Digital Offer and/or Your use of the Services shall be
                subject to said third parties' own terms of use or other legal
                document which shall govern the relationship between You and said
                third parties with respect to such websites, contents products,
                services and information.
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Term, suspension and termination</h6>
              <ol>
                <li>
                  The legal agreement formed with Schneider Electric by Customer's
                  acceptance of these Terms of Use is valid for the duration of the
                  applicable Services Period specified in the Order Confirmation,
                  including any renewals thereof, and shall expire upon expiration
                  date of the Services Period in result, as the case may apply, of
                  Customer’s written notice to Schneider Electric or its Affiliate
                  having issued the Order Confirmation of Customer’s decision not to
                  renew the Services Period. Said legal agreement shall also
                  terminate:
                  
                  (a) upon electronic termination by Customer of its
                  subscription to the Services if and when such possibility shall be
                  made available to Customer by Schneider Electric or its relevant
                  Affiliate on the Cloud Platform and/or the web site(s) and/or
                  online portals through which Customer is given access to the
                  Digital Offer and/or the Services, or (b) upon request for
                  termination by Customer or Schneider Electric when Customer or
                  Customer’s Client acts as Data Controller and Schneider Electric
                  acts as a Data Processor or a sub-processor on their behalf in
                  accordance with Section 7.3 of these Terms of Use, and (i) Data
                  Controller objects to the addition or change of one or more
                  sub-processor(s) that Schneider Electric requires to process
                  Personal Data on Data Controller's behalf, and (ii) Schneider
                  Electric cannot continue to provide the Services without
                  processing of Personal Data by the objected-to sub-processor(s).
                  Termination in accordance with this Section 13.1 shall not trigger
                  liability for either Customer or Schneider Electric and shall not
                  give rise to any refund obligation to Customer of any kind.
                </li>
                <li>
                  <p>
                    Without prejudice to any other rights and remedies it may have
                    under these Terms of Use, at law or otherwise, Schneider
                    Electric reserves the right to suspend the Services (in whole or
                    in part) at any time with immediate effect by written notice to
                    the Customer if:
                  </p>
                    <ul class="romanList">
                      <li>in Schneider Electric's reasonable judgment,
                        the Digital Offer and/or the Services or any component thereof
                        are about to suffer a threat to security or functionality; or
                      </li>
                      <li>Schneider Electric has requested but has not received from
                        the Customer any information required to enable Schneider
                        Electric to perform the Services; or 
                      </li>
                      <li>if any sum payable by
                        Customer to Schneider Electric or Schneider Electric Affiliate
                        under the Order Confirmation is in arrears for more than thirty
                        (30) calendar days after the due date; or</li>
                      <li>the Customer
                        violates the provisions of these Terms of Use and/or any
                        documents expressly incorporated or referred to herein; or </li>
                      <li> the Customer has otherwise breached or failed to comply with any
                        of its obligations under the Order Confirmation and has not
                        cured such breach or failure within a period of thirty (30)
                        calendar days from the date of receipt of a written notice from
                        Schneider Electric or Schneider Electric Affiliate specifying
                        the breach or failure and requiring its remedy.
                      </li>
                    </ul>
                    
                   
                  
                  <p>
                    In any such event of suspension of the Services, the Customer
                    shall remain liable to pay the sums payable to Schneider
                    Electric or Schneider Electric Affiliate(s) under the suspended
                    Services and any resumption of the suspended Services shall be
                    conditional upon the cause giving rise to the suspension of the
                    Services being remedied and the Customer complying with such
                    requirements as Schneider Electric may reasonably specify in its
                    suspension notice to Customer
                  </p>
                  <p>
                    Further, if the Customer shall fail to cure the cause giving
                    rise to the suspension or to effect the remedial action required
                    by Schneider Electric within such period of time as Schneider
                    Electric shall specify in its suspension notice, Schneider
                    Electri c may, in addition to any other rights and remedies that
                    Schneider Electric or Schneider Electric Affiliate(s) may have,
                    terminate the Services permanently without Schneider Electric’s
                    liability or refund obligation to Customer of any kind;
                    termination of the Services by Schneider Electric shall be
                    achieved by disabling Customer's User Account or by any other
                    technical means that Schneider Electric shall reasonably
                    determine to disable Customer's access to the Services.
                  </p>
                </li>
                <li>
                  <p>
                    Within thirty (30) calendar days from effective date of
                    expiration or termination of Services in accordance with
                    Sections 13.1 and 13.2 above, it shall be Customer's
                    responsibility to make, as it may elect to do so, a request to
                    Schneider Electric for the extraction and return or transfer to
                    a third-party designated by Customer of whole or part of the
                    Customer Data and Customer's Clients Data in accordance with and
                    subject to the conditions defined in these Terms of Use, notably
                    the provisions of Section 6.3 which shall identically apply for
                    the purposes of this Section 13.3. Customer acknowledges and
                    agrees that such extraction and return or transfer by Schneider
                    Electric may be subject to prior consent and/or limitations
                    imposed to Schneider Electric by its third-party hosting service
                    providers or third-party licensors.
                  </p>
                  <p>
                    When such extraction and return or transfer by Schneider
                    Electric are subject to prior consent and/or limitations or
                    specific regulatory requirements or standards imposed to
                    Customer or Customer’s Client, Customer shall represent that
                    they have obtained all consents and approvals as well as
                    provided to Schneider Electric all information required to
                    proceed with such extraction and return or transfer in
                    compliance with any such requirements or standards. Customer
                    agrees to indemnify and hold Schneider Electric harmless from
                    any third-party claims and any costs and other amounts that
                    Schneider Electric may incur or otherwise be subject to because
                    of Customer's breach of this Section 13.3.
                  </p>
                  <p>
                    Schneider Electric shall not bear any obligation to ensure that
                    the extracted and returned or transferred Customer Data and
                    Customer's Clients Data are available to Customer under any
                    specific format. Customer agrees that, after thirty (30)
                    calendar days from the effective date of expiration or
                    termination of Services, Schneider Electric shall be fully and
                    automatically entitled to delete from the Cloud Platform and the
                    Application any Customer Data and Customer's Clients Data for
                    which Schneider Electric does not receive Customer’s express
                    request for extraction and return or transfer in accordance with
                    the foregoing provisions, and Schneider Electric shall not bear
                    any additional obligation to continue to hold, store, archive,
                    extract and return or transfer any such remaining Customer Data
                    and Customer's Clients Data. Schneider Electric shall have no
                    liability whatsoever for the deletion of any such remaining
                    Customer Data and Customer's Clients Data pursuant to these
                    Terms of Use.
                  </p>
                </li>
                <li>
                  Expiration of the Services Period or termination of the Services
                  shall automatically and immediately cause (i) termination of
                  Customer's right to access and use the Services, and (ii)
                  termination of the legal agreement which was formed with Schneider
                  Electric by Customer's acceptance of these Terms of Use. Without
                  prejudice to the foregoing, expiration or termination of the
                  Services shall not affect any rights or remedies which may have
                  accrued to Customer or Schneider Electric under these Terms of
                  Use, at law or, if applicable, in equity, with respect to the
                  Services provided before said expiration or termination.
                </li>
                <li>
                  Sub-section 2.5 of Section 2 "User Account", Section 8
                  “Intellectual Property Rights”, Section 9 “Warranties and
                  Disclaimers of warranty”, Section 10 “Limitations of liability”,
                  Section 11 “Indemnification”, Section 12 "Third-party websites and
                  contents", Section14 “Export Control” and Section 16 "Applicable
                  law and attribution of jurisdiction" of these Terms of Use, shall
                  survive expiration of the Services Period or termination of the
                  Services, in addition to any other provisions of these Terms of
                  Use which by their nature are intended to survive.
                </li>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Export Control</h6>
              <ol>
                <li>
                    Schneider Electric is subject to the laws of, and the items provided by Schneider Electric in accordance with these Terms of 
                    Use contain or may contain components and/or technologies from, the United States of America (“US”), the European Union 
                    (“EU”) or other nations. Customer acknowledges and agreesthat the supply, assignment and/or usage of the products, software, 
                    services, information, other items and/or the embedded technologies (hereinafter referred to as “Deliverables”), and all 
                    activities carried out under these Terms of Use shall fully comply with applicable trade, export control, embargo, economic 
                    or financial sanctions or anti-boycott laws, regulations, rules and/or restrictive measures imposed, administered or enforced 
                    from time to time by the United States, the United Kingdom, the European Union, and other applicable jurisdictions 
                    (hereinafter referred to as “International Trade and Sanctions Controls”).
                </li>
                <li>
                    Unless applicable International Trade and Sanctions Controls authorizations have been obtained from the relevant authority
                    and Schneider Electric has approved, Customer shall not take any action that may result, directly or indirectly, in the 
                    Deliverables (i) being exported and/or re-exported to any destination and party (may include but not limited to an individual, 
                    group and/or legal entity) , if export, sale, supply or transfer of the Deliverables to such destination or party is prohibited or 
                    restricted by the International Trade and Sanctions Controls applicable to Schneider Electric and/or Deliverables; or (ii) being
                    used for those purposes and fields prohibited or restricted by the International Trade and Sanctions Controls applicable to 
                    Schneider Electric and/or Deliverables. Customer also agrees that the Deliverables will not be used either directly or indirectly 
                    in any missiles; nor be used in any nuclear weapons delivery systems; and will not be used in any design, development, 
                    production or use for any weapons which may include but not limited to chemical, biological, or nuclear weapons, or for any 
                    other prohibited end-use or end user unless authorized under International Trade and Sanctions Controls
                </li>
                <li>
                    Customer represents and warrants that it shall maintain reasonable compliance policies, procedures and controls designed to 
ensure compliance with International Trade and Sanctions Controls and shall not otherwise undertake any action that violates 
or would cause Schneider Electric to violate International Trade and Sanctions Controls.

                </li>
                <li>
                    Customer agrees to fully cooperate and provide all documentation that Schneider Electric identifies as necessary or advisable 
to support compliance with International Trade Controls, including but not limited to the end user statement.

                </li>
                <li>
                    5 Customer undertakes to use best endeavors to recover the Deliverables that are exported and/or re-exported in violation of 
paragraph 14.2 (i) of this clause from any destination or party prohibited or restricted by the International Trade and Sanctions 
Controls applicable to the Schneider Electric and/or Deliverables
                </li>
                <li>
                    Schneider Electric shall have a right to suspend performance of all obligations under these Terms of Use, if: (i) any necessary 
or advisable licenses, authorizations or approvals are not obtained, whether arising from inaction by any relevant government
authority or otherwise, or (ii) any such licenses, authorizations or approvals are denied or revoked, or (iii) the International 
Trade and Sanctions Controls would prohibit Schneider Electric from fulfilling these Terms of Use, or would in Schneider 
Electric’s judgment otherwise expose Schneider Electric to a risk of liability under the applicable International Trade and
Sanctions Controls if it fulfilled these Terms of Use, or (iv) Schneider Electric becomes aware that the Deliverables were 
exported or re-exported in violation of paragraph 14.2 (i) of this clause.
                </li>
                <li>
                    Schneider Electric may resume the performance of obligations under these Terms of Use, once (i) Customer obtains the 
necessary or advisable licenses, authorizations or approvals; or (ii) the applicable International Trade and Sanctions Controls 
are amended or revised, or any new International Trade and Sanctions Controls are adopted that authorize Schneider Electric 
to fulfil these Terms of Use or to take other required action without any licenses, authorizations or approvals; or (iii) Customer 
confirms in writing that Customer has used best endeavors to recover the Deliverables exported and/or re-exported in violation 
of paragraph 14.2 (i) of this clause. If Customer fails to comply with paragraph 14.7 (iii) of this clause for six (6) months, 
Schneider Electric may terminate the legal agreement formed with Schneider Electric by Customer's acceptance of these Terms 
of Use and will not be liable for any losses of the Customer associated with such termination
                </li>

                <li>
                    Customer shall be responsible for and shall indemnify Schneider Electric against all liability, losses, administrative fines, 
damages, and expenses (including reasonable attorney’s or other professional services provider’s fees) resulting from (i) 
Customer’s non-compliance with or violations of International Trade and Sanctions Controls, or (ii) Customer causing 
Schneider Electric to be in non-compliance with or to violate such International Trade and Sanctions Controls, or (iii) Customer 
violating this clause (regardless of whether such violation results in a breach of the International Trade and Sanctions Controls 
by the Customer or Schneider Electric)
                </li>
                <li>
                    Customer shall annually provide (i) an updated end use statement and (ii) an additional certification, certifying that the 
representations, warranties, and covenants in this clause remain accurat
                </li>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Miscellaneous</h6>
              <ol>
                <li>
                  Your rights or obligations under these Terms of Use may not be
                  sold, sub-licensed, rented, assigned, delegated, transferred or
                  otherwise conveyed by You or Your representatives without
                  Schneider Electric's prior express written consent. Schneider
                  Electric may assign, contribute or otherwise transfer its rights
                  or obligations under these Terms of Use to any of its Affiliates
                  or any third-party that succeeds in or acquires whole or part of
                  the business or operations of Schneider Electric that relate to
                  the Digital Offer and/or the Services at the time of the proposed
                  assignment, contribution or transfer, whether by contract,
                  operation of law, acquisition, merger, consolidation, sale of its
                  outstanding shares or assets, or any other change of control or
                  transaction, without Customer's prior consent. These Terms of Use
                  will bind and inure to the benefit of Schneider Electric and
                  Customer and their permitted successors and assigns.
                </li>
                <li>
                  Schneider Electric is an independent contractor. Each of Schneider
                  Electric and the Customer agrees that no partnership, joint
                  venture, or agency relationship exists or is created by these
                  Terms of Use between Schneider Electric and the Customer. Neither
                  Schneider Electric nor the Customer is granted any right or
                  authority to assume or create any obligation or responsibility,
                  express or implied, on behalf of or in the name of one another.
                </li>
                <li>
                  No third-party beneficiary relationship is created by these Terms
                  of Use. These Terms of Use shall not be construed to confer any
                  rights or remedies to any third party.
                </li>
                <li>
                  These Terms of Use constitute the entire agreement between
                  Schneider Electric and the Customer in relation to the Customer's
                  right to access to and use the Digital Offer and the Services, and
                  such legal agreement replaces and supersedes any previous
                  agreement or understanding, whether oral, electronic or written,
                  in relation with the subject matter of these Terms of Use. The
                  Customer agrees to waive to apply any of its own terms of purchase
                  or license or services or other documents which subject matter is
                  equivalent to these Terms of Use's subject matter.
                </li>
                <li>
                  Should any provision of these Terms of Use be declared invalid or
                  unenforceable by any competent court, such provision of these
                  Terms of Use shall be amended by Schneider Electric to achieve as
                  nearly as possible its inner intent, and all other provisions of
                  these Terms of Use shall remain valid and in full force and
                  effect.
                </li>
                <li>
                  All notices sent pursuant to these Terms of Use shall be in
                  writing. Notices of a general purpose related to the Digital Offer
                  and/or the Services by Schneider Electric to all Customers shall
                  be given by means of a general information posted into the Digital
                  Offer. Notices specifically addressed to You shall be given by
                  Schneider Electric, at its election, by electronic mail to the
                  electronic address on record in the information provided by You to
                  create Your User Account or by written communication sent by first
                  class mail or pre-paid post to the address of Your registered
                  office or principal place of business. Any notice by You to
                  Schneider Electric shall be in writing sent by first class mail or
                  pre-paid post to the address of Schneider Electric's registered
                  office or principal place of business and shall include the words:
                  "Attention Schneider Electric Legal Department.
                </li>
                <li>
                  Schneider Electric's failure to insist on or enforce strict
                  performance of these Terms of Use shall not be construed as a
                  waiver by Schneider Electric of any right to enforce these Terms
                  of Use, nor shall any course of conduct between Schneider Electric
                  and the Customer or any third party be deemed to modify any
                  provision of these Terms of Use.
                </li>
                <li>
                  Headings in these Terms of Use are just for ease of reference and
                  will not affect its interpretation.
                </li>
                <li>
                  Words expressed in the singular include the plural and vice versa.
                </li>
                <li>
                  Schneider Electric reserves the right, at its sole discretion, to
                  amend, add or remove any provision to or from these Terms of Use
                  at any time. Any amendment, addition or removal to these Terms of
                  Use will be posted by Schneider Electric in the Digital Offer or
                  on any Schneider Electric’s web page related to the Digital Offer
                  and/or the Services. It is the Customer's responsibility to check
                  these Terms of Use prior to each use of the Digital Offer and, as
                  applicable, the Services. The Customer's continued use of the
                  Digital Offer and, as applicable, the Services after any
                  amendment, addition or removal to these Terms of Use is posted by
                  Schneider Electric, shall be deemed as the Customer's acceptance
                  and agreement to the changed Terms of Use. Changes to these Terms
                  of Use shall not apply with retroactive effect.
                </li>
                <li>
                  <p>
                    You agree to make all applicable records available for review by
                    Schneider Electric during Your normal business hours so as to
                    permit Schneider Electric (upon reasonable written notice to You)
                    to verify Your compliance with these Terms of Use. Further, You
                    agree that upon the request of Schneider Electric or its
                    authorized representative, You will promptly document and certify
                    in writing to Schneider Electric that Your and Your employees' use
                    of the Digital Offer and the Services comply with these Terms of
                    Use.
    
                  </p>
                  
                  <p> 
                    Schneider Electric may (upon reasonable written notice)
                    inspect Your use of the Digital Offer and the Services during Your
                    normal business hours to ensure Your compliance with these Terms
                    of Use. If the results of any such review or inspection indicate
                    Your unlicensed or non-compliant use of the Digital Offer or the
                    Services or the underpayment by You of applicable license or other
                    fees (if any) contractually due and payable to Schneider Electric,
                    You shall: (i) immediately pay sufficient fees to cover Your use
                    of the Digital Offer and/or as applicable the Services or such
                    amounts of fees remaining due to Schneider Electric, and (ii)
                    reimburse Schneider Electric for the cost of such review or
                    inspection.
                  </p>
                </li>
                <li>
                  These Terms of Use are a separate document from Schneider Electric
                  Affiliate’s general terms and conditions of sale applicable to the
                  Order Confirmation. Without prejudice to the foregoing and unless
                  otherwise expressly agreed between Customer and Schneider Electric
                  Affiliate issuing the Order Confirmation, these Terms of Use shall
                  automatically apply without deviation or amendment to the Digital
                  Offer and the Services accessed and used by Customer in pursuance
                  of the Order Confirmation. You agree that in case of conflict
                  between the provisions of these Terms of Use on the one hand and
                  on the other hand, the provisions of Schneider Electric
                  Affiliate’s general terms and conditions of sale applicable to any
                  Order Confirmation, these Terms of Use shall prevail to the sole
                  extent of the point in conflict with the provisions of said
                  general terms and conditions of sale which define or refer to the
                  right to use the Digital Offer, the Intellectual Property Rights,
                  the warranties and the liabilities related to the Digital Offer or
                  the processing of Customer Data, and any other provisions that
                  said general terms and conditions of sale expressly mention as
                  being prevailed by these Terms of Use.
                </li>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Applicable law & Disputes</h6>
              <ol>
                <li>
                  These Terms of Use and the Services shall be governed by and
                  construed in accordance with the laws of the State of
                  f Delaware
(USA), to the exclusion of its conflict of law rules. In
                  any country or, as applicable in any state, from where Customer
                  will access and use the Digital Offer and the Services or where
                  Customer or Schneider Electric Affiliate issuing the Order
                  Confirmation has its registered office or principal place of
                  establishment, public order laws or regulations and/or mandatory
                  laws or regulations overriding the law governing these Terms of
                  Use may impose statutory rights and/or obligations between
                  Customer and Schneider Electric or Schneider Electric Affiliate in
                  relation with the supply, the access, the use of the Digital
                  Offer, the Services and/or the processing of Customer Data or
                  Customer’s Clients Data. These Terms of Use shall apply to the
                  extent they do not affect or prejudice such statutory rights and
                  obligations. The United Nations Convention on Contracts for the
                  International Sale of Goods shall not apply to these Terms of Use.
                </li>
                <li>
                  Any legal action or proceeding between Schneider Electric and
                  Customer arising out or in connection with these Terms of Use, the
                  Digital Offer and/or and the Services shall be brought exclusively
                  in the federal or state courts having jurisdiction in the State of
                  f Delaware
(USA) and Schneider Electric and Customer hereby submit to
                  the personal jurisdiction and venue therein. Schneider Electric
                  and Customer forever waive and agree not to assert any defense
                  that is based upon an argument that the courts mentioned in this
                  paragraph lack personal jurisdiction, that venue is improper or
                  that the forum is inconvenient. SCHNEIDER ELECTRIC AND CUSTOMER
                  HEREBY IRREVOCABLY WAIVE ANY RIGHT EACH MAY HAVE, AND AGREE NOT TO
                  REQUEST, A JURY TRIAL FOR THE ADJUDICATION OF ANY DISPUTE
                  HEREUNDER OR IN CONNECTION WITH OR ARISING OUT OF THESE TERMS OF
                  USE AND/OR THE SERVICES.
                </li>
                <li>
                  Customer acknowledges and accepts that Schneider Electric will be
                  irreparably damaged and damages at law may be an inadequate remedy
                  if Customer breaches any provision of these Terms of Use and such
                  provision is not specifically enforced. Therefore, in the event of
                  a breach or threatened breach by the Customer of these Terms of
                  Use, Schneider Electric shall be entitled, in addition to all
                  other rights or remedies, to (a) an injunction or other injunctive
                  relief restraining such breach, without being required to show any
                  actual damage or to post an injunction or other bond, and/or (b) a
                  decree for specific performance of the applicable provision of
                  these Terms of Use, to the extent permitted by applicable law in
                  the country where Customer or Schneider Electric has its
                  registered office or principal place of business and/or, as
                  relevant in the context, where Customer will use the Digital Offer
                  and the Services, on either federal or state level when
                  applicable.
                </li>
              </ol>
            </section>
          </li>
          <li>
            <section>
              <h6>Principles of Responsibility / Trust Charter</h6>
              <ol>
                In the event Customer has concerns related to ethics, compliance or
                Schneider Electric’s Principles of Responsibility / Trust Charter,
                and/or any potential violations of these policies, Customer is
                welcome to make use of Schneider Electric’s TrustLine. The TrustLine
                is Schneider Electric’s global helpline for external stakeholders.
                It is a confidential channel through which Customers can ask
                questions and raise concerns. Reports can be made using the link
                below:
                <a href="https://secure.ethicspoint.eu/domain/media/en/gui/104677/index.html">https://secure.ethicspoint.eu/domain/media/en/gui/104677/index.html</a>
                
              </ol>
            </section>
          </li>
        </ol>
      </div>
    
    </div>
  </div>
  <div class="modal-footer">
  
    <button type="button" *ngIf="showAcceptBtn" class="btn modal-cust-btn" (click)="save()">
      Accept
    </button>
    <button type="button" *ngIf="!showAcceptBtn" class="btn modal-cust-btn" (click)="closeModal()">
        Close
      </button>
  </div>
</div>
