<!--
@author Anuj Grover
@email anuj.grover@non.se.com |  SESA625719
@create date 2021-07-14 17:01:32
@modify date 2022-03-09 04:35:51
@desc [description] : base compnent of the app
-->
<ng-sidebar-container style="height: 100vh;">
    <ng-sidebar class="left-sidebar" [(opened)]="_opened" mode="over" [keyClose]="_keyClose" position="left"
        [dock]="_dock" dockedSize="'50px'" [autoCollapseHeight]="_autoCollapseHeight"
        [autoCollapseWidth]="_autoCollapseWidth" [closeOnClickOutside]="_closeOnClickOutside"
        [closeOnClickBackdrop]="_closeOnClickBackdrop" [showBackdrop]="_showBackdrop" [animate]="_animate"
        [trapFocus]="_trapFocus" [autoFocus]="_autoFocus" [ariaLabel]="'My sidebar'" (onClosed)="_onClosed()">
        <!-- !Top menu -->
        <div class='mainItemsDiv d-flex justify-content-between align-items-center'>
            <div class="d-flex justify-content-start textcontentMenuItem align-items-center"
                (click)="toggleSubMenuItem('toggleusernameItem')">
                <i class="se-icon-user_standard sidebarUserIcon"></i>
                <span class="sidebarBoldText pl-1 pr-1 ">{{currentUserFullName}}</span>
                <i class="se-icon-arrow2_up sidebarIconsArrowUp" *ngIf="!toggleusernameItem"></i>
                <i class="se-icon-arrow2_up sidebarIconsArrowDown" *ngIf="toggleusernameItem"></i>
            </div>
            <i class="se-icon-action_close sidebarIconsClose" (click)="_toggleLeftSidebar()"></i>
        </div>
        <div *ngIf="toggleusernameItem">
            <div class='subMenuItemsDiv d-flex justify-content-between align-items-center'>
                <span class="sidebarNormalText"
                    (click)="toggleSubMenuItem('toggleusernameItem');openProfileeditModal()">User profile</span>
            </div>
            <div class='subMenuItemsDiv d-flex justify-content-between align-items-center'>
                <span class="sidebarNormalText" (click)="toggleSubMenuItem('toggleusernameItem');logOut()">Logout</span>
            </div>
        </div>

        <!-- !Main menu -->
        <div class='mainItemsDiv d-flex justify-content-between align-items-center'
            [ngClass]="{activeTab: activeTab === 'Home'}" (click)="activateTab('Home'); isMainRoute('dashboard')">
            <div class="d-flex justify-content-between textcontentMainMenuItem align-items-center">
                <span class="sidebarBoldText pl-1 pr-1" [ngClass]="{activeTabText: activeTab === 'Home'}">Home</span>
            </div>
        </div>


        <!-- ?Multisite -->
        <!-- {{isCI2User +' --  '+ isMoreThanSingleSite}} -->
        <div *ngIf="isCI2User && isMoreThanSingleSite"
            class='mainItemsDiv d-flex justify-content-between align-items-center'
            [ngClass]="{activeTab: activeTab === 'Multisite'}" (click)="toggleSubMenuItem('toggleMultisiteItem');">
            <div class="d-flex justify-content-between textcontentMainMenuItem align-items-center">
                <div>
                    <span class="sidebarBoldText pl-1 pr-1"
                        [ngClass]="{activeTabText: activeTab === 'Multisite'}">Multisite </span>
                    <span class="sidebarBoldText" *ngIf="activeTab === 'Multisite'"
                        [ngClass]="{activeTabText: activeTab === 'Multisite'}">
                        >
                        {{selectedSubmenu}}
                    </span>
                </div>
            </div>
            <i class="se-icon-arrow2_up sidebarIconsArrowUpMainMenu" *ngIf="!toggleMultisiteItem"></i>
            <i class="se-icon-arrow2_up sidebarIconsArrowDownMainMenu" *ngIf="toggleMultisiteItem"></i>
        </div>
        <div *ngIf="toggleMultisiteItem && isCI2User && isMoreThanSingleSite">
            <div class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('site/overview', 'Multisite', 'Site Overview', 'toggleMultisiteItem' )">
                <span class="sidebarNormalText">Site Overview</span>
            </div>
            <div class='subMenuItemsDiv d-flex justify-content-between align-items-center'>
                <span class="sidebarNormalText"
                    (click)="submenuClick('site/comparison', 'Multisite', 'Site Comparison', 'toggleMultisiteItem' )">Site
                    Comparison</span>
            </div>
        </div>
        <!-- <div class="mainItemsDiv d-flex justify-content-between align-items-center"
            *ngIf="isCI2User && !isMoreThanSingleSite">
            <div [ngClass]="{activeTab: activeTab === 'Multisite'}"
                (click)="submenuClick('site/overview', 'Multisite', 'Site Overview', 'toggleMultisiteItem' )">
                <span class="sidebarNormalText">Site Overview</span>
            </div>
        </div> -->

        <div *ngIf="!isCI2User || !isMoreThanSingleSite"
            class='mainItemsDiv d-flex justify-content-between align-items-center'
            [ngClass]="{activeTab: activeTab === 'Multisite'}"
            (click)="submenuClick('site/overview', 'Multisite', 'Site Overview', 'toggleMultisiteItem' )">
            <div class="d-flex justify-content-between textcontentMainMenuItem align-items-center">
                <span class="sidebarBoldText pl-1 pr-1" [ngClass]="{activeTabText: activeTab === 'Multisite'}">Site
                    Overview</span>
            </div>
        </div>

        <!-- <div class='mainItemsDiv d-flex justify-content-between align-items-center'
             [ngClass]="{activeTab: activeTab === 'My Dashboard'}"
             (click)="activateTab('My Dashboard')">
            <div class="d-flex justify-content-between textcontentMainMenuItem align-items-center">
                <span class="sidebarBoldText pl-1 pr-1"
                      [ngClass]="{activeTabText: activeTab === 'My Dashboard'}">My Dashboard</span>
            </div>
        </div> -->

        <!-- ?Performance -->
        <div class='mainItemsDiv d-flex justify-content-between align-items-center'
            [ngClass]="{activeTab: activeTab === 'Performance'}" (click)="toggleSubMenuItem('togglePerformanceItem')">
            <div class="d-flex justify-content-between textcontentMainMenuItem align-items-center">
                <div>
                    <span class="sidebarBoldText pl-1 pr-1"
                        [ngClass]="{activeTabText: activeTab === 'Performance'}">Performance </span>
                    <span class="sidebarBoldText" *ngIf="activeTab === 'Performance'"
                        [ngClass]="{activeTabText: activeTab === 'Performance'}">
                        >
                        {{selectedSubmenu}}
                    </span>
                </div>
            </div>
            <i class="se-icon-arrow2_up sidebarIconsArrowUpMainMenu" *ngIf="!togglePerformanceItem"></i>
            <i class="se-icon-arrow2_up sidebarIconsArrowDownMainMenu" *ngIf="togglePerformanceItem"></i>
        </div>
        <div *ngIf="togglePerformanceItem">
            <!-- <div *ngIf="isCI2User" class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('performance/energyComparison', 'Performance', 'Energy Comparison', 'togglePerformanceItem' )">
                <span class="sidebarNormalText">Energy Comparison</span>
            </div> -->
            <div *ngIf="isCI2User" class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('performance/deviceComparison', 'Performance', 'Device Comparison', 'togglePerformanceItem' )">
                <span class="sidebarNormalText">Device Comparison</span>
            </div>
            <div class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('performance/sourceAnalysisComparison', 'Performance', 'Source Analysis', 'togglePerformanceItem' )">
                <span class="sidebarNormalText">Source Analysis</span>
            </div>
            <div *ngIf="isCI2User" class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('performance/siteAdvancedAnalysis', 'Performance', 'Site Advance Analysis', 'togglePerformanceItem' )">
                <span class="sidebarNormalText">Site Advanced Analysis</span>
            </div>
            <div *ngIf="isCI2User" class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('performance/alarmsAndWarningComparison', 'Performance', 'Alarms Warning Analysis', 'togglePerformanceItem' )">
                <span class="sidebarNormalText">Alarms Warning Analysis</span>
            </div>
            <div *ngIf="isCI2User" class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('performance/benefits', 'Performance', 'Benefits', 'togglePerformanceItem' )">
                <span class="sidebarNormalText">Benefits</span>
            </div>
        </div>


        <!-- ?reporting -->
        <div *ngIf="isCI2User" class='mainItemsDiv d-flex justify-content-between align-items-center'
            [ngClass]="{activeTab: activeTab === 'Reporting'}" (click)="toggleSubMenuItem('toggleReportingItem')">
            <div class="d-flex justify-content-between textcontentMainMenuItem align-items-center">
                <div>
                    <span class="sidebarBoldText pl-1 pr-1"
                        [ngClass]="{activeTabText: activeTab === 'Reporting'}">Reporting </span>
                    <span class="sidebarBoldText" *ngIf="activeTab === 'Reporting'"
                        [ngClass]="{activeTabText: activeTab === 'Reporting'}">
                        >
                        {{selectedSubmenu}}
                    </span>
                </div>
            </div>
            <i class="se-icon-arrow2_up sidebarIconsArrowUpMainMenu" *ngIf="!toggleReportingItem"></i>
            <i class="se-icon-arrow2_up sidebarIconsArrowDownMainMenu" *ngIf="toggleReportingItem"></i>
        </div>
        <div *ngIf="toggleReportingItem">
            <div class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('reporting/reports', 'Reporting', 'Reports', 'toggleReportingItem' )">
                <span class="sidebarNormalText">Reports</span>
            </div>
            <div *ngIf="isSiteAdministrator" class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('reporting/reportManager', 'Reporting', 'Reports Manager', 'toggleReportingItem' )">
                <span class="sidebarNormalText">Reports Manager</span>
            </div>
        </div>


        <!-- ?Events -->
        <div class='mainItemsDiv d-flex justify-content-between align-items-center'
            [ngClass]="{activeTab: activeTab === 'Events'}" (click)="activateTab('EVENTS'); isMainRoute('events')">
            <div class="d-flex justify-content-between textcontentMainMenuItem align-items-center">
                <div>
                    <span class="sidebarBoldText pl-1 pr-1" [ngClass]="{activeTabText: activeTab === 'Events'}">Events
                    </span>
                    <span class="sidebarBoldText" *ngIf="activeTab === 'Events'"
                        [ngClass]="{activeTabText: activeTab === 'Events'}">
                        >
                        {{selectedSubmenu}}
                    </span>
                </div>
            </div>
        </div>

        <!-- ?Configuration -->
        <div class='mainItemsDiv d-flex justify-content-between align-items-center'
            [ngClass]="{activeTab: activeTab === 'Configuration'}"
            (click)="toggleSubMenuItem('toggleConfigurationItem')">
            <div class="d-flex justify-content-between textcontentMainMenuItem align-items-center">
                <div>
                    <span class="sidebarBoldText pl-1 pr-1"
                        [ngClass]="{activeTabText: activeTab === 'Configuration'}">Configuration </span>
                    <span class="sidebarBoldText" *ngIf="activeTab === 'Configuration'"
                        [ngClass]="{activeTabText: activeTab === 'Configuration'}">
                        >
                        {{selectedSubmenu}}
                    </span>
                </div>
            </div>
            <i class="se-icon-arrow2_up sidebarIconsArrowUpMainMenu" *ngIf="!toggleConfigurationItem"></i>
            <i class="se-icon-arrow2_up sidebarIconsArrowDownMainMenu" *ngIf="toggleConfigurationItem"></i>
        </div>
        <div *ngIf="toggleConfigurationItem">
            <div class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('configuration/siteAndDevices', 'Configuration', 'Site Settings', 'toggleConfigurationItem')">
                <span class="sidebarNormalText">Site Settings</span>
            </div>
            <div class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('configuration/deviceSettings', 'Configuration', 'Device Settings', 'toggleConfigurationItem')">
                <span class="sidebarNormalText">Device Settings</span>
            </div>
            <div class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('configuration/systemSettings', 'Configuration', 'System Settings', 'toggleConfigurationItem')">
                <span class="sidebarNormalText">System Settings</span>
            </div>
            <div *ngIf="isCI2User && isAllowedByUserRole"
                class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('configuration/firmware', 'Configuration', 'Firmware', 'toggleConfigurationItem')">
                <span class="sidebarNormalText">Firmware</span>
            </div>
            <div class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('configuration/userRights', 'Configuration', 'User Rights', 'toggleConfigurationItem')">
                <span class="sidebarNormalText">User Rights</span>
            </div>
            <div *ngIf="isCI2User" class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('configuration/emailNotifications', 'Configuration', 'Email Notification', 'toggleConfigurationItem')">
                <span class="sidebarNormalText">Email Notification</span>
            </div>
            <div *ngIf="isCI2User" class='subMenuItemsDiv d-flex justify-content-between align-items-center'
                (click)="submenuClick('configuration/financialSettings', 'Configuration', 'Financial Settings', 'toggleConfigurationItem')">
                <span class="sidebarNormalText">Financial Settings</span>
            </div>
        </div>

        <div class="d-flex flex-column justify-content-end sideBarFooter"
            [ngStyle]="{height: sideBarFooterHeight+'px'}">
            <div class="d-flex flex-column align-items-center justify-content-center p-2">
                <span class="copyrightText">
                    This application is protected by copyright law and international treaties.
                </span>
                <span class="copyrightText">
                    ©&nbsp;2020 Schneider Electric Industries SAS. All rights reserved
                </span>
                <span class="copyrightText dAcFontStyle"
                    (click)="goToSchneiderWebPortalForDataPrivacyAndCookiePolicy()">
                    Data Privacy and Cookie Policy
                </span>
            </div>

            <div class="d-flex justify-content-around align-items-center p-2">
                <div class="d-flex flex-column justify-content-start">
                    <div class="d-flex">
                        <span class="boldTextInsightCloud">
                            Insight
                        </span>
                        <span class="textInsightCloud">
                            Cloud
                        </span>
                    </div>
                    <span class="textVersion">Version</span>
                </div>
                <div class='sec-logo'></div>
            </div>
        </div>



    </ng-sidebar>
    <!-- Page content -->
    <div ng-sidebar-content>
        <app-topbar (sideBarEvent)="_toggleLeftSidebar()" *ngIf="showTopBar"></app-topbar>
        <div class="minHeightApp">
            <router-outlet></router-outlet>
        </div>

        <app-footer *ngIf='showFooter===true'></app-footer>
    </div>
</ng-sidebar-container>


<ng-template #siteCreatePopUp let-c="close" let-d="dismiss" class="add-user-modal">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">New Site Registration</h4>
    </div>
    <div class="modal-body">
        <ngx-spinner name="sitecreateSpinner" [fullScreen]="false" size="default" color="#3dcd58"
            type="ball-clip-rotate"></ngx-spinner>
        <site-registration></site-registration>
    </div>
</ng-template>


<!-- Firmware splash screen Start-->
<ng-template #firmwareSplashContent let-c="close" let-d="dismiss" class="add-user-modal">
    <div class="modal-header-image">
        <button type="button" class="close" aria-label="Close" (click)="closeFirmwareSplashScreen(false)">
            <span aria-hidden="true">&times;</span>&nbsp;&nbsp;
        </button>
    </div>
    <div class="modal-header-body" style="border-bottom-left-radius: 0px !important;">
        <div class="d-flex flex-column">
            <div class="f-message d-grid justify-content-start">
                <span class="flash-message">{{flashMessage}} <b>{{siteWithNewFirmwareAvailable}}</b>
                    &nbsp;&nbsp;&nbsp;
                    <span class="flash-upgrade" (click)="closeFirmwareSplashScreen(true)"> Upgrade </span> &nbsp;
                    <!-- &nbsp;
                    <span class="flash-upgrade" (click)="closeFirmwareSplashScreen(false)"> No, thanks </span> -->
                </span>
                <span class="flash-message">{{upgradeMessage}}</span>
            </div>
            <!-- <div class="do-not-show d-flex justify-content-start ">
                    <label class="container d-flex align-content-center">
                        <input type="checkbox" [(ngModel)]="userPreference" (change)="setSplashVisibility($event)" />
                        &nbsp; &nbsp;
                        Do not show this again
                    </label>
                </div> -->
        </div>
    </div>
</ng-template>
<!-- Firmware splash screen end-->


<ng-template #firmwareForceUpgradeContent let-c="close" let-d="dismiss" class="add-user-modal">
    <div *ngIf="!isUpgradeCompletionContainer">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{modalPopupHeader}}</h4>
            <!-- <button type="button" class="close" aria-label="Close" (click)="CloseFirmwareForceUpgradeContent()">
                    <span aria-hidden="true">&times;</span>
                </button> -->
        </div>
        <div class="modal-body firmware-list-container">
            <!-- <div *ngIf="hasAlert" class="alert-container"
                    [ngClass]="{ 'alert-success': isValidCredntials, 'alert-error': !isValidCredntials }">
                    {{authenticationMessage}}
                </div> -->
            <ngx-spinner name="reloadLoader" [fullScreen]="false" size="default" color="#3dcd58"
                type="ball-clip-rotate">
                <p class="spinner-message"><i>Verifying devices...</i></p>
            </ngx-spinner>
            <div class="d-flex flex-column justify-content-start firmware-upgrade-container">
                <h4 class="text-center"><b>Firmware change recommended</b></h4>
                <p class="text-justify">Why this fimware to be upgraded?</p>
                <p class="text-justify small-txt"><i>Solar Schneider Electirc recommends to upgrade identified
                        <b>{{deviceTypeFirmwareUPgrade}}</b> devicess with latest version
                        <b>[{{latestFirmwareUpgradeDetails.newVersion}}]</b> and build number
                        <b>[{{latestFirmwareUpgradeDetails.newBuildNo}}]</b>.</i></p>
                <div class="divider d-flex flex-row"></div>
                <div class="upgrade-container">
                    <div id="accordion">
                        <div class="card" *ngFor="let item of firmwareUpgradeDevicesList">
                            <div class="card-header pointer-cursor" id="{{item.id}}" (click)="togglePanel(item)">
                                <h6 class="mb-0">{{item.id}}</h6>
                            </div>
                            <div class="card-body" [hidden]="!item.enableUpgradeSection">
                                <div *ngIf="hasAlert" class="alert-container"
                                    [ngClass]="{ 'alert-success': isSuccessAlert, 'alert-error': !isSuccessAlert }">
                                    {{authenticationMessage}}
                                </div>
                                <div class="d-flex justify-content-between element-contianer">
                                    <div class="element-section d-flex flex-column justify-content-start">
                                        <div *ngIf="!item.isUpgradeInProgress"
                                            class="modal-body firmware-list-container">
                                            <ngx-spinner name="verifyLoader" [fullScreen]="false" size="default"
                                                color="#3dcd58" type="ball-clip-rotate">
                                                <p class="spinner-message"><i>Verifying upgrade...</i></p>
                                            </ngx-spinner>
                                            <div class="devices-section d-flex flex-row justify-content-start align-items-center"
                                                *ngFor="let device of item.devices">
                                                <div
                                                    class="progress-bar-name d-flex justify-content-start align-content-center">
                                                    {{device.name}} </div>
                                                <div
                                                    class="progress-bar-container d-flex justify-content-end align-content-center">
                                                    <div class="bar-cont mt-3">
                                                        <progressbar [animate]="true" [striped]="true"
                                                            [value]="device.progressPrecent"
                                                            [type]="device.progressType" id="{{device.name}}_V"><em
                                                                class="progress-status-per">{{device.progressPrecent}}%</em>
                                                        </progressbar>
                                                        <span class="progress-status"
                                                            id="{{device.name}}_S">{{device.progressStatus}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="item.isUpgradeInProgress"
                                            class="modal-body firmware-list-container">
                                            <div class="d-flex flex-row justify-content-center align-content-center">
                                                <div class="upgrade-in-progress-img"></div>
                                                <div class="update-message">Upgrade in progress... Please wait</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="action-item-section d-flex flex-column justify-content-start">
                                        <div class="action-items d-flex justify-content-end">
                                            <button
                                                *ngIf="item.enableUpgrade && isValidCredntials && !item.isUpgradeInProgress && !item.isUpgradeStarted"
                                                class="modal-cust-btn" (click)="selectAndProceed(item)">Upgrade</button>
                                        </div>
                                        <div *ngIf="item.enableUpgrade && !isValidCredntials"
                                            class="login-section d-flex justify-content-end">
                                            <form *ngIf="deviceLoginForm" [formGroup]="deviceLoginForm"
                                                (ngSubmit)="verifyDeviceCredentials()" autocomplete="off">
                                                <div class="modal-body firmware-list-container">
                                                    <ngx-spinner name="loginLoader" [fullScreen]="false" size="default"
                                                        color="#3dcd58" type="ball-clip-rotate">
                                                        <p class="spinner-message"><i>Logging in...</i></p>
                                                    </ngx-spinner>
                                                    <div class="d-flex flex-column justify-content-end">
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i
                                                                            class="fa fa-user"></i></span>
                                                                </div>
                                                                <input type="text" formControlName="username"
                                                                    class="form-control user-credentials"
                                                                    placeholder="username" readonly
                                                                    onfocus="this.removeAttribute('readonly');"
                                                                    autocomplete="off"
                                                                    [ngClass]="{ 'is-invalid': isDeviceCredentialsSubmitted && f.username.errors }">
                                                            </div>
                                                            <div *ngIf="f.username.errors" class="error-text">
                                                                <div *ngIf="f.username.errors.required">*Username is
                                                                    required
                                                                </div>
                                                                <div *ngIf="f.username.errors.pattern">*Enter valid
                                                                    username
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i
                                                                            class="fa fa-lock"></i></span>
                                                                </div>
                                                                <input [type]="passwordInputType ? 'text' : 'password'"
                                                                    class="form-control input-type-password"
                                                                    placeholder="password" readonly
                                                                    onfocus="this.removeAttribute('readonly');"
                                                                    autocomplete="off" formControlName="password"
                                                                    [ngClass]="{ 'is-invalid': isDeviceCredentialsSubmitted && f.password.errors }">
                                                                <i (click)="togglePassword()" class="showPassword fa"
                                                                    [ngClass]="{'se-eye': !passwordInputType,'fa-eye-slash': passwordInputType}"></i>
                                                            </div>
                                                            <div *ngIf="f.password.errors" class="error-text">
                                                                <div *ngIf="f.password.errors.required">*Password is
                                                                    required
                                                                </div>
                                                                <div
                                                                    *ngIf="f.password.errors.minlength || f.password.errors.maxlength || f.password.errors.pattern">
                                                                    *Enter valid password</div>
                                                            </div>
                                                        </div>
                                                        <span class="password-note"><i>*We will never save your
                                                                gateway
                                                                credentials</i></span>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button *ngIf="!isValidCredntials" type="button"
                                                            class="btn modal-cust-btn" type="submit"
                                                            [disabled]="!deviceLoginForm.valid">Login</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button *ngIf="isValidCredntials" [disabled]="!disabledAgreement" type="button" class="btn modal-cust-btn"
                    (click)="modifySettings('licenseAgreementSelector')">Apply Changes</button>
                <button type="button" class="btn modal-cust-btn"
                    (click)="CloseFirmwareForceUpgradeContent()">Cancel</button>
            </div> -->
        </div>
    </div>
    <div *ngIf="isUpgradeCompletionContainer">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{modalPopupHeader}}</h4>
        </div>
        <div class="modal-body d-flex firmware-list-container">
            <div class="completion-container d-flex justify-content-center align-content-center">
                <div class="completion-message">Successfully upgraded all devices.
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn modal-cust-btn" (click)="CloseFirmwareForceUpgradeContent()">Ok</button>
        </div>
    </div>
    <pdf-viewer [src]="src"
    [original-size]="true"></pdf-viewer>
</ng-template>


<ng-container>
    <ngx-spinner name="forceFirmwareUpgradeSpinner" [fullScreen]="true" size="default" color="#3dcd58"
        type="ball-clip-rotate"></ngx-spinner>
</ng-container>