import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectedSiteService {
  currentSite: any;

  sendMessage(selectedSite: any) {
    this.currentSite = selectedSite;
  }

  getMessage() {
    return this.currentSite;
  }
}
