export class GatewayAuthRequest {
    siteName: string;
    deviceUsername: string;
    devicePassword: string;

    constructor(siteName: string, deviceUsername: string, devicePassword: string){
        this.siteName = siteName;
        this.deviceUsername = deviceUsername;
        this.devicePassword = devicePassword;
    }
}