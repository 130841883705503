import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { Constants } from 'src/app/shared/constants';
import { CountryListValues } from 'src/app/shared/data-values/countries/country-list';
import { createPasswordValidator } from 'src/app/shared/validators';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss']
})
export class ProfileModalComponent implements OnInit {

  @Input() name;
  // @ViewChild('profileFormState') profileForm: NgForm;
  seeOld = false;
  seeNew = false;
  noSiteVal: boolean = false;
  successMessage: string = "";
  passwordResetStatus: any = {};
  deleteAccountStatus: any = {};
  profileImageUploadSuccessMessage: string = "";
  countryList: any = CountryListValues.countryListArray || [];
  private imageSrc: string = '';
  imageError: string = "";
  userInfoForImageObject: any = {};
  profileImageSource: any = '';
  modalRef: NgbModalRef;
  demoUserEmail:any;
  isDemoUser:boolean=false;

  // profile form started
  profileForm: FormGroup = this.fb.group({
    firstName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z\'. ]*$'), Validators.maxLength(21)])],
    lastName: ['', Validators.compose([Validators.pattern('^[a-zA-Z\'. ]*$'), Validators.maxLength(21)])],
    email: ['', Validators.compose([Validators.required])],
    phone: ['', Validators.compose([Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(15)])],
    address: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.*[a-zA-Z])[ a-zA-Z0-9\'./#()&,-]+$'), Validators.minLength(5), Validators.maxLength(100)])],
    city: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.*[a-zA-Z])[ a-zA-Z\'.-]+$'), Validators.minLength(2), Validators.maxLength(21)])],
    organisation: ['', Validators.compose([Validators.pattern('^(?=.*[a-zA-Z])[ a-zA-Z0-9\'.-]+$'), Validators.maxLength(100)])],
    country: ['', Validators.compose([Validators.required])],
    pincode: ['', Validators.compose([Validators.pattern('[-a-zA-Z0-9]+'), Validators.maxLength(50)])],
    state: ['', Validators.compose([Validators.pattern('^(?=.*[a-zA-Z])[ a-zA-Z0-9\'./#()&,-]+$'), Validators.maxLength(50)])]
  });

  pwdForm: FormGroup = this.fb.group({
    oldPassword: ['', Validators.compose([Validators.required])],
    newPassword: ['', Validators.compose([Validators.required, Validators.minLength(8),
    Validators.maxLength(25), createPasswordValidator()])],
  });

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) { }
  ngOnInit(): void {
    this.detectScreenSize();
    let selectedSite = this.authService.getSelectedSite();
    if (
      selectedSite == null ||
      selectedSite == '' ||
      selectedSite == undefined
    ) {
      this.noSiteVal = true;
    } else {
      this.fetchUserProfile();
      this.fetchProfileImagedata();
    }
  }

  fetchUserProfile() {
    this.spinner.show("userProfileForm");
    let selectedSite = this.authService.getSelectedSite();

    this.authService.getUserProfile(selectedSite).subscribe((data) => {
      if (data && data.msg) {
        let userProfileData = JSON.parse(data.msg);

        if (userProfileData) {
          this.demoUserEmail=userProfileData.email
        if(this.demoUserEmail==="demouser@insightcloud.se.com"){
          this.isDemoUser=true;
        }else{
          this.isDemoUser=false;
        }
          this.patchProfileForm(userProfileData);
        }
      }
      this.spinner.hide("userProfileForm");
    });
  }

  patchProfileForm(profileFormData) {
    this.profileForm.patchValue({
      firstName: profileFormData.firstName,
      lastName: profileFormData.lastName,
      email: profileFormData.email,
      phone: profileFormData.phone,
      address: profileFormData.address,
      city: profileFormData.city,
      country: profileFormData.country,
      pincode: profileFormData.pincode,
      organisation: profileFormData.organisation,
      state: profileFormData.state
    });
  }

  fetchProfileImagedata(newimageuploadflag = false) {
    let selectedSite = this.authService.getSelectedSite();

    this.spinner.show("profileImageLoading");
    this.userInfoForImageObject = {};
    // * If alrady the image is present in the service fetched from sibling component dont call the service 
    if (!newimageuploadflag && this.userService.profileImage && this.userService.userDataProfileImage) {
      this.profileImageSource = this.userService.profileImage;
      this.userInfoForImageObject = this.userService.userDataProfileImage;
      this.spinner.hide("profileImageLoading");

    }
    else {
      this.userService.getProfileImageData(selectedSite).subscribe((data) => {

        if (data) {
          const { profilePic, ...profileData } = data;
          this.userInfoForImageObject = profileData;
          this.spinner.hide("profileImageLoading");

          if (profilePic) {
            this.profileImageSource = `data:image/jpeg;base64,${profilePic}`;
            //set data for global use case
            this.userService.profileImage = `data:image/jpeg;base64,${profilePic}`;
            this.userService.userDataProfileImage = profileData;

            this.userService.emitChange(Constants.PROFILE_IMAGE_CHANGE_EVENT);
          }
        }

      });
    }
  }

  saveUserProfile() {
    //
    this.successMessage = "";
    let profileForm = {
      lastName: this.profileForm.value.lastName || "",
      firstName: this.profileForm.value.firstName || "",
      phone: this.profileForm.value.phone || "",
      address: this.profileForm.value.address || "",
      city: this.profileForm.value.city || "",
      country: this.profileForm.value.country || "",
      state: this.profileForm.value.state || "",
      organisation: this.profileForm.value.organisation || "",
      pincode: this.profileForm.value.pincode || ""
    };
    this.spinner.show("userProfileForm");

    this.authService.updateUserProfile(profileForm).subscribe((data) => {
      if (data && data.msg) {
        this.successMessage = data.msg;
        setTimeout(() => {
          this.successMessage = '';
        }, 2000);  
      }
      this.authService.setLoggedInUserFirstname(profileForm.firstName);
      this.authService.setLoggedInUsersLastname(profileForm.lastName);
      
      this.userService.emitUserNameChange('site');
      this.fetchUserProfile();
    });
  }

  onImageSelected(event: any) {

    const file: File = event.target.files[0];
    this.imageError = "";
    this.profileImageUploadSuccessMessage = "";
    if (file) {
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        this.imageError = "Invalid Image Format";
        return;
      }
      if ((file.size / 1024 / 1024) > 2) { // in MiB
        this.imageError = "File size exceeds 2 MiB";
      } else {
        // Proceed further

        this.userService.uploadProfileImage(file, this.userInfoForImageObject).subscribe((data) => {
          if (data && data.msg && !data.msg.includes('Failed')) {
            this.profileImageUploadSuccessMessage = "Profile Image Uploaded Successfully";

            let newimageuploadflag = true;
            this.fetchProfileImagedata(newimageuploadflag);

          }
        });

      }
    }
  }



  @HostListener("window:resize", [])
  private onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }


  relRatio(a, b) {
    return 100 * Math.abs((a - b) / ((a + b) / 2));
  }

  isMobile: boolean = false
  private detectScreenSize() {
    setTimeout(() => {
      if (window.innerWidth > 1090) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    }, 200);
  }

  toggleSeeOld() {
    this.seeOld = !this.seeOld;
  }

  toggleSeeNew() {
    this.seeNew = !this.seeNew;
  }

  cleanForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => formGroup.get(key).setValue(formGroup.get(key).value.trim()));
  }

  showResult(status: any): void {
    this.spinner.hide("userProfileForm");
    this.passwordResetStatus = status;
    setTimeout(() => this.passwordResetStatus = null, 2000);
  }

  get oldPasswordCtl(): AbstractControl {
    return this.pwdForm.get('oldPassword');
  }

  get newPasswordCtl(): AbstractControl {
    return this.pwdForm.get('newPassword');
  }

  changePassword(): void {
    // trim trailing spaces
    this.cleanForm(this.pwdForm);
    // if still valid proceed to update password
    if (this.pwdForm.valid) {
      this.spinner.show("userProfileForm");
      this.passwordResetStatus = null;
      this.authService.updateUserPwd(this.pwdForm.value.oldPassword, this.pwdForm.value.newPassword).pipe(
        take(1),
      ).subscribe(s => {
        this.showResult(s);
      }, error => {
        const failure = { status: 'Failure', msg: 'Invalid credentials. Please contact support if issue persists' };
        this.showResult(failure);
      });
    }

  }

  deleteUserModalPopUp(content) {
    this.modalRef = this.modalService.open(content, { centered: true });
    this.modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  };

  deleteAccount() {
    this.modalRef.close();
    this.spinner.show("userProfileForm");
    this.userService.deleteAccount().subscribe(data => {
      const success = { status: 'Success', msg: 'User account deleted successfully. You will be logged out of the application' };
      this.spinner.hide("userProfileForm");
      this.deleteAccountStatus = success;
      setTimeout(() => {
        this.deleteAccountStatus = null;
        this.authService.logout();
      }, 2000);
    }, err => {
      const failure = { status: 'Failure', msg: 'Unable to delete account. Please contact support if issue persists' };
      this.spinner.hide("userProfileForm");
      this.deleteAccountStatus = failure;
      setTimeout(() => this.deleteAccountStatus = null, 2000);
    });
  }
}
