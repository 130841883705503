/**
 * @author SESA480327 - Santhosh raj
 * @email santhosh.raj@non.se.com
 * @create date 2019-07-10 15:55:16
 * @modify date 2019-07-10 15:55:16
 * @desc Service for CRUD operations
 */


import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NewSite } from '../models/new-site';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API_URLS } from '../shared/api_urls';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private http: HttpClient) { }

  updateSite(site: NewSite, image1, image2, image3): Observable<NewSite> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.set('Content-Type', "image/plain");
    formData.append('site', new Blob([JSON.stringify(site)], { type: 'application/json' }));
    if (image1)
      formData.append("image1", new Blob([JSON.stringify(image1)
      ], {
        type: "application/json"
      }));

    if (image2)
      formData.append("image2", new Blob([JSON.stringify(image2)
      ], {
        type: "application/json"
      }));
    if (image3)
      formData.append("image3", new Blob([JSON.stringify(image3)
      ], {
        type: "application/json"
      }));
    const url = `${API_URLS.SITE_UPDATE}`;
    return this.http.put<NewSite>(url, formData, { headers })
      .pipe(
        catchError(this.handleError)
      );
    // const url = 'sites/update';
    // return this.http.put<NewSite>(url, site).pipe(
    //   catchError(this.handleError)
    // );
  }

  duplicateSite(Sites: any): Observable<any> {
    const url = `${API_URLS.SITE_DUPLICATE}`;
    return this.http.post<any>(url, Sites)
  }

  createSite(site: NewSite, image1, image2, image3): Observable<NewSite> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.set('Content-Type', "image/plain");
    // headers.set('Content-Type', "image/plain");
    formData.append('site', new Blob([JSON.stringify(site)], { type: 'application/json' }));
    if (image1)
      formData.append("image1", new Blob([JSON.stringify(image1)
      ], {
        type: "application/json"
      }));

    if (image2)
      formData.append("image2", new Blob([JSON.stringify(image2)
      ], {
        type: "application/json"
      }));
    if (image3)
      formData.append("image3", new Blob([JSON.stringify(image3)
      ], {
        type: "application/json"
      }));
    const url = `${API_URLS.SITE_NEW_CLOUD}`;
    return this.http.post<NewSite>(url, formData, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  uploadDocs(upload: any, uploadFileUrl): Observable<NewSite> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.set('Content-Type', undefined);
    headers.append('Accept', 'application/json');
    formData.append('uploadDetails', new Blob([JSON.stringify(upload)], { type: 'application/json' }));
    // formData.append("fileFirst", new Blob([JSON.stringify(uploadFileUrl)]));
    formData.append("uploadedDocument", new Blob([JSON.stringify(uploadFileUrl)
    ], {
      type: "application/json"
    }));
    // const docType = upload['documentType'];
    // console.log('docType---------------------' + docType);
    // const siteName = upload['siteName'];
    // const fileName = upload['fileName'];
    const fileUploadURL = `${API_URLS.SITE_FILE_UPLOAD}`;
    return this.http.post<NewSite>(fileUploadURL, formData, { headers });
  }

  getAllDocsList(siteName: string): Observable<any> {
    let siteObj = {
      "siteName": siteName
    }
    return this.http.post<any>(`${API_URLS.SITE_DOCUMENT_LIST}`, siteObj).pipe(map(data => {
      return data;
    }));
  }

  deleteDocs(id): Observable<any> {
    let docsObj = {
      id: id
    }
    return this.http.post<any>(`${API_URLS.SITE_DOCUMENT_DELTE}`, docsObj)
      .pipe(map(data => {
        return data;
      }));
  }

  downloadDocs(id): Observable<any> {
    const url = `${API_URLS.SITE_DOCUMENT_DOWNLOAD}` + id;
    // return this.http.get<any>(url)
    //     .pipe(map(data => {
    //         return data;
    //     }));
    return this.http.get<any>(url, { observe: 'response' });
  }

  updateGatewayDevice(siteName, deviceData): Observable<any> {
    deviceData.siteName = siteName;
    return this.http.post<any>(`${API_URLS.SITE_REGISTER_DEVICE}`, deviceData).pipe(map(data => {
      return data;
    }));
  }


  validateGatewayDevice(siteName, deviceData): Observable<any> {
    // deviceData.siteName = siteName;
    return this.http.post<any>(`${API_URLS.SITE_VALIDATE_DEVICE}`, deviceData).pipe(map(data => {
      return data;
    }));
  }

  deleteSelectedSite(siteName: any): Observable<{}> {
    let obj = {
      "siteName": siteName
    }
    const url = `${API_URLS.SITE_DELETE}/${siteName}`;
    return this.http.delete(url).pipe(
      catchError(this.handleError)
    );
  }

  deleteAllSelectedSite(siteListData: any): Observable<{}> {
    const url = `${API_URLS.SITE_ALL_DELETE}`;
    return this.http.post(url, siteListData).pipe(
      catchError(this.handleError)
    );
  }

  getSite(siteId: any): Observable<any> {
    let getSiteObj = {
      "siteName": siteId
    };
    return this.http.post(`${API_URLS.SELECTED_SITE}`, getSiteObj).pipe(
      catchError(this.handleError)
    );
  }

  getSiteAndInverterSize(siteNameValue: string): Observable<any> {
    // console.log("Get site obj: ", siteName)
    let siteName = {
      "siteName": siteNameValue
    };
    const url = 'pvcapacity';
    return this.http.get(url, { params: siteName }).pipe(
      catchError(this.handleError)
    );
  }

  getActiveAlarmAndWarningCount(siteName: any): Observable<any> {
    const url = `${API_URLS.ALRM_ACTIVE}${siteName}`;
    return this.http.get(url).pipe(
      catchError(this.handleError)
    );
  }

  getImage(siteName: string): Observable<any> {
    const url = `${API_URLS.SITE_DOWNLOAD}`;
    let siteName1 = {
      "siteName": siteName
    }
    return this.http.get(url, { params: siteName1 });
  }

  isSiteExists(siteName): Observable<any> {
    const url = `${API_URLS.SITE_EXIST}` + siteName;
    return this.http.get(url).pipe(
      catchError(this.handleError)
    );
  }

  getUserFUllNamesBySiteName(siteName): Observable<any> {
    const url = `${API_URLS.SITE_OWNERS}` + siteName;
    return this.http.get(url).pipe(
      catchError(this.handleError)
    );
  }


  postFinancialSetting(currency: any): Observable<any> {
    return this.http.post<any>(`${API_URLS.FINANCIAL}`, JSON.stringify(currency))
      .pipe(map(data => {
        return data;
      }));
  }

  getFinancialSetting(siteName): Observable<any> {
    let obj = {
      siteName: siteName
    }
    return this.http.post<any>(`${API_URLS.FINANCIAL_VALUE}`, obj)
      .pipe(map(data => {
        return data;
      }));
  }

  deleteImage(siteName: string, imageName: string): Observable<{}> {
    const url = `${API_URLS.SITE_DELETE_IMAGE}${siteName}/${imageName}`;
    return this.http.delete(url).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Error, please try again later.');
  };
}
